import React, { useEffect, useState } from 'react';
import { color } from 'theme/colors';
import { observer } from 'mobx-react-lite';
import { useStores } from 'models/root-store/root-store-context';
import { Employee } from 'models/employees/employeeModel';
import { Experience } from 'models/employees/experience/experienceModel';
import { Education } from 'models/employees/education/educationModel';
import ExperienceCard from './experienceCard';
import ReferenceCard, { ReferenceHidden } from './references';
import AbilityCard from './abilityCard';
import { StyledDisplay, StyledDisplayBox } from './styles.dislpayComponents';
import EducationCard from './educationCard';
import NotRegisteredCard from './notRegistered';

const Display: React.FC<Employee> = observer(({ employee }) => {
  const {
    experiencesStore,
    educationsStore,
    contactsStore,
    renderStore,
    sessionStore,
    roomStore,
    skillsModel,
  } = useStores();
  const [registered, setRegistered] = useState(false);
  const [buttonPressed, setButtonPressed] = useState('experience');
  const experience = experiencesStore.employeeExperiences;
  const education = educationsStore.employeeEducations;
  const references = experience.filter(
    (experiencies: Experience) =>
      experiencies?.references && experiencies.references,
  );

  const displayCard = () => {
    switch (buttonPressed) {
      case 'experience':
        return experience.map((experiencie: Experience) => (
          <ExperienceCard experience={experiencie} />
        ));
      case 'education':
        return education.map((edu: Education) => (
          <EducationCard education={edu} />
        ));
      case 'reference':
        if (renderStore.renderReference) {
          return references.map((experiencies: Experience) =>
            experiencies.references.map((e: any) => (
              <ReferenceCard job={experiencies.job} reference={e} />
            )),
          );
        }
        return <ReferenceHidden firstName={employee.firstName} />;
      case 'ability':
        return <AbilityCard />;
      default:
        return null;
    }
  };
  const selectedTextStyle = (button: string) => {
    if (button === buttonPressed) {
      return color.red;
    }
    return color.white;
  };

  useEffect(() => {
    const { id } = employee;
    if (registered || renderStore.renderYourConversations) {
      educationsStore.getMeEducations(id);
      experiencesStore.getMeExperiences(id);
    }

    const isContacted = contactsStore.contacts.filter(
      (contact) => contact.contacted.id === id,
    );

    if (isContacted.length > 0) {
      renderStore.setRenderReference(true);
    } else {
      renderStore.setRenderReference(false);
    }
    setButtonPressed('')
  }, [employee]);

  useEffect(() => {
    if (
      sessionStore.currentUser &&
      sessionStore.currentUser.business &&
      sessionStore.token
    ) {
      setRegistered(true);
    } else {
      setRegistered(false);
    }
  }, [sessionStore.currentUser, sessionStore.token]);

  return (
    <StyledDisplay room={roomStore.roomId}>
      <div className="displayBar">
        {experience.length > 0 && (
          <StyledDisplayBox
            onClick={() => setButtonPressed('experience')}
            selected={buttonPressed === 'experience' && true}
            init
          >
            <span
              className="text"
              style={{ color: selectedTextStyle('experience') }}
            >
              Experiencia
            </span>
          </StyledDisplayBox>
        )}
        {console.log()}
        {skillsModel.skills.length > 0 && (
          <StyledDisplayBox
            selected={buttonPressed === 'ability' && true}
            onClick={() => setButtonPressed('ability')}
          >
            <span
              className="text"
              style={{ color: selectedTextStyle('ability') }}
            >
              Habilidades
            </span>
          </StyledDisplayBox>
        )}
        {education.length > 0 && (
          <StyledDisplayBox
            selected={buttonPressed === 'education' && true}
            onClick={() => setButtonPressed('education')}
          >
            <span
              className="text"
              style={{ color: selectedTextStyle('education') }}
            >
              Educación
            </span>
          </StyledDisplayBox>
        )}
        {references.length > 0 && (
          <StyledDisplayBox
            selected={buttonPressed === 'reference' && true}
            onClick={() => setButtonPressed('reference')}
            end
          >
            <span
              className="text"
              style={{ color: selectedTextStyle('reference') }}
            >
              Referencias
            </span>
          </StyledDisplayBox>
        )}
      </div>
      <div className="dislpayScroll">
        {registered ? displayCard() : <NotRegisteredCard />}
      </div>
    </StyledDisplay>
  );
});

export default Display;
