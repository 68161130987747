import React from 'react';
import Icon from 'components/icons/icon';
import { StyledError, StyledLabel } from './index.styled';

interface LabelProps {
  text: string;
  
}

export interface ErrorProps {
  text: string;
  field?: boolean;
  errClick?: (args: any) => void;
}

const Label: React.FC<LabelProps> = ({ text }) => {
  return <StyledLabel>{text}</StyledLabel>;
};

export const Error: React.FC<ErrorProps> = ({ text, field, errClick }) => {
  return (
    <StyledError field={field} onClick={errClick}>
      <span className="error-text">
        <Icon
          height={field ? 14 : 18}
          width={field ? 14 : 18}
          icon="alertCircle"
        />{' '}
        {text}
      </span>
    </StyledError>
  );
};

export default Label;
