import React, { useState } from 'react';
import { CustomTextInput, CustomPasswordInput  } from 'components/common/Input';
import Button from 'components/common/Button';
import { Formik } from 'formik';
import { color } from 'theme/colors';
import { EmailRegistrationSchema } from 'utils/schema-validations';
import { emailErrors } from 'utils/errors';
import { Error } from 'components/common/Label';

interface ValuesProps {
  email: string;
  password: string;
}

interface EmailFormProps {
  onSubmit: (arg0: ValuesProps, arg1: any) => Promise<void>;
}

const initialValues = {
  email: '',
  password: '',
};

const EmailForm: React.FC<EmailFormProps> = ({ onSubmit }) => {
  const [validation, setValidationError] = useState();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit(values, setValidationError);
      }}
      validationSchema={EmailRegistrationSchema}
      validateOnBlur={false}
      validateOnChange
    >
      {({ handleSubmit, values, isValid }) => (
        <>
          <div className="field-wrapper">
            <CustomTextInput name="email" placeholder="Tu email" type="email" />
          </div>
          <div className="field-wrapper">
            <CustomPasswordInput
              name="password"
              placeholder="Contraseña"
            />
          </div>
          <div className="field-wrapper">
            <Button
              text="Confirmar"
              color={color.red}
              hBtn={48}
              wBtn={328}
              btnClick={handleSubmit}
              disabled={!(values.email && values.password) || !isValid}
            />
          </div>
          {validation && emailErrors[validation] && (
            <Error text={emailErrors[validation]} />
          )}
          {validation && !emailErrors[validation] && (
            <Error text="Ocurrió un error interno. Intente más tarde" />
          )}
        </>
      )}
    </Formik>
  );
};

export default EmailForm;
