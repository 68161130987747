import React from 'react';
import { SvgProps } from './icon.props';

const HeartFilled: React.FC<SvgProps> = () => {
  return (
    <svg
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1413 0.662165C10.6672 0.444267 11.2308 0.332115 11.8 0.332115C12.3693 0.332115 12.9329 0.444268 13.4588 0.662165C13.9846 0.880023 14.4623 1.19932 14.8647 1.60182C15.2672 2.00419 15.5867 2.48212 15.8045 3.0079C16.0224 3.53378 16.1346 4.09743 16.1346 4.66667C16.1346 5.2359 16.0224 5.79955 15.8045 6.32543C15.5867 6.85126 15.2673 7.32901 14.8648 7.7314L8.97144 13.6247C8.71109 13.8851 8.28898 13.8851 8.02863 13.6247L2.1353 7.7314C1.32248 6.91858 0.865845 5.81616 0.865845 4.66667C0.865845 3.51717 1.32248 2.41475 2.1353 1.60193C2.94812 0.789109 4.05054 0.332472 5.20004 0.332472C6.34954 0.332472 7.45196 0.789109 8.26478 1.60193L8.50004 1.83719L8.73519 1.60204C9.13758 1.19949 9.61545 0.880043 10.1413 0.662165Z"
        fill="#F24545"
      />
    </svg>
  );
};

export default HeartFilled;
