import { ApiResponse } from 'apisauce';
import { Api } from './api';
import { getGeneralApiProblem } from './api-problem';

export class ContactApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async postContact(body: any): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(
        '/contact',
        body,
      );
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const contact = response.data;
      return { kind: 'ok', contact };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async setFavourite(contactId: string): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(
        `/contact/${contactId}/favourite`,
      );
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const contact = response.data;
      return { kind: 'ok', contact };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async unsetFavourite(contactId: string): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.delete(
        `/contact/${contactId}/favourite`,
      );
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const contact = response.data;
      return { kind: 'ok', contact };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async approveContact(contactId: string): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.put(
        '/contact/approve',
        { contactId },
      );
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const contact = response.data;
      return { kind: 'ok', contact };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
