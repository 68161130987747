import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'models/root-store/root-store-context';
import Search from 'components/common/Search';
import Filter from 'components/common/Filter';
import AuthOptions from 'components/common/AuthOptions';
import UserMenu from 'components/common/UserMenu';
import Logo from 'assets/images/buba_logo.svg';
import { StyledMenu } from './index.styled';

const Menu: React.FC = observer(function Menu() {
  const { loginStore, renderStore, filterStore } = useStores();
  const [initialValues, setInitialValues] = useState({
    minimumAge: filterStore.minimumAge || 16,
    maximumAge: filterStore.maximumAge || 85,
    experience: filterStore.experience || -1,
    reference: filterStore.reference || false,
    firstJob: filterStore.firstJob || false,
  });

  useEffect(() => {
    if (!renderStore.renderClearFilter) {
      setInitialValues({
        minimumAge: 16,
        maximumAge: 85,
        experience: filterStore.experience || -1,
        reference: false,
        firstJob: false,
      });
    } else {
      setInitialValues({
        minimumAge: filterStore.minimumAge || 16,
        maximumAge: filterStore.maximumAge || 85,
        experience: filterStore.experience || -1,
        reference: filterStore.reference || false,
        firstJob: filterStore.firstJob || false,
      });
    }
    // eslint-disable-next-line
  }, [renderStore.renderClearFilter]);

  const handleLogo = () => {
    renderStore.closeRightComponents();
    renderStore.closeLeftComponents();
  };

  return (
    <StyledMenu openFilter={renderStore.renderFilter || false}>
      {(loginStore.openLogin ||
        loginStore.openRegister ||
        renderStore.renderPostOffer ||
        renderStore.renderCommerceData) &&
        !renderStore.renderModalSegment && <div className="bg-block" />}
      <div className="menu-wrapper">
        <div className="logo-filter-wrapper">
          <div
            className="logo"
            onKeyDown={handleLogo}
            tabIndex={0}
            role="button"
            onClick={handleLogo}
          >
            <img src={Logo} alt="Logo" />
            <h1>&nbsp;Comercios</h1>
          </div>
          <div className="search">
            <Search />
            <div className="visible-filter">
              {filterStore && <Filter initialValues={initialValues} />}
            </div>
          </div>
        </div>
        <div className="options-login-wrapper">
          {loginStore.userLogged ? <UserMenu /> : <AuthOptions />}
        </div>
      </div>
    </StyledMenu>
  );
});

export default Menu;
