import styled from 'styled-components';
import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';

interface YourConverProps {
  renderFavourite: boolean;
}

export const StyledYourConversations = styled.div<YourConverProps>`
  display: flex;
  flex-direction: column;
  width: ${px2rem(360)};
  height: calc(100vh - 80px);
  background-color: ${color.black};
  padding-left: ${px2rem(24)};
  padding-right: ${px2rem(24)};
  padding-top: ${px2rem(18)};
  align-items: center;

  .bubaLoader-bubaloader{
    width: ${px2rem(360)};
    display: flex;
    justify-content: center;
    margin-top: ${px2rem(30)}
  }

  .buttons-container {
    margin-bottom: 2em;

    .button-selector {
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 400;
      font-size: ${px2rem(14)};
      line-height: ${px2rem(18)};
      width: ${px2rem(94)};
      padding: ${px2rem(7)} 0;
      border: none;
      cursor: pointer;
    }

    .button-alls {
      border-top-left-radius: ${px2rem(8)};
      border-bottom-left-radius: ${px2rem(8)};
      background-color: ${({ renderFavourite }) =>
        renderFavourite ? color.white : color.palette.primary.red};
      color: ${({ renderFavourite }) =>
        renderFavourite ? color.black : color.white};
    }

    .button-favourites {
      border-top-right-radius: ${px2rem(8)};
      border-bottom-right-radius: ${px2rem(8)};
      background-color: ${({ renderFavourite }) =>
        renderFavourite ? color.palette.primary.red : color.white};
      color: ${({ renderFavourite }) =>
        renderFavourite ? color.white : color.black};
    }
  }
`;
