import React, { useState } from 'react';
import Button, { LinkBtn } from 'components/common/Button';
import getFirebase, {
  googleAuthProvider,
  facebookAuthProvider,
} from 'services/firebase/client';
import { useStores } from 'models/root-store/root-store-context';
import { color } from 'theme/colors';
import { Error } from '../Label';

const RegisterOptions: React.FC = () => {
  const { registrationStore, loginStore, sessionStore } = useStores();
  const [errorAuth, setErrorAuth] = useState(false);

  const handleAuth = async (provider: any) => {
    await getFirebase()
      .auth()
      .signInWithPopup(provider)
      .then((result: any) => {
        const token: any = Object.entries(result.user!)[6][1];
        sessionStore.setToken(token);
        if (token) {
          const body = {
            userTypeId: 2,
          };
          sessionStore.postUser(body).then((res) => {
            if (res === 'ConflictError') {
              setErrorAuth(true);
            } else {
              loginStore.setUserLogged(true);
              registrationStore.setStep('RegisterCommerce');
            }
          });
        }
      })
      .catch((error) => {
        console.log('[handleAuth error]:', error);
      });
  };

  return (
    <>
      <h1 className="title-rightpanel">Encontrá empleados de confianza</h1>
      <div>
        <div className="field-wrapper">
          <Button
            text="Empezar con Teléfono"
            color={color.red}
            wBtn={328}
            hBtn={48}
            icon="phone"
            btnClick={() => {
              registrationStore.setMethod('phone');
              registrationStore.setStep('RegisterCredentials');
            }}
          />
        </div>
        <div className="field-wrapper">
          <Button
            text="Empezar con email"
            color={color.black}
            wBtn={328}
            hBtn={48}
            icon="email"
            btnClick={() => {
              registrationStore.setMethod('email');
              registrationStore.setStep('RegisterCredentials');
            }}
          />
        </div>
        <div className="field-wrapper">
          <Button
            text="Empezar con Facebook"
            color={color.black}
            wBtn={328}
            hBtn={48}
            icon="facebook"
            btnClick={() => {
              handleAuth(facebookAuthProvider);
            }}
          />
        </div>
        <div className="field-wrapper">
          <Button
            text="Empezar con Google"
            color={color.black}
            wBtn={328}
            hBtn={48}
            icon="google"
            btnClick={() => handleAuth(googleAuthProvider)}
          />
        </div>
        {errorAuth && <Error text="¡El usuario ya existe!" />}
      </div>
      <p className="have-account">¿Ya tenés un perfil en Buba?</p>
      <LinkBtn
        text="Ingresá a tu cuenta"
        textColor={color.red}
        textSize={14}
        btnClick={() => {
          loginStore.setOpenLogin(true);
        }}
      />
    </>
  );
};

export default RegisterOptions;
