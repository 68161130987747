import * as yup from 'yup';
import { Strings } from 'constants/strings';

const phoneRegExp = /^(\+[1-9]{1,4})*?([0-9]{10})$/;
const emailCommonSchema = yup.string().email(Strings.inputs.email.error);
const requiredError = Strings.inputs.requiredError.error;
const phoneNumberCommonSchema = yup
  .string()
  .matches(phoneRegExp, Strings.inputs.phoneNumber.error);

const EmailRegistrationSchema = yup.object().shape({
  email: emailCommonSchema.required(),
  password: yup
    .string()
    .required(requiredError)
    .min(8, Strings.inputs.password.error),
});

const PasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required(requiredError)
    .min(8, Strings.inputs.password.error)
});

const ChangePasswordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required(requiredError)
    .min(8, Strings.inputs.password.error),
  repeatNewPassword: yup
    .string()
    .required(requiredError)
    .min(8, Strings.inputs.password.error)
    .required(requiredError)
    .min(8, Strings.inputs.password.error)
    .oneOf([yup.ref('newPassword'), null], 'Las contraseñas no coinciden'),
});

const PhoneSchema = yup.object().shape({
  phoneNumber: phoneNumberCommonSchema,
});

const ageSchema = yup
  .number()
  .min(16, Strings.inputs.mayority.error)
  .max(99, Strings.inputs.mayority.maxError)
  .typeError(Strings.inputs.mayority.nAn);

export const NameCommerceSchema = yup.object().shape({
  name: yup
    .string()
    .required(requiredError)
    .min(3, Strings.inputs.min3Error.error)
    .typeError(requiredError),
});

const ProfileJobSchema = yup.object().shape({
  minAge: ageSchema,
  maxAge: ageSchema,
  gender: yup.string(),
});

const CategoryJobSchema = yup.object().shape({
  name: yup
    .string()
    .required(requiredError)
    .min(3, Strings.inputs.min3Error.error),
  modality: yup.string().required(requiredError),
  // TODO: add validation for Rubros
});

const DescriptionSchema = yup.object().shape({
  description: yup.string().min(12, Strings.inputs.min12Error.error),
});

export {
  PhoneSchema,
  EmailRegistrationSchema,
  ProfileJobSchema,
  CategoryJobSchema,
  DescriptionSchema,
  ChangePasswordSchema,
  PasswordSchema,
};
