import React, { useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { Error } from 'components/common/Label';
import { InputProps } from 'lib/types';
import { IconBtn } from '../Button';
import { StyledInput, StyledTextarea, StyledTinyInput } from './index.styled';

interface refInputProps{
  current: any;
}

const AddFormikFieldProps = (name: string) => {
  const formikContext = useFormikContext();
  if (!formikContext) return {};
  const { errors, values, handleChange } = formikContext;
  // @ts-ignore
  const error = errors[name];
  // @ts-ignore
  const value = values[name];

  return {
    error,
    value,
    handleChange: handleChange(name),
  };
};

export const CustomTextInput = ({
  name,
  type,
  placeholder,
  maxLength,
  tiny,
  handleSubmit,
}: any) => {
  const { value, handleChange, error } = AddFormikFieldProps(name);
  return (
    <>
      <StyledTinyInput
        type={type || 'text'}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        maxLength={maxLength}
        tiny={tiny}
        onKeyPress={(e) => handleSubmit && e.key === 'Enter' && handleSubmit()}
      />
      {error && <Error text={error} field />}
    </>
  );
};

export const CustomPasswordInput = ({
  name,
  placeholder,
  maxLength,
  tiny,
  handleSubmit,
  autoComplete,
}: any) => {
  const { value, handleChange, error } = AddFormikFieldProps(name);
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  return (
    <>
      <StyledTinyInput
        type={passwordShown ? 'text' : 'password'}
        placeholder={placeholder}
        value={value}
        autoComplete={autoComplete}
        onChange={handleChange}
        maxLength={maxLength}
        tiny={tiny}
        onKeyPress={(e) => handleSubmit && e.key === 'Enter' && handleSubmit()}
      />
      <div
        style={{
          position: 'absolute',
          top: '20px',
          right: '10px',
        }}
      >
        <IconBtn
          icon={passwordShown ? 'eye' : 'eyeOff'}
          color="white"
          btnClick={togglePassword}
        />
      </div>
      {error && <Error text={error} field />}
    </>
  );
};

export const CustomTextarea = ({
  name,
  placeholder,
  text,
  maxLength,
  handleSubmit,
}: any) => {
  const { value, handleChange } = AddFormikFieldProps(name);

  return (
    <StyledTextarea
      name={name}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      maxLength={maxLength}
      onKeyPress={(e) => handleSubmit && e.key === 'Enter' && handleSubmit()}
    >
      {text}
    </StyledTextarea>
  );
};

export const CustomSlotInput = ({ onCodeCompleted }: any) => {
  const [firstDigit, setFirstDigit] = useState('');
  const [secondDigit, setSecondDigit] = useState('');
  const [thirdDigit, setThirdDigit] = useState('');
  const [fourthDigit, setFourthDigit] = useState('');
  const [fifthDigit, setFifthDigit] = useState('');
  const [sixthDigit, setSixthDigit] = useState('');

  const refInput1:refInputProps = useRef();
  const refInput2:refInputProps = useRef();
  const refInput3:refInputProps = useRef();
  const refInput4:refInputProps = useRef();
  const refInput5:refInputProps = useRef();
  const refInput6:refInputProps = useRef();
  const setFullCode = (value: string[]) => {
    setFirstDigit(value[0]);
    setSecondDigit(value[1]);
    setThirdDigit(value[2]);
    setFourthDigit(value[3]);
    setFifthDigit(value[4]);
    setSixthDigit(value[5]);
  };

  const handleOnChangeText = (value: any, setDigit: any) => {
    if (value.length === 6) {
      setFullCode(value);
    } else {
      setDigit(value[0]);
    }
  };

  useEffect(() => {
    const fullCode =
      firstDigit +
      secondDigit +
      thirdDigit +
      fourthDigit +
      fifthDigit +
      sixthDigit;
    if (fullCode.length === 6) {
      onCodeCompleted(fullCode);
    }
    // eslint-disable-next-line
  }, [
    firstDigit,
    secondDigit,
    thirdDigit,
    fourthDigit,
    fifthDigit,
    sixthDigit,
  ]);

  return (
    <div style={{ display: 'flex' }}>
      <StyledInput
        maxLength={1}
        type="number"
        pattern="[0-9]"
        value={firstDigit}
        onKeyDownCapture={(e:any) => {
          if (e.keyCode === 39 && e.target.value === '') {  // change to the next input
            refInput2.current.focus();
          }
        }}
        onChange={(value) => {
          handleOnChangeText(value.target.value, setFirstDigit);
          if (value.target.value !== '') { // change to next input when empty
            refInput2.current.focus();
          }
        }}
        ref={refInput1}
        isCode
      />
      <StyledInput
        maxLength={1}
        type="number"
        value={secondDigit}
        onKeyDownCapture={(e: any) => {
          if (e.target.value === '' && e.keyCode === 8) { // when press the delete key and the input is empty, it changes to the previous input
            refInput1.current.focus();
          }
          if (e.keyCode === 39 && e.target.value === '') { // change to the next input
            refInput3.current.focus();
          }
          if (e.keyCode === 37 && e.target.value === '') { // change to the previous input
            refInput1.current.focus();
          }
        }}
        onChange={(value) => {
          handleOnChangeText(value.target.value, setSecondDigit);
          if (value.target.value !== '') { // change to next input when empty
            refInput3.current.focus();
          }
        }}
        ref={refInput2}
        isCode
      />
      <StyledInput
        maxLength={1}
        type="number"
        value={thirdDigit}
        onKeyDownCapture={(e: any) => {
          if (e.target.value === '' && e.keyCode === 8) { // when press the delete key and the input is empty, it changes to the previous input
            refInput2.current.focus();
          }
          if (e.keyCode === 39 && e.target.value === '') { // change to the next input
            refInput4.current.focus();
          }
          if (e.keyCode === 37 && e.target.value === '') { // change to the previous input
            refInput2.current.focus();
          }
        }}
        onChange={(value) => {
          handleOnChangeText(value.target.value, setThirdDigit);
          if (value.target.value !== '') { // change to next input when empty
            refInput4.current.focus();
          }
        }}
        ref={refInput3}
        isCode
      />
      <StyledInput
        maxLength={1}
        type="number"
        value={fourthDigit}
        onKeyDownCapture={(e: any) => {
          if (e.target.value === '' && e.keyCode === 8) { // when press the delete key and the input is empty, it changes to the previous input
            refInput3.current.focus();
          }
          if (e.keyCode === 39 && e.target.value === '') { // change to the next input
            refInput5.current.focus();
          }
          if (e.keyCode === 37 && e.target.value === '') { // change to the previous input
            refInput3.current.focus();
          }
        }}
        onChange={(value) => {
          handleOnChangeText(value.target.value, setFourthDigit);
          if (value.target.value !== '') { // change to next input when empty
            refInput5.current.focus();
          }
        }}
        ref={refInput4}
        isCode
      />
      <StyledInput
        maxLength={1}
        type="number"
        value={fifthDigit}
        onKeyDownCapture={(e: any) => {
          if (e.target.value === '' && e.keyCode === 8) { // when press the delete key and the input is empty, it changes to the previous input
            refInput4.current.focus();
          }
          if (e.keyCode === 39 && e.target.value === '') { // change to the next input
            refInput6.current.focus();
          }
          if (e.keyCode === 37 && e.target.value === '') { // change to the previous input
            refInput4.current.focus();
          }
        }}
        onChange={(value) => {
          handleOnChangeText(value.target.value, setFifthDigit);
          if (value.target.value !== '') { // change to next input when empty
            refInput6.current.focus();
          }
        }}
        ref={refInput5}
        isCode
      />
      <StyledInput
        maxLength={1}
        type="number"
        value={sixthDigit}
        onKeyDownCapture={(e: any) => {
          if (e.target.value === '' && e.keyCode === 8) { // when press the delete key and the input is empty, it changes to the previous input
            refInput5.current.focus();
          }
          if (e.keyCode === 37 && e.target.value === '') { // change to the previous input
            refInput5.current.focus();
          }
        }}
        onChange={(value) => {
          handleOnChangeText(value.target.value, setSixthDigit);
        }}
        ref={refInput6}
        isCode
      />
    </div>
  );
};

const Input: React.FC<InputProps> = ({
  type,
  name,
  id,
  placeholder,
  value,
  onKeyDown,
  onChange,
}) => {
  return (
    <StyledInput
      type={type || 'text'}
      name={name}
      id={id}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
};

export default Input;
