import { types, Instance, SnapshotOut } from 'mobx-state-tree';

export const DocumentTypeModel = types
  .model('DocumentType')
  .props({
    createdAt: types.maybeNull(types.string),
    createdBy: types.maybeNull(types.string),
    modifiedAt: types.maybeNull(types.string),
    modifiedBy: types.maybeNull(types.string),
    state: types.maybeNull(types.string),
    id: types.maybeNull(types.number),
    description: types.maybeNull(types.string),
  })
  .actions(() => {
    return {};
  });

type DocumentTypeModelType = Instance<typeof DocumentTypeModel>;
export type DocumentType = DocumentTypeModelType;
type DocumentTypeSnapshotType = SnapshotOut<typeof DocumentTypeModel>;
export type DocumentTypeSnapshot = DocumentTypeSnapshotType;
export const createDocumentTypeDefaultModel = () =>
  types.optional(DocumentTypeModel, {});
