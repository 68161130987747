import React from 'react';
import { SvgProps } from './icon.props';

const HeartOutlined: React.FC<SvgProps> = () => {
  return (
    <svg
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1413 0.662168C10.6672 0.44427 11.2308 0.332118 11.8 0.332118C12.3693 0.332118 12.9329 0.44427 13.4588 0.662168C13.9846 0.880026 14.4623 1.19932 14.8647 1.60182C15.2672 2.00419 15.5867 2.48212 15.8045 3.0079C16.0224 3.53378 16.1346 4.09743 16.1346 4.66667C16.1346 5.2359 16.0224 5.79956 15.8045 6.32543C15.5867 6.85126 15.2673 7.32901 14.8648 7.73141C14.8647 7.73144 14.8648 7.73137 14.8648 7.73141L8.97144 13.6247C8.71109 13.8851 8.28898 13.8851 8.02863 13.6247L2.1353 7.73141C1.32248 6.91859 0.865845 5.81617 0.865845 4.66667C0.865845 3.51717 1.32248 2.41475 2.1353 1.60193C2.94812 0.789112 4.05054 0.332475 5.20004 0.332475C6.34954 0.332475 7.45196 0.789112 8.26478 1.60193L8.50004 1.83719L8.73519 1.60204C8.73523 1.602 8.73515 1.60208 8.73519 1.60204C9.13758 1.19949 9.61545 0.880046 10.1413 0.662168ZM11.8 1.66545C11.406 1.66545 11.0157 1.7431 10.6517 1.89395C10.2876 2.0448 9.95681 2.2659 9.67822 2.54463L8.97144 3.25141C8.71109 3.51176 8.28898 3.51176 8.02863 3.25141L7.32197 2.54474C6.7592 1.98197 5.99592 1.66581 5.20004 1.66581C4.40416 1.66581 3.64088 1.98197 3.07811 2.54474C2.51534 3.10751 2.19918 3.87079 2.19918 4.66667C2.19918 5.46255 2.51534 6.22583 3.07811 6.7886L8.50004 12.2105L13.922 6.7886C14.2007 6.51 14.4219 6.17911 14.5728 5.81504C14.7236 5.45097 14.8013 5.06075 14.8013 4.66667C14.8013 4.27258 14.7236 3.88236 14.5728 3.51829C14.4219 3.15422 14.2008 2.82344 13.9221 2.54485C13.6435 2.26613 13.3125 2.0448 12.9484 1.89395C12.5843 1.7431 12.1941 1.66545 11.8 1.66545Z"
        fill="#717173"
      />
    </svg>
  );
};

export default HeartOutlined;
