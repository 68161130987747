import React from 'react';
import { SvgProps } from './icon.props';

const FacebookSvg: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || 25}
      height={height || 25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6204 5.59396H17.5004V2.41396C16.5901 2.3193 15.6755 2.27257 14.7604 2.27396C12.0404 2.27396 10.1804 3.93396 10.1804 6.97396V9.59396H7.11035V13.154H10.1804V22.274H13.8604V13.154H16.9204L17.3804 9.59396H13.8604V7.32396C13.8604 6.27396 14.1404 5.59396 15.6204 5.59396Z"
        fill={color || 'white'}
      />
    </svg>
  );
};

export default FacebookSvg;
