export const palette = {
  primary: {
    red: '#F24545',
    purple: '#925BFF',
  },
  secondary: {
    lightPurple: '#CB88FF',
    passionPink: '#FF7A96',
    happyYellow: '#FFC10F',
    leafGreen: '#28A555',
    palePurple: '#F4E7FF',
    palePink: '#FFE4EA',
    paleYellow: '#FFF3CF',
    paleGreen: '#D4EDDD',
  },
  shades: {
    black: '#0D0D0D',
    darkGrey: '#1F1F1F',
    mediumGray: '#717173',
    lightGray: '#C1C1C1',
    ultraLightGray: '#F6F6F6',
    white: '#FFFFFF',
  },
  system: {
    error: '#DB3500',
    warning: '#E1980A',
    success: '#068936',
  },
  overlay: '#1F1F1F4D',
};
