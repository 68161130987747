import { types, Instance, SnapshotOut } from 'mobx-state-tree';
import api from 'services/api/api';

export const JobPositionModel = types
  .model('JobPosition')
  .props({
    id: types.maybeNull(types.string),
    modalityId: types.maybeNull(types.number),
    genderId: types.maybeNull(types.number),
    name: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    minimumAge: types.optional(types.number, 16),
    maximumAge: types.optional(types.number, 99),
    skills: types.maybeNull(types.string),
    businessSegmentId: types.optional(types.number, 0),
  })
  .actions((self) => {
    function setId(id: string | null) {
      self.id = id;
    }

    function setCategory(name: string, modality: number) {
      self.name = name;
      self.modalityId = modality;
    }

    function setBusinessSegment(id: number) {
      self.businessSegmentId = id;
    }

    function setRequirement(minAge: number, maxAge: number, gender: number) {
      self.minimumAge = minAge;
      self.maximumAge = maxAge;
      self.genderId = gender;
    }

    function setSkills(skills: string) {
      self.skills = skills;
    }

    function setDescription(description: string) {
      self.description = description;
    }
    function resetValues() {
      self.name = '';
      self.modalityId = 1;
      self.businessSegmentId = 0;
      self.minimumAge = 16;
      self.maximumAge = 99;
      self.genderId = null;
      self.skills = '';
      self.description = '';
    }
    return {
      setId,
      setCategory,
      setBusinessSegment,
      setRequirement,
      setDescription,
      setSkills,
      resetValues,
    };
  })
  .actions(() => ({
    postJobs: async (body: any) => {
      await api.job.postJobs(body);
    },
  }))
  .actions(() => ({
    putJob: async (body: any, id: string) => {
      await api.job.putJob(body, id);
    },
  }));

type JobPositionModelType = Instance<typeof JobPositionModel>;
export type JobPosition = JobPositionModelType;
type JobPositionSnapshotType = SnapshotOut<typeof JobPositionModel>;
export type JobPositionSnapshot = JobPositionSnapshotType;
export const createJobPositionDefaultModel = () =>
  types.optional(JobPositionModel, {});
