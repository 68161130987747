export const Strings = {
  inputs: {
    requiredError: { error: 'Este campo es obligatorio' },
    phoneNumber: {
      helper: 'Ingresá tu número sin el 15 y con código de área (11)',
      error: 'Revisá el número telefónico',
    },
    dniDocumentNumber: {
      missing: 'Ingresá tu número de documento',
      error: 'Revisá tu número de documento',
    },
    documentType: {
      missing: 'Ingresá el tipo de documento',
      error: 'Revisá el tipo de documento',
    },
    birthdate: {
      missing: 'Ingresá tu fecha de nacimiento',
      error: 'Revisá tu fecha de nacimiento',
      minor: 'Para usar Buba debés ser mayor de 16 años',
    },
    genre: {
      missing: 'Ingresa tu género',
      error: 'Revisá el campo género',
    },
    password: {
      error: 'Debe tener almenos 8 caracteres',
    },
    email: {
      error: 'Revisá tu email',
    },
    name: {
      requiredError: 'Ingresá tu nombre',
    },
    lastName: {
      requiredError: 'Ingresá tu apellido',
    },
    min3Error: {
      error: 'Debe tener al menos 3 caracteres',
    },
    min12Error: {
      error: 'Debe tener al menos 12 caracteres',
    },
    max50Error: {
      error: 'No puede tener mas de 50 caracteres',
    },
    studyType: {
      missing: 'Selecciona el tipo de estudio',
    },
    status: {
      missing: 'Selecciona el estado',
    },
    modality: {
      missing: 'Selecciona la modalidad',
    },
    terminationReason: {
      missing: 'Selecciona el motivo de finalización',
    },
    position: {
      missing: 'Selecciona la relación laboral',
    },
    mayority: {
      error: 'La edad debe ser superior a 16 años',
      nAn: 'La edad debe ser numérica',
      maxError: 'La edad debe ser menor a 100 años',
      minGreatThanMaxError: 'La edad mínima debe ser menor a la edad máxima',
    },
  },
  forms: {
    registration: {
      email: {
        common:
          'Estamos teniendo problemas para validar tu email, probá más tarde o ingresa con otro medio',
      },
      phone: {
        common:
          'Estamos teniendo problemas para validar tu teléfono, probá más tarde o ingresa con otro medio',
      },
    },
  },
};
