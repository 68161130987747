import React, { useState } from 'react';
import { AbilityBubblePost } from 'components/sections/employeeDetail/components/abilityBuble';
import Button, { IconBtn } from 'components/common/Button';
import Input from 'components/common/Input';
import Label from 'components/common/Label';
import { color } from 'theme/colors';
import { useStores } from 'models/root-store/root-store-context';
import { observer } from 'mobx-react-lite';
import { randomColor } from 'utils/randomColor';

const PositionSkills: React.FC = observer(function PositionSkills() {
  const { JobPositionStore, skillsModel, renderStore } = useStores();

  const [skill, setSkill] = useState('');

  const handleSkills = () => {
    if (skill) {
      skillsModel.addSkill(skill, randomColor());
      setSkill('');
      const newSkills = skillsModel.skills
        .map((item) => item.skillName)
        .join(',');
      JobPositionStore.setSkills(`${newSkills}`);
    }
  };

  const handleChange = (e: any) => {
    setSkill(e.target.value);
  };

  const handleDestroy = (id: number) => {
    skillsModel.removeSkillById(id);
  };

  const handleSubmit = () => {
    if (JobPositionStore.id) {
      const newSkills = skillsModel.skills
        .map((item) => item.skillName)
        .join(',');
      JobPositionStore.setSkills(`${newSkills}`);
      const { id, ...body } = JobPositionStore;
      JobPositionStore.putJob(body, id);
    }
    renderStore.setStepPostOffer(renderStore.stepPostOffer + 1);
  };
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleSkills();
    }
  };

  return (
    <>
      <h1 className="title-rightpanel">Describí el perfil</h1>
      <h2 className="section-rightpanel">
        Estos datos te permitirán filtrar y acceder sólo a postulantes que
        cumplan los requisitos
      </h2>

      <div className="field-wrapper">
        <Label text="Habilidades (debés completar al menos dos) " />
        <Input
          name="ability"
          placeholder="Agrega una habilidad"
          value={skill}
          onChange={handleChange}
          onKeyDown={(e) =>
            !skill && e.key === 'Enter' ? handleSubmit() : handleKeyDown(e)
          }
        />
        <div className="icon-input-right">
          <IconBtn icon="plus" btnClick={handleSkills} />
        </div>
      </div>
      <div className="ability-tags">
        {skillsModel.skills.length !== 0
          ? skillsModel.skills.map((props: any) => (
              <AbilityBubblePost
                text={props.skillName || ''}
                key={`${props.skillId} + ${props.skillName}` || 'skill'}
                skillId={props.skillId || 0}
                isDestroy={() => handleDestroy(props.skillId)}
                color={props.color || '#000'}
              />
            ))
          : ''}
      </div>
      <div className="btn-panel">
        <Button
          text="Confirmar"
          color={color.red}
          hBtn={48}
          wBtn={328}
          btnClick={handleSubmit}
          disabled={skillsModel.skills.length < 2}
        />
        <Button
          text="Omitir"
          color={color.black}
          hBtn={48}
          wBtn={328}
          btnClick={handleSubmit}
          btnMaring="5px 0"
        />
      </div>
    </>
  );
});

export default PositionSkills;
