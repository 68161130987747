import React from 'react';
import { SvgProps } from './icon.props';

const FlagSvg: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || 12}
      height={height || 14}
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.908 13L6.24137 9.66667L1.57471 13V2.33333C1.57471 1.97971 1.71518 1.64057 1.96523 1.39052C2.21528 1.14048 2.55442 1 2.90804 1H9.57471C9.92833 1 10.2675 1.14048 10.5175 1.39052C10.7676 1.64057 10.908 1.97971 10.908 2.33333V13Z"
        stroke={color || '#C1C1C1'}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FlagSvg;
