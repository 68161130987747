import styled from 'styled-components';
import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';

export const StyledUserSettings = styled.div`
background-color: ${color.black};
max-width: ${px2rem(364)};
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
padding: ${px2rem(16)} ${px2rem(19)};

.header-panel {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: ${px2rem(30)};
  margin: ${px2rem(10)} 0 ${px2rem(33)} 0;
}

.title-rightpanel,
.section-rightpanel,
.subsection-tittle-rightpanel,
.subsection-subtittle-rightpanel,
.footer-tittle-rightpanel,
.footer-content-rightpanel,
.a {
  font-family: 'Lexend';
  color: #C1C1C1;
  font-size: ${px2rem(14)};
  width: 100%;
}

.a{
  text-decoration-line: underline;
  color: ${color.red};
  display: flex;
  font-size: ${px2rem(16)};
  margin-top: ${px2rem(15)};
}

.subsection-tittle-rightpanel{
  font-size: ${px2rem(16)};
}


.subsection-subtittle-rightpanel{
  font-weight: 400; 
}

.footer-tittle-rightpanel{
  margin-bottom: ${px2rem(10)};
}

.footer-content-rightpanel {
  font-weight: 400;
  color: ${color.white};
  maring-bottom:
}

.title-rightpanel {
  color: ${color.white};
  font-family: 'Lexend Mega';
  font-size: ${px2rem(20)};
  letter-spacing: -0.12em;
  margin: 0 ${px2rem(10)} ${px2rem(25)} ${px2rem(10)};
  text-align: center;
}

.line{
width: 100%;
height: 0px;
border: 0.1px solid #717173;
margin: ${px2rem(10)}
}

.paragraph-rightpanel {
  font-family: 'Lexend';
  color: ${color.white};
  font-size: ${px2rem(14)};
  margin-top: ${px2rem(8)};
  margin-bottom: ${px2rem(35)};
  text-align: left;
}


.fields-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.field-wrapper {
  position: relative;
  width: min-content;
  margin-bottom: ${px2rem(11.3)};
  margin-top: ${px2rem(6)};
}
`;
