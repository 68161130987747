import React from 'react';
import { SvgProps } from './icon.props';

const PenSvg: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || 23}
      height={height || 22}
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 2.17157C18.2599 2.17157 18.0222 2.21887 17.8003 2.31075C17.5785 2.40264 17.3769 2.53732 17.2071 2.70711L3.89491 16.0193L2.92524 19.5748L6.4807 18.6051L19.7929 5.29289C19.9627 5.12311 20.0974 4.92154 20.1893 4.69971C20.2812 4.47788 20.3284 4.24012 20.3284 4C20.3284 3.75989 20.2812 3.52212 20.1893 3.30029C20.0974 3.07846 19.9627 2.87689 19.7929 2.70711C19.6231 2.53732 19.4216 2.40264 19.1997 2.31075C18.9779 2.21887 18.7401 2.17157 18.5 2.17157ZM17.0349 0.462995C17.4994 0.270599 17.9973 0.171574 18.5 0.171574C19.0028 0.171574 19.5006 0.270599 19.9651 0.462995C20.4296 0.655392 20.8516 0.937391 21.2071 1.29289C21.5626 1.6484 21.8446 2.07044 22.037 2.53492C22.2294 2.99941 22.3284 3.49725 22.3284 4C22.3284 4.50275 22.2294 5.00059 22.037 5.46508C21.8446 5.92956 21.5626 6.35161 21.2071 6.70711L7.70713 20.2071C7.58407 20.3302 7.43104 20.419 7.26314 20.4648L1.76314 21.9648C1.41693 22.0592 1.04667 21.9609 0.792916 21.7071C0.539166 21.4534 0.440838 21.0831 0.535259 20.7369L2.03526 15.2369C2.08105 15.069 2.16986 14.9159 2.29292 14.7929L15.7929 1.29289C16.1484 0.937391 16.5705 0.655392 17.0349 0.462995Z"
        fill={color || '#0D0D0D'}
      />
    </svg>
  );
};

export default PenSvg;
