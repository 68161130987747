import React, { useEffect, useState } from 'react';
import { useStores } from 'models/root-store/root-store-context';
import { observer } from 'mobx-react-lite';
import Button from 'components/common/Button';
import Icon from 'components/icons/icon';
import { color } from 'theme/colors';
import { rootStore } from 'models/root-store/root-store';
import getFirebase from 'services/firebase/client';
import { StyledUserMenu } from './index.styled';

const Dropdown: React.FC = () => {
  const {
    renderStore,
  } = useStores();

  return (
    <ul className="commerce-dropdown">
      <button
        type="button"
        onClick={() => {
          renderStore.closeLeftComponents();
          renderStore.closeRightComponents();
          renderStore.setRenderUserSettings(true);
        }}
      >
        <li>
          <Icon icon="settings" />
          <span>Configuracion</span>
        </li>
      </button>
      <button
        type="button"
        onClick={() => {
          renderStore.closeRightComponents();
          renderStore.closeLeftComponents();
          renderStore.setRenderCommerceData(!renderStore.renderCommerceData);
        }}
      >
        <li>
          <Icon icon="edit" />
          <span>Datos de mi comercio</span>
        </li>
      </button>
      <button
        type="button"
        onClick={() => {
          renderStore.closeRightComponents();
          if (renderStore.renderPublishedOffers) {
            renderStore.closeLeftComponents();
          } else {
            renderStore.closeLeftComponents();
            renderStore.setRenderPublishedOffers(true);
          }
          renderStore.setRenderList(!renderStore.renderPublishedOffers);
        }}
      >
        <li>
          <Icon icon="list" />
          <span>Ofertas publicadas</span>
        </li>
      </button>
      <button
        type="button"
        onClick={() => {
          rootStore.resetStore();
        }}
      >
        <li>
          <Icon icon="logOut" />
          <span>Cerrar sesión</span>
        </li>
      </button>
    </ul>
  );
};

const UserMenu: React.FC = observer(function UserMenu() {
  const { renderStore, loginStore, skillsModel, contactsStore } = useStores();

  const checkExplore = () => {
    return (
      !renderStore.renderList &&
      !renderStore.renderYourConversations &&
      !renderStore.renderPublishedOffers &&
      !renderStore.renderEmployeeDetail &&
      !renderStore.renderJobDetail &&
      !renderStore.renderChat &&
      !loginStore.openLogin &&
      !loginStore.openRegister &&
      !renderStore.renderCommerceData &&
      !renderStore.renderPostOffer &&
      !renderStore.renderDropdown
    );
  };

  return (
    <StyledUserMenu menu={renderStore.renderDropdown}>
      <div className="options-user">
        <button
          type="button"
          className="opt-menu commerce"
          onClick={() => {
            renderStore.setRenderList(true);
            renderStore.setRenderCardList(false);
            renderStore.setRenderDropdown(false);
            renderStore.setRenderCommerceData(false);
            renderStore.setRenderPostOffer(false);
            renderStore.setRenderFilter(false);
            renderStore.setRenderEmployeeDetail(false);
            renderStore.setRenderJobDetail(false);
            renderStore.setRenderPublishedOffers(false);
            renderStore.setRenderChat(false);
            renderStore.setRenderYourConversations(false);
          }}
        >
          {checkExplore() ? (
            <>
              <Icon icon="map" color={color.red} />
              &nbsp;<span className="opt-text opt-text-hover">Explorar</span>
            </>
          ) : (
            <>
              <Icon icon="map" />
              &nbsp;<span className="opt-text">Explorar</span>
            </>
          )}
        </button>
        <button
          type="button"
          onClick={() => {
            renderStore.setRenderYourConversations(
              !renderStore.renderYourConversations,
            );
            renderStore.setRenderChat(false)
            renderStore.setRenderPublishedOffers(false);
            renderStore.setRenderJobDetail(false);
            renderStore.setRenderList(!renderStore.renderYourConversations);
            renderStore.setRenderEmployeeDetail(false);
            renderStore.setRenderCardList(false);
          }}
          className="opt-menu commerce"
        >
          <>
            <Icon
              icon={contactsStore.hasUnreadMessages ? 'messageNotification' : 'notification'}
              color={
                renderStore.renderYourConversations ? color.red : '#C1C1C1'
              }
            />
            &nbsp;
            <span
              className={`opt-text ${
                renderStore.renderYourConversations ? 'opt-text-hover' : ''
              }`}
            >
              Mensajes
            </span>
          </>
        </button>

        <button
          type="button"
          className="opt-menu commerce"
          onClick={() =>
            renderStore.setRenderDropdown(!renderStore.renderDropdown)
          }
        >
          {renderStore.renderDropdown ? (
            <>
              <Icon icon="comercio" color={color.red} />
              <Icon icon="chevronDown" color={color.red} />
            </>
          ) : (
            <>
              <Icon icon="comercio" />
              <Icon icon="chevronDown" />
            </>
          )}
        </button>
        <Dropdown />
      </div>
      <Button
        text="Publicar"
        wBtn={135}
        hBtn={48}
        color={color.red}
        icon="plus"
        btnClick={() => {
          skillsModel.removeSkills();
          renderStore.closeLeftComponents();
          renderStore.closeRightComponents();
          renderStore.setRenderPostOffer(!renderStore.renderPostOffer);
          renderStore.setStepPostOffer(1);
          renderStore.setRenderCommerceData(false);
          renderStore.setStepCommcerData(1);
        }}
      />
    </StyledUserMenu>
  );
});

export default UserMenu;
