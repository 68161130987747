import React from 'react';
import { SvgProps } from './icon.props';

const EmailSvg: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || 25}
      height={height || 25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 4.27393H5.5C4.70435 4.27393 3.94129 4.59 3.37868 5.15261C2.81607 5.71521 2.5 6.47828 2.5 7.27393V17.2739C2.5 18.0696 2.81607 18.8326 3.37868 19.3952C3.94129 19.9579 4.70435 20.2739 5.5 20.2739H19.5C20.2956 20.2739 21.0587 19.9579 21.6213 19.3952C22.1839 18.8326 22.5 18.0696 22.5 17.2739V7.27393C22.5 6.47828 22.1839 5.71521 21.6213 5.15261C21.0587 4.59 20.2956 4.27393 19.5 4.27393ZM5.5 6.27393H19.5C19.7652 6.27393 20.0196 6.37928 20.2071 6.56682C20.3946 6.75436 20.5 7.00871 20.5 7.27393L12.5 12.1539L4.5 7.27393C4.5 7.00871 4.60536 6.75436 4.79289 6.56682C4.98043 6.37928 5.23478 6.27393 5.5 6.27393ZM20.5 17.2739C20.5 17.5391 20.3946 17.7935 20.2071 17.981C20.0196 18.1686 19.7652 18.2739 19.5 18.2739H5.5C5.23478 18.2739 4.98043 18.1686 4.79289 17.981C4.60536 17.7935 4.5 17.5391 4.5 17.2739V9.55393L11.98 14.1239C12.132 14.2117 12.3045 14.2579 12.48 14.2579C12.6555 14.2579 12.828 14.2117 12.98 14.1239L20.5 9.55393V17.2739Z"
        fill={color || 'white'}
      />
    </svg>
  );
};

export default EmailSvg;
