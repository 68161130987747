import styled from 'styled-components';
import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';
import { CardProps } from 'lib/types';

export const StyledCard = styled.div<CardProps>`
  display: flex;
  width: ${px2rem(315)};
  cursor: pointer;
  height: ${(props) => (props.hiring ? px2rem(100) : px2rem(130))};
  background-color: ${(props) =>
    props.selected ? props.bgSelected : color.darkGrey};
  flex-direction: row;
  box-shadow: 1px 0px 16px rgba(0, 0, 0, 0.5);
  border-radius: 0.5em;
  border-width: ${px2rem(2)};
  border-style: solid;
  border-color: ${(props) => (props.selected ? color.red : 'transparent')};
  position: relative;

  .photo-card-employee {
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 3em;
    .photo {
      margin: 0 ${px2rem(16)} 0 ${px2rem(11)};
      width: ${(props) => (props.hiring ? px2rem(71) : px2rem(81))};
      height: ${(props) => (props.hiring ? px2rem(71) : px2rem(81))};
      border-radius: 3em;
      object-fit: cover;
    }

    .photo-blur {
      filter: blur(2.5px);
    }
  }

  .info-card-employee {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: ${px2rem(185)};

    .name-employee {
      width: 90%;
      font-family: 'Lexend';
      color: ${color.white};
      font-style: normal;
      font-weight: normal;
      font-size: 1em;
      line-height: 1.1em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .contacted-employee {
      flex: 1;
      background: 'none';
      text-align: right;
      border: none;
      align-self: flex-end;
    }

    .favourite-employee {
      flex: 1;
      background: none;
      text-align: right;
      border: none;
      align-self: flex-end;
      cursor: pointer;
    }

    .row-employee-card {
      margin-top: 0.5em;
      align-items: center;
      display: flex;
      background-color: ${(props) =>
        props.selected ? props.bgSelected : color.darkGrey};
    }

    .text-employee-card {
      width: 90%;
      font-family: 'Lexend';
      font-size: ${px2rem(14)};
      line-height: ${px2rem(18)};
      color: ${color.palette.shades.lightGray};
      margin-left: ${px2rem(4)};
      background-color: ${(props) =>
        props.selected ? props.bgSelected : color.darkGrey};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .arrowIcon {
    width: ${px2rem(30)};
    height: ${px2rem(30)};
    position: absolute;
    right: -1em;
    top: 35%;
    align-items: center;
    display: flex;
    z-index: 30;
  }

  .border-black {
    position: absolute;
    background-color: ${(props) => props.selected && props.bgSelected};
    width: ${px2rem(5)};
    height: ${px2rem(30)};
    top: 35%;
    right: -0.3rem;
  }
`;
