import { types, Instance, SnapshotOut } from 'mobx-state-tree';
import getFirebase from 'services/firebase/client';

export const UserMessageModel = types.model('UserMessage').props({
  _id: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
});

export const DateMessageModel = types.model('DataMessage').props({
  createdAt: types.maybeNull(types.number),
  text: types.maybeNull(types.string),
  user: types.maybeNull(UserMessageModel),
});

export const MessagesModel = types
  .model('Messages')
  .props({
    sessionId: types.maybe(types.string), // room
    userMessages: types.maybe(types.array(DateMessageModel)),
  })
  .actions((self) => {
    function setSessionId(roomId: string) {
      self.sessionId = roomId;
    }
    function setUserMessages(messages: any) {
      self.userMessages = messages;
    }

    return { setSessionId, setUserMessages };
  })
  .actions((self) => ({
    getMessagesFirebase: (roomId: string) => {
      self.setSessionId(roomId);
      getFirebase()
        .firestore()
        .collection('THREADS')
        .doc(roomId)
        .collection('MESSAGES')
        .orderBy('createdAt', 'asc')
        .onSnapshot((message) => {
          if (roomId === self.sessionId) {
            const documents = message.docs.map((doc) => doc.data());
            self.setUserMessages(documents);
            getFirebase()
              .firestore()
              .collection('THREADS')
              .doc(roomId)
              .update({ unreadMessagesBusiness: false });
          }
        });
    },
  }))
  .views((self) => ({
    get messages() {
      const message = self.userMessages?.map((doc) => doc);
      return message;
    },
  }));

type MessagesModelType = Instance<typeof MessagesModel>;
export type Gender = MessagesModelType;
type MessagesSnapshotType = SnapshotOut<typeof MessagesModel>;
export type MessagesSnapshot = MessagesSnapshotType;
export const MessagesDefaultModel = () =>
  types.optional(MessagesModel, { sessionId: '', userMessages: [] });
