import React from 'react';
import { SvgProps } from './icon.props';

const ResumeSvg: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.54 6.00003L5.87997 0.460026C5.35725 0.158186 4.76397 6.39946e-05 4.16036 0.00172189C3.55676 0.00337978 2.96435 0.164758 2.4433 0.469465C1.92225 0.774173 1.49112 1.21135 1.19372 1.7366C0.896306 2.26185 0.743208 2.85646 0.749971 3.46003V14.58C0.749971 15.4871 1.11029 16.357 1.75167 16.9983C2.39304 17.6397 3.26293 18 4.16997 18C4.77041 17.999 5.36006 17.8404 5.87997 17.54L15.54 12C16.059 11.6996 16.49 11.268 16.7896 10.7485C17.0891 10.2289 17.2468 9.63975 17.2468 9.04003C17.2468 8.4403 17.0891 7.85112 16.7896 7.33159C16.49 6.81206 16.059 6.38044 15.54 6.08003V6.00003Z"
        fill={color || '#0D0D0D'}
      />
    </svg>
  );
};

export default ResumeSvg;
