import styled from 'styled-components';
import { color } from './colors';
import { px2rem } from './styleUtils';

export const Divider = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${px2rem(23)};
  color: ${color.white};
  margin-bottom: ${px2rem(11.3)};

  div {
    width: ${px2rem(148)};
    height: 0;
    border-top: 1px solid ${color.darkGrey};
    box-shadow: 0px 0px 2px ${color.darkGrey};
  }
`;
