import React from 'react';
import Label from 'components/common/Label';
import { useField } from 'formik';
import { CheckboxWrapper, StyledCheckbox } from './index.styled';

interface CheckboxProps {
  name: string;
  text: string;
  checked: boolean;
  onChange: (arg: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomCheckbox: React.FC<CheckboxProps> = ({ name, text }) => {
  const [field] = useField({ name, type: 'checkbox' });

  return (
    <CheckboxWrapper>
      <StyledCheckbox {...field} type="checkbox" id={name} />
      <Label text={text} />
    </CheckboxWrapper>
  );
};

export const Checkbox: React.FC<CheckboxProps> = ({
  name,
  text,
  checked,
  onChange,
}) => {
  return (
    <CheckboxWrapper>
      <StyledCheckbox
        name={name}
        onChange={onChange}
        checked={checked}
        type="checkbox"
        id={name}
      />
      <Label text={text} />
    </CheckboxWrapper>
  );
};

export default CustomCheckbox;
