import styled from 'styled-components';
import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';

export const StyledCardsList = styled.div`
  display: flex;
  flex-direction: column;
  width: ${px2rem(363)};
  height: 100%;
  background-color: ${color.black};
  padding: ${px2rem(18)} ${px2rem(24)} ${px2rem(10)} ${px2rem(24)};
  -ms-overflow-style: none;

  .text {
    color: ${color.palette.shades.mediumGray};
    font-family: 'Lexend';
    text-align: center;
    margin-bottom: 1em;
    background-color: ${color.black};
  }

  .hideList {
    width: ${px2rem(32)};
    height: ${px2rem(32)};
    cursor: pointer;
    border: none;
    background-color: ${color.black};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px ${px2rem(8)} ${px2rem(8)} 0px;
    position: absolute;
    top: ${px2rem(21)};
    left: ${px2rem(363)};
    z-index: 2;
  }
  .scroll {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow-y: 'scroll';
    width: ${px2rem(325)};
    align-self: center;
    justify-self: center;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
