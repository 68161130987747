import { ApiResponse } from 'apisauce';
import { Api } from './api';
import { getGeneralApiProblem } from './api-problem';

export class EmployeesApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getEmployees(): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        '/employees',
      );
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const employees = response;
      return { kind: 'ok', employees };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
