import React from 'react';
import Icon from 'components/icons/icon';
import { Reference } from 'models/employees/experience/referenceModel';
import { color } from 'theme/colors';
import { StyledCard } from './styles.dislpayComponents';

interface ReferenceProps {
  job: string;
  reference: Reference;
}

const ReferenceCard: React.FC<ReferenceProps> = ({ job, reference }) => {
  return (
    <StyledCard>
      <div className="refBox">
        <div className="refDataBox">
          <span className="refCardTitle">{reference.name}</span>
          <div className="iconText">
            <Icon icon="user" color={color.palette.shades.lightGray} />
            <span className="text">
              {(reference.employmentRelationship &&
                reference.employmentRelationship.description) ||
                ''}
            </span>
          </div>
          <div className="iconText">
            <Icon
              icon="shop"
              height={14}
              width={14}
              color={color.palette.shades.lightGray}
            />
            <span className="text">{job}</span>
          </div>
          <div className="iconText">
            <Icon icon="phone" color={color.palette.shades.lightGray} />
            <span className="text">{reference.phoneNumber || ''} </span>
          </div>
        </div>
        <div className="phoneBox">
          <Icon icon="phone" color={color.red} height={30} width={30} />
        </div>
      </div>
    </StyledCard>
  );
};

export default ReferenceCard;

export const ReferenceHidden: React.FC<{ firstName: string }> = ({
  firstName,
}) => {
  return (
    <StyledCard>
      <div className="refBoxNotConected">
        <span className="refTextNotConected">
          Conecta con {firstName} para poder acceder a sus referencias
        </span>
      </div>
    </StyledCard>
  );
};
