import React from 'react';

interface SearchMapProps {
  address: string;
  handleAddress: (arg: any) => void;
}

const SearchMap: React.FC<SearchMapProps> = ({ address, handleAddress }) => {
  return (
    <>
      <div
        style={{
          backgroundColor: '#0D0D0D',
          width: `388px`,
          height: `103px`,
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '0 0 16px 16px',
          left: '50%',
          marginLeft: '-120px',
        }}
      >
        <input
          type="text"
          placeholder="Dirección de tu comercio"
          value={address}
          onChange={handleAddress}
          className="search-map"
          style={{
            boxSizing: `border-box`,
            border: `1px solid white`,
            borderRadius: `12px`,
            width: `328px`,
            height: `48px`,
            outline: `none`,
            textOverflow: `ellipses`,
            padding: `0 12px`,
            backgroundColor: '#0D0D0D',
            color: '#f6f6f6',
            fontFamily: 'Lexend',
            fontSize: `16px`,
          }}
        />
      </div>
    </>
  );
};

export default SearchMap;
