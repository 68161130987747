import styled from 'styled-components';
import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';

interface MenuPropStyle {
  openFilter: boolean;
}

export const StyledMenu = styled.nav<MenuPropStyle>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${color.darkGrey};
  width: 100%;
  height: ${px2rem(80)};
  min-height: ${px2rem(80)};
  padding: 0 45px;
  position: relative;

  .menu-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .logo-filter-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: ${px2rem(476)};

      .logo {
        display: flex;
        cursor: pointer;

        img {
          width: ${px2rem(25)};
          height: ${px2rem(35.96)};
        }
        h1 {
          font-family: 'Messapia';
          font-size: ${px2rem(27.5)};
          color: ${color.white};
          display: none;
        }
      }

      .search {
        position: relative;

        .visible-filter {
          display: flex;
          position: absolute;
          z-index: 2000;
          right: 0;
          top: ${px2rem(55)};
          height: ${(props) => (props.openFilter ? '366px' : '0')}; // 436
          overflow: hidden;
          transition-duration: 400ms;
          box-shadow: 1px 0px 16px #414141;
          border-radius: ${px2rem(16)} 0 ${px2rem(16)} ${px2rem(16)};
        }
      }
    }

    .options-login-wrapper {
      display: flex;
    }
  }

  @media (min-width: 1440px) {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${color.darkGrey};
    width: 100%;
    height: ${px2rem(80)};
    min-height: ${px2rem(80)};

    .menu-wrapper {
      .logo-filter-wrapper {
        width: ${px2rem(615)};
        .logo {
          h1 {
            display: flex;
          }
        }
      }
    }
  }
`;
