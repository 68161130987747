import { color } from 'theme/colors';
import styled from 'styled-components';
import { px2rem } from 'theme/styleUtils';

export const StyledFilter = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: ${px2rem(360)};
  background-color: ${color.black};
  border-radius: ${px2rem(16)} 0 ${px2rem(16)} ${px2rem(16)};
  padding: ${px2rem(16)};
  z-index: 90003;

  .have-account {
    color: ${color.white};
    font-family: 'Lexend';
    font-size: ${px2rem(14)};
    margin: ${px2rem(2)};
  }
  
  .title-filter {
    width: 100%;
    h2 {
      text-align: center;
      font-family: 'Lexend Mega';
      font-size: ${px2rem(18)};
      color: ${color.white};
    }
  }

  .field-age {
    display: flex;
    flex-direction: column;
  }

  .fields-age {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: ${px2rem(24)};
    margin-bottom: ${px2rem(4)};
  }

  .fields-exp {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: ${px2rem(10)};
  }

  .exp-value {
    font-family: 'Lexend';
    font-size: ${px2rem(14)};
    color: ${color.palette.shades.mediumGray};
    margin-top: ${px2rem(6)};
    margin-bottom: ${px2rem(19)};
  }

  .fields-profile {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: ${px2rem(29)};
  }

  .fields-checkbox {
    display: flex;
    justify-content: flex-start;
    margin: ${px2rem(15)} 0;
  }

  .title-field {
    color: ${color.white};
    font-family: 'Lexend';
    font-size: ${px2rem(14)};
    margin-bottom: ${px2rem(11)};
  }

  .btn-wrapper {
    position: absolute;
    bottom: 16px;
  }
`;

export const Bar = styled.div`
  background-color: ${color.black};
`;
