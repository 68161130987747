import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { CustomTextInput } from 'components/common/Input';
import Select from 'components/common/Select';
import Button from 'components/common/Button';
import Label, { Error } from 'components/common/Label';
import { color } from 'theme/colors';
import { useStores } from 'models/root-store/root-store-context';
import { KeysMasters } from 'lib/types';
import { ProfileJobSchema } from 'utils/schema-validations';
import { Strings } from 'constants/strings';

const PositionRequirement: React.FC<{ genders: KeysMasters[] }> = ({
  genders,
}) => {
  const { JobPositionStore, renderStore } = useStores();

  const onSubmit = (values: {
    minAge: string;
    maxAge: string;
    gender: string;
  }) => {
    const { minAge, maxAge, gender } = values;
    JobPositionStore.setRequirement(
      Number(minAge || '16'),
      Number(maxAge || '99'),
      genders.find((g) => g.description === gender)?.id || 4,
    );

    if (JobPositionStore.id) {
      const { id, ...body } = JobPositionStore;
      JobPositionStore.putJob(body, id);
    }

    renderStore.setStepPostOffer(renderStore.stepPostOffer + 1);
  };

  const minGreatThanMax = (minAge: string, maxAge: string) => {
    return !!minAge && !!maxAge && Number(minAge) > Number(maxAge);
  };

  return (
    <>
      <h1 className="title-rightpanel">Describí el perfil</h1>
      <h2 className="section-rightpanel">
        Estos datos te permitirán filtrar y acceder sólo a postulantes que
        cumplan los requisitos
      </h2>
      <Formik
        initialValues={{
          minAge: JobPositionStore.minimumAge?.toString(10) || '',
          maxAge: JobPositionStore.maximumAge?.toString(10) || '',
          gender: genders[(JobPositionStore.genderId || 4) - 1].description,
        }}
        onSubmit={onSubmit}
        validationSchema={ProfileJobSchema}
        validateOnChange
      >
        {({ handleSubmit, isValid, values }) => (
          <>
            <div className="fields-flex">
              <div className="field-wrapper">
                <Label text="Edad mínima" />
                <CustomTextInput
                  name="minAge"
                  type="text"
                  placeholder="Sin límites"
                  tiny
                  handleSubmit={handleSubmit}
                />
              </div>
              <div className="field-wrapper">
                <Label text="Edad máxima" />
                <CustomTextInput
                  name="maxAge"
                  type="text"
                  placeholder="Sin límites"
                  tiny
                  handleSubmit={handleSubmit}
                />
              </div>
            </div>
            <div className="field-wrapper">
              <Select
                name="gender"
                id="gender"
                options={genders.map((gender) => gender.description)}
                selected={values.gender}
              />
            </div>
            <div style={{ alignSelf: 'flex-start' }}>
              {minGreatThanMax(values.minAge, values.maxAge) && (
                <Error
                  text={Strings.inputs.mayority.minGreatThanMaxError}
                  field
                />
              )}
            </div>

            <div className="btn-panel">
              <Button
                text="Confirmar"
                color={color.red}
                hBtn={48}
                wBtn={328}
                btnClick={handleSubmit}
                disabled={
                  !isValid || minGreatThanMax(values.minAge, values.maxAge)
                }
              />
              <Button
                text="Omitir"
                color={color.black}
                hBtn={48}
                wBtn={328}
                btnClick={handleSubmit}
                btnMaring='5px 0'
              />
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

export default PositionRequirement;
