import React, { useState, useEffect } from 'react';
import { color } from 'theme/colors';
import Icon from 'components/icons/icon';
import shop from 'assets/images/shop.png';
import { sinceCreated } from 'utils/sinceModifiedPost';
import { useStores } from 'models/root-store/root-store-context';
import { observer } from 'mobx-react-lite';
import api from 'services/api/api';
import { randomColor } from 'utils/randomColor';
import { StyledJobDetail } from './styledJobDetail';

export const JobDetail: React.FC = observer(function JobDetail(): React.ReactElement {
  const {
    renderStore,
    sessionStore,
    meJobsStore,
    JobPositionStore,
    skillsModel,
    dataCommerceStore,
  } = useStores();
  const [job, setJob] = useState<any>();
  const business = sessionStore.currentUser?.business;

  const handleStateJob = () => {
    if (
      meJobsStore &&
      meJobsStore.jobs[meJobsStore.index].state === 'Enabled'
    ) {
      api.job.putJobPause({ jobId: job?.id });
    } else {
      api.job.putJobResume({ jobId: job?.id });
    }
    meJobsStore.getMeJobs();
  };

  const editJob = () => {
    JobPositionStore.setId(job.id);
    JobPositionStore.setCategory(job.name, job.modalityId);
    JobPositionStore.setBusinessSegment(job.businessSegmentId);
    JobPositionStore.setRequirement(
      job.minimumAge,
      job.maximumAge,
      job.genderId,
    );
    job.skills?.split(',').map((skillName: string) => {
      return skillsModel.addSkill(skillName, randomColor());
    });
    JobPositionStore.setSkills(job.skills);
    JobPositionStore.setDescription(job.description);
    renderStore.setStepPostOffer(1);
    renderStore.setRenderPostOffer(true);
    renderStore.setRenderJobDetail(false);
    renderStore.setRenderPublishedOffers(false);
  };

  const copyLink = (jobId: string) => {
    navigator.clipboard.writeText(`http://jobs.bubajobs.com/${jobId}`);
    renderStore.setNotification('Enlace copiado al portapapeles', 3000, '', '');
    renderStore.setRenderNotification(true);
  };

  useEffect(() => {
    const { index } = meJobsStore;
    if (meJobsStore.jobs) {
      setJob(meJobsStore.jobs[index]);
    }
    // eslint-disable-next-line
  }, [meJobsStore.index]);

  return (
    <StyledJobDetail>
      <div className="photoBox">
        <div
          onKeyDown={() => renderStore.setRenderJobDetail(false)}
          tabIndex={0}
          role="button"
          onClick={() => renderStore.setRenderJobDetail(false)}
          className="iconDiv"
        >
          <Icon icon="closeCircle" />
        </div>
        <img
          src={dataCommerceStore.pictures || shop}
          className="photoBox"
          alt="shop"
        />
      </div>
      <div className="dataBox">
        <span className="title">{job?.name || 'Puesto'} </span>
        <div className="iconTextJobDetail">
          <Icon
            icon="shop"
            height={14.67}
            width={14.67}
            color={color.palette.shades.lightGray}
          />
          <span className="text">{business?.name || 'No ingresado'}</span>
        </div>
        <div className="iconTextJobDetail">
          <Icon
            icon="mapPin"
            height={14.67}
            width={14.67}
            color={color.palette.shades.lightGray}
          />
          <span className="text">{business?.address || 'No ingresado'}</span>
        </div>

        <div className="iconTextJobDetail">
          <Icon
            icon="clock"
            height={14.67}
            width={14.67}
            color={color.palette.shades.lightGray}
          />
          <span className="text">
            {sinceCreated(job?.modifiedAt) || 'Recién publicada'}
          </span>
        </div>
      </div>

      <div className="buttonsBox">
        {job && job.id && (
          <div
            className="action-jobdetail"
            role="button"
            tabIndex={0}
            onClick={() => copyLink(job.id)}
            onKeyPress={() => copyLink(job.id)}
          >
            <Icon icon="share" height={21.83} width={21.83} color={color.red} />
            <span className="buttonText">Compartir</span>
          </div>
        )}
        <div
          className="action-jobdetail"
          role="button"
          tabIndex={0}
          onClick={editJob}
          onKeyPress={editJob}
        >
          <Icon icon="pen" height={21.83} width={21.83} color={color.red} />
          <span className="buttonText">Editar oferta</span>
        </div>
        <div
          className="action-jobdetail"
          role="button"
          tabIndex={0}
          onClick={handleStateJob}
          onKeyPress={handleStateJob}
        >
          {meJobsStore &&
          meJobsStore.jobs[meJobsStore.index].state === 'Enabled' ? (
            <>
              <Icon
                icon="pause"
                height={21.83}
                width={21.83}
                color={color.red}
              />
              <span className="buttonText">Pausar búsqueda</span>
            </>
          ) : (
            <>
              <Icon
                icon="resume"
                height={21.83}
                width={21.83}
                color={color.red}
              />
              <span className="buttonText">Reanudar búsqueda</span>
            </>
          )}
        </div>
      </div>
    </StyledJobDetail>
  );
});
