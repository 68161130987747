import React, { useEffect } from 'react';
import GlobalStyle from 'theme/globalStyles';
import 'assets/fonts/index.css';
import { AppStyled } from 'App.styled';
import Map from 'components/sections/map/map';
import Menu from 'components/sections/Menu';
import Modal from 'components/common/Modal';
import Notification from 'components/common/Notification';
import CardsListButton from 'components/sections/cardsList/cardListButton';

// Left side
import { Chat } from 'components/sections/chat/chat';
import CardsList from 'components/sections/cardsList/cardsList';
import { JobDetail } from 'components/sections/jobDetail/jobDetail';
import { EmployeeDetail } from 'components/sections/employeeDetail/employeeDetail';
import PublishedOffers from 'components/sections/publishedOffers/publishedOffers';
import YourConversations from 'components/sections/yourConversations/yourConversations';

// Right side
import PostOffer from 'components/sections/PostOffer';
import UserSettings from 'components/sections/UserSettings';
import Login from 'components/sections/Login';
import Register from 'components/sections/Register';
import CommerceData from 'components/sections/CommerceData';

import { useStores } from 'models/root-store/root-store-context';
import { observer } from 'mobx-react-lite';
import getFirebase from 'services/firebase/client';

const App: React.FC = observer(() => {
  const {
    loginStore,
    renderStore,
    employeesStore,
    mastersStore,
    meJobsStore,
    sessionStore,
    dataCommerceStore,
    contactsStore,
    registrationStore
  } = useStores();

  useEffect(() => {
    employeesStore.getMeEmployees();
    // eslint-disable-next-line
  }, [loginStore.userLogged]);

  useEffect(() => {
    if (sessionStore.currentUser && sessionStore.currentUser.business) {
      const { name, id } = sessionStore.currentUser.business;
      if(name === null){
        loginStore.setOpenRegister(true)
        registrationStore.setStep('RegisterCommerce') 
      }
      renderStore.setRenderNotification(true);
      renderStore.setNotification(
        `Sesión iniciada. Bienvenido ${name && true ? name : ''}.`,
        5000,
        '',
        '',
      );
      mastersStore.getMasters();
      dataCommerceStore.getDataCommerce(id!);
      contactsStore.getMeContacts();
      
    }
    // eslint-disable-next-line
  }, [sessionStore.currentUser]);

  const refresh = async () => {
    if (sessionStore.currentUser) {
      await getFirebase()
        .auth()
        .currentUser?.getIdToken(true)
        .then((token) => {
          sessionStore.setToken(token);
        });
    }
  };

  useEffect(() => {
    const tokenRefreshInterval = setInterval(() => {
      refresh();
    }, 1000 * 60); // Cada 1 minuto
    return () => clearInterval(tokenRefreshInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=> {
    if(sessionStore.currentUser?.business?.id){
      contactsStore.getFirebaseContacts(sessionStore.currentUser.business.id)
    }
  }, [sessionStore.currentUser?.business])

  return (
    <AppStyled>
      <GlobalStyle />
      <Menu />
      {renderStore.renderList && <CardsListButton />}
      {renderStore.renderModalSegment && <Modal />}
      <div className="main-app">
        {/* Left side */}
        {renderStore.renderCardList && <CardsList />}
        {renderStore.renderYourConversations && <YourConversations />}
        {renderStore.renderPublishedOffers && <PublishedOffers />}
        {renderStore.renderEmployeeDetail && <EmployeeDetail />}
        {renderStore.renderJobDetail && meJobsStore.jobs && <JobDetail />}
        {renderStore.renderChat && <Chat />}

        {/* Center side */}
        <Map />
        {renderStore.renderNotification && renderStore.renderNotificationProps && (
          <Notification
            message={renderStore.renderNotificationProps.message}
            time={renderStore.renderNotificationProps.time}
            render={{
              type: renderStore.renderNotificationProps.renderType,
              btnText: renderStore.renderNotificationProps.renderBtnText,
            }}
          />
        )}

        {/* Right side */}
        {loginStore.openLogin && <Login />}
        {loginStore.openRegister && <Register />}
        {renderStore.renderCommerceData && <CommerceData />}
        {renderStore.renderPostOffer && <PostOffer />}
        {renderStore.renderUserSettings && <UserSettings />}
      </div>
    </AppStyled>
  );
});

export default App;
