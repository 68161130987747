import styled from 'styled-components';
import { StepperProps } from 'lib/types';
import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';

export const StyledStepper = styled.div<StepperProps>`
  .front-rail {
    background-color: ${color.palette.shades.lightGray};
    border-radius: ${px2rem(12)};
    width: ${px2rem(212)};
    height: ${px2rem(7)};
    position: relative;

    .actual-step {
      display: flex;
      justify-content: center;
      align-items: center;
      width: ${px2rem(36)};
      height: ${px2rem(24)};
      background-color: ${color.red};
      border-radius: ${px2rem(12)};
      color: ${color.white};
      border-radius: ${px2rem(10)};
      font-family: 'Lexend';
      font-size: ${px2rem(12)};
      position: absolute;
      left: ${(props) => px2rem((212 * props.step) / props.total - 18)};
      top: -7px;
    }

    .behind-rail {
      background-color: ${color.red};
      border-radius: ${px2rem(12)};
      width: ${(props) => px2rem((212 * props.step) / props.total)};
      height: ${px2rem(7)};
      position: absolute;
      left: 0;
    }
  }
`;
