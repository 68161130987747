import React from 'react';
import { BtnProps, LinkProps } from 'lib/types';
import Icon from 'components/icons/icon';
import {
  StyledButton,
  StyledIconBtn,
  StyledLink,
  StyledContactBtn,
} from './index.styled';

const Button: React.FC<BtnProps> = ({
  icon,
  text,
  link,
  wBtn,
  hBtn,
  color,
  btnClick,
  disabled,
  btnMaring
}) => {
  const getIcon = () => {
    if (icon) {
      return <Icon icon={icon} />;
    }
    return '';
  };

  if (link) {
    return (
      <a href={link}>
        <StyledButton
          type="button"
          icon={icon}
          wBtn={wBtn}
          hBtn={hBtn}
          color={color}
          btnMaring={btnMaring}
        >
          {getIcon()} <span>{text}</span>
        </StyledButton>
      </a>
    );
  }
  return (
    <StyledButton
      type="button"
      icon={icon}
      wBtn={wBtn}
      hBtn={hBtn}
      color={color}
      onClick={btnClick}
      disabled={disabled}
      btnMaring={btnMaring}
    >
      {getIcon()} <span>{text}</span>
    </StyledButton>
  );
};

export default Button;

export const IconBtn: React.FC<BtnProps> = ({ icon, btnClick }) => {
  return (
    <StyledIconBtn type="button" onClick={btnClick}>
      <Icon icon={icon} />
    </StyledIconBtn>
  );
};

export const LinkBtn: React.FC<LinkProps> = ({
  text,
  textColor,
  textSize,
  textFont,
  btnClick,
  disabled,
}) => {
  return (
    <StyledLink
      type="button"
      textColor={textColor}
      textSize={textSize}
      textFont={textFont}
      onClick={btnClick}
      disabled={disabled}
    >
      {text}
    </StyledLink>
  );
};

export const ContactBtn: React.FC<BtnProps> = ({
  icon,
  text,
  wBtn,
  hBtn,
  color,
  textSize,
  btnClick,
  disabled,
}) => {
  const getIcon = () => {
    if (icon) {
      return <Icon icon={icon} />;
    }
    return '';
  };

  return (
    <StyledContactBtn
      type="button"
      icon={icon}
      wBtn={wBtn}
      hBtn={hBtn}
      textSize={textSize}
      color={color}
      onClick={btnClick}
      disabled={disabled}
    >
      {getIcon()} <span>{text}</span>
    </StyledContactBtn>
  );
};
