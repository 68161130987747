import React from 'react';
import { CustomTextarea } from 'components/common/Input';
import Button from 'components/common/Button';
import { Formik } from 'formik';
import { color } from 'theme/colors';
import { useStores } from 'models/root-store/root-store-context';

const CommerceDescription: React.FC = () => {
  const { renderStore, dataCommerceStore } = useStores();

  const initialValues = {
    description: dataCommerceStore.description || '',
  };
  return (
    <>
      <h1 className="title-rightpanel">
        Contanos qué hace destacar a tu negocio
      </h1>
      <p className="paragraph-rightpanel">
        Esta información la vamos a compartir con los postulantes
      </p>
      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange
        onSubmit={(values) => {
          const { description } = values;
          dataCommerceStore.setDescription(description);
          dataCommerceStore.putDataCommerce({ ...dataCommerceStore });
          renderStore.setStepCommcerData(renderStore.stepCommerceData + 1);
        }}
      >
        {({ handleSubmit, values, isValid }) => (
          <>
            <div className="field-wrapper">
              <CustomTextarea
                name="description"
                placeholder="En nuestro negocio nos dedicamos a..."
                maxLength={220}
              />
            </div>
            <div className="field-wrapper">
              {values.description === '' ? (
                <Button
                  text="Omitir"
                  color={color.black}
                  hBtn={48}
                  wBtn={328}
                  btnClick={handleSubmit}
                />
              ) : (
                <Button
                  text="Continuar"
                  color={color.red}
                  hBtn={48}
                  wBtn={328}
                  btnClick={handleSubmit}
                />
              )}
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

export default CommerceDescription;
