import { types, Instance } from 'mobx-state-tree';
import { ExperienceModel } from '../experienceModel';
import { EmploymentRelationshipModel } from '../employmentRelationshipModel';

export const ReferenceModel = types.model('Reference').props({
  email: types.maybeNull(types.string),
  employmentRelationship: types.maybeNull(EmploymentRelationshipModel),
  employmentRelationshipId: types.maybeNull(types.number),
  id: types.maybeNull(types.string),
  modifiedAt: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  phoneNumber: types.maybeNull(types.string),
  experienceId: types.maybeNull(types.string),
  experience: types.maybeNull(ExperienceModel),
});

type ReferenceModelType = Instance<typeof ReferenceModel>;
export type Reference = ReferenceModelType;
