import React, { useState, useEffect } from 'react';
import Icon from 'components/icons/icon';
import EmployeeCard from 'components/common/employeeCard';
import { color as colors } from 'theme/colors';
import { FlapProps } from 'lib/types';
import { observer } from 'mobx-react-lite';
import { useStores } from 'models/root-store/root-store-context';
import { StyledFlap } from './index.styled';

const Flap: React.FC<FlapProps> = observer(({ color, text, number, data }) => {
  const { renderStore, employeesStore } = useStores();
  const disabled = number === 0;
  const [open, setOpen] = useState(text === renderStore.renderFlap && true);
  const [chevron, setChevron] = useState<'chevronUp' | 'chevronDown'>(
    text === renderStore.renderFlap ? 'chevronUp' : 'chevronDown',
  );

  const handleClick = () => {
    // if (disabled) return;
    if (renderStore.renderFlap === 'En contacto') {
      renderStore.setRenderFlap('Nuevos postulantes');
    } else {
      renderStore.setRenderFlap('En contacto');
    }
  };

  useEffect(() => {
    setOpen(text === renderStore.renderFlap && true);
    setChevron(text === renderStore.renderFlap ? 'chevronUp' : 'chevronDown');
  }, [renderStore.renderFlap, text]);


  const unReadContacts = data.filter((contact: any) => contact.unreadMessages);
  const readContacts = data.filter((contact: any) => !contact.unreadMessages);

  return (
    <StyledFlap color={color} open={open}>
      <div
        className="flap"
        tabIndex={0}
        role="button"
        onKeyDown={handleClick}
        onClick={handleClick}
      >
        <div className="flap-title">
          <span className="flap-title-text">{text}</span>
          {unReadContacts.length > 0 && <div className="flap-circle-count">
            <span className="circle-count-number">{unReadContacts.length}</span>
          </div>}
        </div>
        <Icon color={color} width={14} height={14} icon={chevron} />
      </div>

      <div className="scroll-cards">
        {unReadContacts.length > 0 && (
          <>
            <div
              style={{
                fontFamily: 'Lexend',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '12px',
                lineHeight: '18px',
                textAlign: 'left',
                alignSelf: 'flex-start',
                marginLeft: '18px',
                color: '#C1C1C1',
              }}
            >
              Sin leer
            </div>
            {unReadContacts.map((employee: any, index: number) => (
              <div className="card-wrapper">
                <EmployeeCard
                  index={index}
                  disabled={!open}
                  employee={employee}
                  selected={
                    employeesStore.employeeSelected === employee.id &&
                    renderStore.renderChat
                  }
                  bgSelected={colors.darkGrey}
                  hiring
                />
              </div>
            ))}
          </>
        )}
        {readContacts.length > 0 && (
          <>
            <div
              style={{
                fontFamily: 'Lexend',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '12px',
                lineHeight: '18px',
                textAlign: 'left',
                alignSelf: 'flex-start',
                marginLeft: '18px',
                color: '#C1C1C1',
              }}
            >
              Leidos
            </div>
            {readContacts.map((employee: any, index: number) => (
              <div className="card-wrapper">
                <EmployeeCard
                  index={index}
                  disabled={!open}
                  employee={employee}
                  selected={
                    (employeesStore.employeeSelected === employee.id &&
                      renderStore.renderEmployeeDetail) ||
                    (employeesStore.employeeSelected === employee.id &&
                      renderStore.renderChat)
                  }
                  bgSelected={colors.darkGrey}
                  hiring
                />
              </div>
            ))}
          </>
        )}
        {/* {data.length > 0 &&
          data.map((employee: any, index: number) => (
            <div className="card-wrapper">
              <EmployeeCard
                index={index}
                disabled={!open}
                employee={employee}
                selected={
                  employeesStore.employeeSelected === employee.id &&
                  renderStore.renderEmployeeDetail
                }
                bgSelected={colors.darkGrey}
                hiring
              />
            </div>
          ))} */}
      </div>
    </StyledFlap>
  );
});

export default Flap;
