import React from 'react';
import { SvgProps } from './icon.props';

const ShareSvg: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || 21}
      height={height || 22}
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6581 6.81485C14.383 7.54668 15.3885 8 16.5 8C18.7091 8 20.5 6.20914 20.5 4C20.5 1.79086 18.7091 0 16.5 0C14.2909 0 12.5 1.79086 12.5 4C12.5 4.37701 12.5522 4.74185 12.6497 5.08767L7.34193 8.18514C6.61703 7.45332 5.61146 7 4.5 7C2.29086 7 0.5 8.79086 0.5 11C0.5 13.2091 2.29086 15 4.5 15C5.61167 15 6.61742 14.5465 7.34235 13.8144L12.6509 16.9079C12.5526 17.255 12.5 17.6214 12.5 18C12.5 20.2091 14.2909 22 16.5 22C18.7091 22 20.5 20.2091 20.5 18C20.5 15.7909 18.7091 14 16.5 14C15.3902 14 14.386 14.452 13.6613 15.1819L8.3505 12.0871C8.4479 11.7415 8.5 11.3768 8.5 11C8.5 10.623 8.44784 10.2581 8.35034 9.91231L13.6581 6.81485ZM16.5 2C15.3954 2 14.5 2.89543 14.5 4C14.5 4.34726 14.5885 4.67385 14.7442 4.95844C14.7544 4.97391 14.7642 4.98975 14.7737 5.00596C14.7831 5.02205 14.792 5.0383 14.8004 5.05468C15.1533 5.6222 15.7825 6 16.5 6C17.6046 6 18.5 5.10457 18.5 4C18.5 2.89543 17.6046 2 16.5 2ZM6.19977 9.94554C6.20813 9.96184 6.21698 9.978 6.22632 9.99402C6.23573 10.0101 6.24553 10.0259 6.25569 10.0413C6.41145 10.326 6.5 10.6526 6.5 11C6.5 11.3473 6.41148 11.6739 6.25576 11.9585C6.24546 11.9742 6.23553 11.9902 6.226 12.0065C6.21675 12.0224 6.20799 12.0384 6.19971 12.0546C5.84681 12.6221 5.21753 13 4.5 13C3.39543 13 2.5 12.1046 2.5 11C2.5 9.89543 3.39543 9 4.5 9C5.21757 9 5.84688 9.3779 6.19977 9.94554ZM14.5 18C14.5 17.6749 14.5776 17.3678 14.7153 17.0964C14.74 17.064 14.7629 17.0296 14.784 16.9935C14.8042 16.9588 14.8221 16.9234 14.8377 16.8875C15.1966 16.3523 15.8072 16 16.5 16C17.6046 16 18.5 16.8954 18.5 18C18.5 19.1046 17.6046 20 16.5 20C15.3954 20 14.5 19.1046 14.5 18Z"
        fill={color || '#0D0D0D'}
      />
    </svg>
  );
};

export default ShareSvg;
