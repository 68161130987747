import React from 'react';
import Icon from 'components/icons/icon';
import { KeysMasters } from 'lib/types';
import { color } from 'theme/colors';
import { useStores } from 'models/root-store/root-store-context';
import { observer } from 'mobx-react-lite';
import { StyledSegment } from './index.styled';

interface SegmentProps extends KeysMasters {
  icon: any;
}

const Segment: React.FC<SegmentProps> = observer(function Segment({
  id,
  description,
  icon,
}) {
  const { JobPositionStore } = useStores();

  const handleClick = () => {
    JobPositionStore.setBusinessSegment(id);
  };

  return (
    <StyledSegment check={JobPositionStore.businessSegmentId === id}>
      <div
        role="button"
        onClick={handleClick}
        onKeyDown={handleClick}
        tabIndex={0}
        className="segment-container"
      >
        <div className="segment-icon">
          <Icon icon={icon} />
        </div>
        <span className="segment-name">{description}</span>
        <div className="segment-selected">
          <Icon icon="check" color={color.black} />
        </div>
      </div>
    </StyledSegment>
  );
});

export default Segment;
