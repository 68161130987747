import React from 'react';
import { MessageProps } from 'lib/types';
import defaultRed from 'assets/images/defaultRed.svg';
import { StyledMessage } from './styledMessage';


const Message: React.FC<MessageProps> = ({
  message,
  date,
  isCurrentUser = false,
  employeePicture,
}) => {
  if(employeePicture === null || employeePicture === undefined){
    employeePicture = defaultRed
  }  
  return (
    <StyledMessage isCurrentUser={isCurrentUser}>
      <div className="box">
        {!isCurrentUser && (
          <div className="photoBox">
            <img className="photoBubble" src={employeePicture} alt="dudePic" />
          </div>
        )}
        <div className="messageBox">
          <p className="messageText">
            {message || 'Solicito una revisión de la evaluación recibida...'}
          </p>
        </div>
      </div>
      <div style={{ marginTop: 4 }}>
        <span className="dateText"> {date || 'Viernes 18:33'}</span>
      </div>
    </StyledMessage>
  );
};

export default Message;
