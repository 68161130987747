import { types, Instance, SnapshotOut } from 'mobx-state-tree';

export const RoomModel = types
  .model('Room')
  .props({
    roomId: types.optional(types.string, ''),
  })
  .actions((self) => ({
    setRoom: (id: string) => {
      self.roomId = id;
    },
  }));

type RoomModelType = Instance<typeof RoomModel>;
export type Room = RoomModelType;
type RoomSnapshotType = SnapshotOut<typeof RoomModel>;
export type RoomSnapshot = RoomSnapshotType;
export const roomDefaultModel = () => types.optional(RoomModel, {});
