export const iconSegments = [
  { id: 1, iconName: 'cleaning' },
  { id: 2, iconName: 'restaurant' },
  { id: 3, iconName: 'supermarket' },
  { id: 4, iconName: 'hotel' },
  { id: 5, iconName: 'transport' },
  { id: 6, iconName: 'construction' },
  { id: 7, iconName: 'management' },
  { id: 8, iconName: 'realEstate' },
  { id: 9, iconName: 'health' },
  { id: 10, iconName: 'mechanics' },
  { id: 11, iconName: 'education' },
  { id: 12, iconName: 'others' },
  { id: 13, iconName: 'supermarket' },
  { id: 14, iconName: 'management' },
];
