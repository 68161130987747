import styled from 'styled-components';
import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';
import {
  DisplayBarProps,
  CircleWiconProps,
  AbilityBubbleProps,
} from 'lib/types';

interface DisplayProp {
  room: string;
}

export const StyledDisplay = styled.div<DisplayProp>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 360px);

  .displayBar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .dislpayScroll {
    height: calc(100vh - 415px);
    max-height: calc(100vh - 500px);
    margin: 16px 0;
    padding-bottom: ${(props) => (props.room === '' ? '10px' : '80px')};
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const StyledDisplayBox = styled.div<DisplayBarProps>`
  margin-left: 2px;
  margin-right: 2px;
  background-color: ${(props) =>
    props.selected ? 'rgba(255, 0, 0, 0.1)' : color.palette.shades.darkGrey};
  height: ${px2rem(32)};
  width: ${px2rem(126)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: ${(props) => (props.end ? px2rem(8) : 0)};
  border-bottom-right-radius: ${(props) => (props.end ? px2rem(8) : 0)};
  border-top-left-radius: ${(props) => (props.init ? px2rem(8) : 0)};
  border-bottom-left-radius: ${(props) => (props.init ? px2rem(8) : 0)};
  cursor: pointer;

  .text {
    font-family: 'Lexend';

    color: ${(props) => (props.selected ? color.red : color.white)};
  }
`;

export const StyledCard = styled.div`
  width: ${px2rem(513)};
  background-color: ${color.darkGrey};
  margin-top: ${px2rem(16)};
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;

  .skills-box {
    display: flex;
    flex-wrap: wrap;
  }

  .upperBox {
    display: flex;
    flex-direction: row;
    padding: ${px2rem(14)} 0;
    width: 100%;
  }

  .iconBox {
    display: flex;
    width: 20%;
    height: 100%;
    justify-content: center;
    align-self: center;
  }

  .edu-box {
    display: flex;
    width: 20%;
    height: 100%;
    justify-content: center;
  }

  .data-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    padding-left: 0.5em;
  }
  .data-wrapper {
    display: flex;
    flex-direction: row;
    margin-top: 0.5em;
    justify-content: space-between;
  }

  .cardTitle {
    font-family: 'Lexend';
    color: ${color.white};
    margin-top: 0.5em;
  }

  .refCardTitle {
    font-family: 'Lexend';
    color: ${color.white};
    margin-bottom: 0.5em;
  }

  .data-item {
    display: flex;
    align-content: center;
    max-width: ${px2rem(120)};
  }

  .icon-wrapper {
    width: min-content;
    height: min-content;
    margin-right: ${px2rem(5.33)};
  }

  .data-text {
    font-family: 'Lexend';
    font-size: ${px2rem(14)};
    color: ${color.palette.shades.lightGray};
  }

  .blackLine {
    height: 0.1em;
    width: 100%;
    background-color: ${color.black};
  }
  .hasRef {
    display: flex;
    flex-direction: row;
    height: ${px2rem(34)};
    width: 100%;
    align-items: center;
    padding-left: 1em;
  }
  .refBox {
    padding: 1em;
    display: flex;
    flex-direction: row;
    height: ${px2rem(119)};
  }
  .refDataBox {
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-content: space-between;
    padding-left: 0.5em;
  }
  .phoneBox {
    align-self: center;
    margin-left: 3em;
  }
  .refBoxNotConected {
    padding: 1em;
    display: flex;
    align-items: center;
    height: ${px2rem(119)};
  }
  .refTextNotConected {
    display: flex;
    margin: 0 auto;
    text-align: center;
    font-family: 'Lexend';
    color: ${color.palette.shades.mediumGray};
  }
  .cardBox {
    height: ${px2rem(119)};
    display: flex;
    flex-direction: row;
  }
  .notRegisteredBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em;
  }
  .notRegisteredTitle {
    font-family: 'Lexend Mega';
    color: ${color.white};
    letter-spacing: -0.12em;
    font-size: ${px2rem(20)};
    text-align: center;
    width: ${px2rem(430)};
  }

  .notRegisteredButton {
    margin-top: 0.2em;
    font-size: ${px2rem(14)};
    font-family: 'Lexend';
    color: ${color.red};
  }
`;

export const StyledCircleWicon = styled.div<CircleWiconProps>`
  width: ${(props) => (props.width ? px2rem(props.width) : px2rem(60))};
  height: ${(props) => (props.height ? px2rem(props.height) : px2rem(60))};
  background-color: ${(props) => props.color};
  border-radius: 2em;
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const StyledEduWicon = styled(StyledCircleWicon)`
  border-radius: ${px2rem(16)};
  align-items: flex-start;
  padding-top: ${px2rem(10)};
`;

export const StyledAbilityBubble = styled.div<AbilityBubbleProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.color};
  width: fit-content;
  height: fit-content;
  padding: ${px2rem(4)};
  padding-left: ${px2rem(10)};
  padding-right: ${px2rem(10)};
  border-radius: 0.5em;
  margin: ${px2rem(3)} ${px2rem(5)};

  .text-skill {
    font-family: 'Lexend';
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    max-width: 280px;
  }

  .remove-skill {
    display: flex;
    margin-left: ${px2rem(8)};
    cursor: pointer;
  }
`;

export const StyledFooter = styled.div`
  width: ${px2rem(544)};
  height: ${px2rem(110)};
  background-color: ${color.darkGrey};
  margin-left: -1em;
  display: flex;
  bottom: 0;
  flex-direction: column;
  position: absolute;
  bottom: 0;

  .footerBox {
    align-items: center;
    display: flex;
    padding: 1em;
    justify-content: center;
  }
  .footerText {
    font-family: 'Lexend';
    font-size: ${px2rem(14)};
    color: ${color.palette.shades.mediumGray};
    text-align: center;
  }
`;

export const StyledFooterButton = styled.button`
  width: 100%;
  cursor: pointer;
  width: ${px2rem(495)};
  height: ${px2rem(48)};
  align-self: center;
  background-color: ${color.darkGrey};
  border-radius: ${px2rem(12)};
  border-width: 1px;
  border-color: ${color.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .text {
    font-family: 'Lexend Mega';
    color: ${color.white};
  }
`;
