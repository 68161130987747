import React from 'react';
import { SvgProps } from './icon.props';

const ListSvg: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.99967 3.3335C1.63148 3.3335 1.33301 3.63197 1.33301 4.00016C1.33301 4.36835 1.63148 4.66683 1.99967 4.66683H2.00634C2.37453 4.66683 2.67301 4.36835 2.67301 4.00016C2.67301 3.63197 2.37453 3.3335 2.00634 3.3335H1.99967ZM5.33301 3.3335C4.96482 3.3335 4.66634 3.63197 4.66634 4.00016C4.66634 4.36835 4.96482 4.66683 5.33301 4.66683H13.9997C14.3679 4.66683 14.6663 4.36835 14.6663 4.00016C14.6663 3.63197 14.3679 3.3335 13.9997 3.3335H5.33301ZM5.33301 7.3335C4.96482 7.3335 4.66634 7.63197 4.66634 8.00016C4.66634 8.36835 4.96482 8.66683 5.33301 8.66683H13.9997C14.3679 8.66683 14.6663 8.36835 14.6663 8.00016C14.6663 7.63197 14.3679 7.3335 13.9997 7.3335H5.33301ZM4.66634 12.0002C4.66634 11.632 4.96482 11.3335 5.33301 11.3335H13.9997C14.3679 11.3335 14.6663 11.632 14.6663 12.0002C14.6663 12.3684 14.3679 12.6668 13.9997 12.6668H5.33301C4.96482 12.6668 4.66634 12.3684 4.66634 12.0002ZM1.33301 8.00016C1.33301 7.63197 1.63148 7.3335 1.99967 7.3335H2.00634C2.37453 7.3335 2.67301 7.63197 2.67301 8.00016C2.67301 8.36835 2.37453 8.66683 2.00634 8.66683H1.99967C1.63148 8.66683 1.33301 8.36835 1.33301 8.00016ZM1.99967 11.3335C1.63148 11.3335 1.33301 11.632 1.33301 12.0002C1.33301 12.3684 1.63148 12.6668 1.99967 12.6668H2.00634C2.37453 12.6668 2.67301 12.3684 2.67301 12.0002C2.67301 11.632 2.37453 11.3335 2.00634 11.3335H1.99967Z"
        fill={color || 'white'}
      />
    </svg>
  );
};

export default ListSvg;
