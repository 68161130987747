import Icon from 'components/icons/icon';
import React from 'react';
import { StyledAbilityBubble } from './styles.dislpayComponents';

interface AbilityBubbleProps {
  text: string;
  color: string;
  skillId: number;
  isDestroy: (arg: number) => void;
}

const AbilityBubble: React.FC<{ text: string; color: string }> = ({
  text,
  color,
}) => {
  return (
    <StyledAbilityBubble color={color}>
      <span style={{ fontFamily: 'Lexend' }}>{text}</span>
    </StyledAbilityBubble>
  );
};

export default AbilityBubble;

export const AbilityBubblePost: React.FC<AbilityBubbleProps> = ({
  text,
  color,
  skillId,
  isDestroy,
}) => {
  return (
    <StyledAbilityBubble color={color}>
      <span className="text-skill">{text}</span>

      <div
        role="button"
        tabIndex={0}
        onKeyPress={() => isDestroy(skillId)}
        onClick={() => isDestroy(skillId)}
        className="remove-skill"
      >
        <Icon icon="close" width={20} color="black" />
      </div>
    </StyledAbilityBubble>
  );
};
