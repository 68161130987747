import { types, Instance, SnapshotOut } from 'mobx-state-tree';

export const GenderModel = types
  .model('Gender')
  .props({
    createdAt: types.maybeNull(types.string),
    createdBy: types.maybeNull(types.string),
    modifiedAt: types.maybeNull(types.string),
    modifiedBy: types.maybeNull(types.string),
    state: types.maybeNull(types.string),
    id: types.maybeNull(types.number),
    description: types.maybeNull(types.string),
  })
  .actions(() => {
    return {};
  });
type GenderModelType = Instance<typeof GenderModel>;
export type Gender = GenderModelType;
type GenderSnapshotType = SnapshotOut<typeof GenderModel>;
export type GenderSnapshot = GenderSnapshotType;
export const createExperienceDefaultModel = () =>
  types.optional(GenderModel, {});
