import React, { useEffect, useState } from 'react';
import { useStores } from 'models/root-store/root-store-context';
import { color } from 'theme/colors';
import { observer } from 'mobx-react-lite';
import AbilityBubble from './abilityBuble';
import { StyledCard } from './styles.dislpayComponents';

const AbilityCard: React.FC = observer(() => {
  const { skillsModel } = useStores();
  return (
    <StyledCard
      style={{
        padding: '1em',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
      }}
    >
      <div className="skills-box">
        {skillsModel.skills.length ? (
          skillsModel.skills.map((item: any) => (
            <AbilityBubble
              text={item.skillName}
              color={item.color}
              key={`skill-${item.skillId}-${item.skillName}`}
            />
          ))
        ) : (
          <StyledCard>
            <div className="refBoxNotConected">
              <span className="refTextNotConected">
                No cargó ninguna hablidad
              </span>
            </div>
          </StyledCard>
        )}
      </div>
    </StyledCard>
  );
});

export default AbilityCard;
