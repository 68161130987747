import React from 'react';
import { SvgProps } from './icon.props';

const MessagesSvg: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || 25}
      height={height || 24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3848 1.29289C23.7754 1.68342 23.7754 2.31658 23.3848 2.70711L12.3848 13.7071C11.9943 14.0976 11.3612 14.0976 10.9706 13.7071C10.5801 13.3166 10.5801 12.6834 10.9706 12.2929L21.9706 1.29289C22.3612 0.902369 22.9943 0.902369 23.3848 1.29289Z"
        fill={color || 'white'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3848 1.29292C23.6565 1.56456 23.7485 1.96779 23.6216 2.33038L16.6216 22.3304C16.4861 22.7174 16.1282 22.9825 15.7185 22.9992C15.3088 23.0159 14.9305 22.7808 14.7639 22.4062L10.9201 13.7576L2.2716 9.91384C1.89692 9.74731 1.66186 9.36894 1.67857 8.95926C1.69528 8.54959 1.96039 8.19162 2.34739 8.05617L22.3474 1.05617C22.71 0.929264 23.1132 1.02128 23.3848 1.29292ZM5.38881 9.11063L12.0839 12.0862C12.3101 12.1868 12.491 12.3677 12.5916 12.5939L15.5671 19.289L21.0478 3.63L5.38881 9.11063Z"
        fill={color || 'white'}
      />
    </svg>
  );
};

export default MessagesSvg;
