import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { color } from 'theme/colors';
import Icon from 'components/icons/icon';
import { SrcContactProps } from 'lib/types';
import { useStores } from 'models/root-store/root-store-context';
import { filter } from 'lodash';
import {StyledSearchContacs} from './index.style'

const SearchContact: React.FC<SrcContactProps> = observer((props) => {
  const {
    placeHolder,
    setContacts,
    setApplicants,
    contacts,
    applicants,
    getApplicants,
    getContacts,
  } = props;
  const { renderStore } = useStores();
  const [isFilter, setIsFilter] = useState<boolean>(false);

  const handleClick = () =>{
    if(isFilter){
        getContacts();
        setIsFilter(false)
        setInputValue('')
    }
  } 
  const searchContacts = (e: any) => {
    const userSearch = e.target.value.toLocaleLowerCase();
    setInputValue(userSearch)
    const filterArr = (o: any) => {
      return (
        o.firstName.split(' ').join('').toString().toLocaleLowerCase() ===
          userSearch ||
        o.lastName.split(' ').join('').toString().toLocaleLowerCase() ===
          userSearch ||
        o.profession.toString().toLocaleLowerCase() === userSearch ||
        o.firstName.split(' ').join('').toString().toLocaleLowerCase() +
          o.lastName.split(' ').join('').toString().toLocaleLowerCase() ===
          userSearch.split(' ').join('')
      );
    };

    if (renderStore.renderFlap === 'En contacto') {
      const findContacts = filter(contacts, filterArr);
      if (findContacts.length > 0 && userSearch) {
        setContacts(findContacts);
        setIsFilter(true)
      } else if (
        (findContacts.length === 0 && !userSearch) ||
        userSearch === ''
      ) {
        getContacts();
        setIsFilter(false)
      }
    } else {
      const findApliccants = filter(applicants, filterArr);
      if (findApliccants.length > 0 && userSearch) {
        setApplicants(findApliccants);
      } else if (
        (findApliccants.length === 0 && !userSearch) ||
        userSearch === ''
      ) {
        getApplicants();
        setIsFilter(false)
      }
    }
  };
  const [inputValue,setInputValue] = useState('')
  return (
    <StyledSearchContacs>
      <input value={inputValue} type="text" onChange={searchContacts} placeholder={placeHolder} />
      <div className="search-icon">
        <Icon icon="search" color={color.white} />
      </div>
      {isFilter && <button type="button" className="clear-icon" onClick={handleClick}>
          <Icon icon="closeCircle" color={color.white} />
        </button>}
    </StyledSearchContacs>
  );
});

export default SearchContact;
