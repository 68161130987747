import React from 'react';
import { SvgProps } from './icon.props';

const CalendarSvg: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || 14}
      height={height || 16}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3334 1.33335C10.3334 0.965164 10.0349 0.666687 9.66671 0.666687C9.29852 0.666687 9.00004 0.965164 9.00004 1.33335V2.00002H5.00004V1.33335C5.00004 0.965164 4.70156 0.666687 4.33337 0.666687C3.96518 0.666687 3.66671 0.965164 3.66671 1.33335V2.00002H2.33337C1.2288 2.00002 0.333374 2.89545 0.333374 4.00002V6.66669V13.3334C0.333374 14.4379 1.2288 15.3334 2.33337 15.3334H11.6667C12.7713 15.3334 13.6667 14.4379 13.6667 13.3334V6.66669V4.00002C13.6667 2.89545 12.7713 2.00002 11.6667 2.00002H10.3334V1.33335ZM12.3334 6.00002V4.00002C12.3334 3.63183 12.0349 3.33335 11.6667 3.33335H10.3334V4.00002C10.3334 4.36821 10.0349 4.66669 9.66671 4.66669C9.29852 4.66669 9.00004 4.36821 9.00004 4.00002V3.33335H5.00004V4.00002C5.00004 4.36821 4.70156 4.66669 4.33337 4.66669C3.96518 4.66669 3.66671 4.36821 3.66671 4.00002V3.33335H2.33337C1.96518 3.33335 1.66671 3.63183 1.66671 4.00002V6.00002H12.3334ZM1.66671 7.33335H12.3334V13.3334C12.3334 13.7015 12.0349 14 11.6667 14H2.33337C1.96518 14 1.66671 13.7015 1.66671 13.3334V7.33335Z"
        fill={color || '#C1C1C1'}
      />
    </svg>
  );
};

export default CalendarSvg;
