import styled from 'styled-components';
import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';

export const StyledSegment = styled.div<{ check: boolean }>`
  .segment-container {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 300px;
    height: 72px;
    margin-bottom: ${px2rem(15)};
    padding-left: ${px2rem(16)};
    background-color: ${color.black};
    border: ${px2rem(1)} solid
      ${(props) => (props.check ? color.red : color.black)};
    border-radius: ${px2rem(16)};
    font-family: 'Lexend';
    font-weight: 700;
    font-size: ${px2rem(16)};
    color: ${color.white};
    cursor: pointer;

    // TODO: Width responsive pero fluido
    @media (min-width: 1440px) {
      width: 328px;
    }
  }

  .segment-name {
    padding-left: ${px2rem(16)};
  }

  .segment-selected {
    display: ${(props) => (props.check ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: ${color.red};
    right: -1px;
    bottom: -1px;
    border-radius: ${px2rem(16)} 0 ${px2rem(16)} 0;
  }
`;
