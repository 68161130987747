import React, { Dispatch, SetStateAction } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'models/root-store/root-store-context';
import Button, { IconBtn } from 'components/common/Button';
import { color } from 'theme/colors';
import { ChangePasswordSchema } from 'utils/schema-validations';
import Icon from 'components/icons/icon';
import { Formik } from 'formik';
import { CustomPasswordInput } from 'components/common/Input';

interface ValuesProps {
  newpassword: string;
  repeatNewPassword: string;
}

interface LinkIconTextProps {
  link: string;
  text: string;
}

interface IProps {
  setChangePassword: Dispatch<SetStateAction<string>>;
}

const LinkIconText = ({ link, text }: LinkIconTextProps) => (
  <a href={link || ''} target="_blanck" className="a">
    <p>{text || ''}</p>
    <Icon icon="arrowUpRigth" color={color.red} />
  </a>
);

const Settings = ({ setChangePassword }: IProps) => {
  const { renderStore } = useStores();

  const initialValues = {
    newPassword: '',
    repeatNewPassword: '',
  };
  const handleClose = () => {
    renderStore.setRenderUserSettings(false);
  };

  const onSubmit = (values: any) => {
    if (values.newPassword === values.repeatNewPassword) {
      setChangePassword(values.repeatNewPassword);
    }
  };

  return (
    <>
      <>
        <div className="header-panel">
          <h2 className="title-rightpanel">Configuración</h2>
          <IconBtn icon="close" btnClick={handleClose} />
        </div>
      </>
      {/* <>
        <p className="subsection-tittle-rightpanel">Modificar contraseña</p>
        <br />
        <Formik
          initialValues={initialValues}
          onSubmit={(values: any) => {
            onSubmit(values);
          }}
          validationSchema={ChangePasswordSchema}
          validateOnBlur={false}
          validateOnChange
          >
          {({ handleSubmit, values, isValid }) => (
            <div>
            <p className="subsection-subtittle-rightpanel">
            Nueva contraseña
            </p>
            <div className="field-wrapper">
                <CustomPasswordInput name="newPassword" autoComplete="new-password"/>
              </div>
              <p className="subsection-subtittle-rightpanel">
              Repetir nueva contraseña
              </p>
              <div className="field-wrapper">
              <CustomPasswordInput name="repeatNewPassword" />
              </div>
              <div className="field-wrapper">
              <Button
              text="Cambiar contraseña"
              color={color.red}
              hBtn={48}
              wBtn={328}
              btnClick={() => (isValid ? handleSubmit() : null)}
              disabled={!isValid}
              />
              </div>
              </div>
              )}
        </Formik>
      </> */}
      <div className="line" />
      <>
        <>
          <p className="footer-tittle-rightpanel">Eliminar mis datos</p>
          <p className="footer-content-rightpanel">
            En caso de que no quieras usar nunca más Buba, podés solicitar para
            eliminar tus datos
          </p>
          <LinkIconText
            link="https://bubajobs.com.ar/eliminar-cuenta"
            text="Eliminar mi cuenta"
          />
          <LinkIconText
            link="https://bubajobs.com.ar/ayuda"
            text="Necesito ayuda"
          />
        </>
      </>
    </>
  );
};
export default Settings;
