import { ApiResponse } from 'apisauce';
import { Api } from './api';
import { getGeneralApiProblem } from './api-problem';

export class BusinessApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async putBusinessProfile(body: any): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.put(
        '/business',
        body,
      );
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const businessData = response.data;
      return { kind: 'ok', businessData };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getBusinessProfile(businessId: string): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/business/${businessId}`,
      );
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const businessData = response.data;
      return { kind: 'ok', businessData };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
