import React from 'react';
import { SvgProps } from './icon.props';

const RealEstateSvg: React.FC<SvgProps> = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.2" cx="20" cy="20" r="20" fill="#CB88FF" />
      <path
        d="M31.0508 30.6015H29.0742V27.0527C29.0742 26.8046 28.873 26.6035 28.625 26.6035C28.3769 26.6035 28.1758 26.8046 28.1758 27.0527V30.6015H22.7852V22.0664C22.7852 21.8183 22.584 21.6172 22.3359 21.6172H17.6641C17.416 21.6172 17.2149 21.8183 17.2149 22.0664V30.6015H11.8243V20.4492H11.9971C12.1163 20.4492 12.2305 20.4018 12.3148 20.3176L20 12.6323L27.6853 20.3176C27.7695 20.4018 27.8838 20.4492 28.0029 20.4492H28.1758V25.0875C28.1758 25.3356 28.3769 25.5367 28.625 25.5367C28.873 25.5367 29.0742 25.3356 29.0742 25.0875V20.4492H31.0508C31.2325 20.4492 31.3962 20.3397 31.4658 20.1719C31.5353 20.004 31.4968 19.8108 31.3684 19.6823L20.3177 8.63156C20.1422 8.45615 19.8578 8.45615 19.6823 8.63156L14.6753 13.614C14.4999 13.7894 14.4999 14.0739 14.6753 14.2493C14.8508 14.4247 15.1352 14.4247 15.3107 14.2493L20 9.58449L29.9662 19.5507H28.189L20.3177 11.6794C20.1422 11.504 19.8578 11.504 19.6823 11.6794L11.811 19.5507H10.0338L13.5794 16.0051C13.7548 15.8297 13.7548 15.5453 13.5794 15.3698C13.404 15.1944 13.1195 15.1944 12.9441 15.3698L8.63159 19.6823C8.50316 19.8108 8.46466 20.004 8.5342 20.1719C8.60374 20.3397 8.76757 20.4492 8.94928 20.4492H10.9258V30.6015H8.94928C8.70122 30.6015 8.50006 30.8026 8.50006 31.0507C8.50006 31.2988 8.70122 31.4999 8.94928 31.4999H11.375H17.6641H22.3359H28.625H31.0508C31.2988 31.4999 31.5 31.2988 31.5 31.0507C31.5 30.8026 31.2988 30.6015 31.0508 30.6015ZM21.8867 30.6015H18.1133V22.5156H21.8867V30.6015Z"
        fill="#CB88FF"
      />
      <path
        d="M26.7383 21.6172H24.2227C23.9746 21.6172 23.7734 21.8183 23.7734 22.0664V26.5586C23.7734 26.8067 23.9746 27.0078 24.2227 27.0078H26.7383C26.9863 27.0078 27.1875 26.8067 27.1875 26.5586V22.0664C27.1875 21.8183 26.9863 21.6172 26.7383 21.6172ZM26.2891 26.1094H24.6719V22.5156H26.2891V26.1094Z"
        fill="#CB88FF"
      />
      <path
        d="M15.7773 21.6172H13.2617C13.0137 21.6172 12.8125 21.8183 12.8125 22.0664V26.5586C12.8125 26.8067 13.0137 27.0078 13.2617 27.0078H15.7773C16.0254 27.0078 16.2265 26.8067 16.2265 26.5586V22.0664C16.2265 21.8183 16.0254 21.6172 15.7773 21.6172ZM15.3281 26.1094H13.7109V22.5156H15.3281V26.1094Z"
        fill="#CB88FF"
      />
      <path
        d="M20 15.8816C18.8606 15.8816 17.9336 16.8086 17.9336 17.948C17.9336 19.0874 18.8606 20.0144 20 20.0144C21.1394 20.0144 22.0664 19.0874 22.0664 17.948C22.0664 16.8086 21.1394 15.8816 20 15.8816ZM20 19.116C19.3559 19.116 18.832 18.592 18.832 17.948C18.832 17.304 19.3559 16.78 20 16.78C20.644 16.78 21.168 17.304 21.168 17.948C21.168 18.592 20.644 19.116 20 19.116Z"
        fill="#CB88FF"
      />
      <path
        d="M19.464 26.241C19.3805 26.1574 19.2646 26.1094 19.1465 26.1094C19.0283 26.1094 18.9124 26.1574 18.8289 26.241C18.7453 26.3245 18.6973 26.4404 18.6973 26.5586C18.6973 26.6767 18.7453 26.7926 18.8289 26.8762C18.9125 26.9597 19.0283 27.0078 19.1465 27.0078C19.2646 27.0078 19.3805 26.9597 19.464 26.8762C19.5476 26.7926 19.5957 26.6767 19.5957 26.5586C19.5957 26.4404 19.5476 26.3245 19.464 26.241Z"
        fill="#CB88FF"
      />
    </svg>
  );
};

export default RealEstateSvg;
