import { ApiResponse } from 'apisauce';
import { Api } from './api';
import { getGeneralApiProblem } from './api-problem';

export class MeUserApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getMeUserProfile(): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        '/me/profile',
      );
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const userProfile = response.data;
      return { kind: 'ok', userProfile };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getMeJobs(): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        '/me/jobs',
      );
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const jobs = response.data;
      return { kind: 'ok', jobs };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getMeContacts(): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        '/me/contacts',
      );
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const contacts = response.data;
      return { kind: 'ok', contacts };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getMeApplicants(): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        '/me/applicants',
      );
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const applicants = response.data;
      return { kind: 'ok', applicants };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
