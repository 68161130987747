import React from 'react';
import { SvgProps } from './icon.props';

const GoogleSvg: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || 25}
      height={height || 25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9938 3.27393C14.2552 3.27242 16.322 4.10481 17.9037 5.48062C18.1608 5.70421 18.1737 6.10031 17.9327 6.34128L16.2617 8.01229C16.0492 8.22481 15.7106 8.24235 15.479 8.05078C14.5329 7.26806 13.3216 6.79571 12.0002 6.79567C10.1875 6.79563 8.58227 7.68604 7.5845 9.04708L4.51624 7.27555C6.13866 4.85543 8.90004 3.276 11.9938 3.27393ZM3.92788 8.29132L6.99364 10.0613C6.6925 10.7391 6.52178 11.4867 6.52174 12.2739C6.52171 13.061 6.69232 13.8089 6.99343 14.4869L3.94057 16.2495C3.33908 15.0417 3 13.6894 3 12.2739C3.00004 10.8385 3.3355 9.48843 3.92788 8.29132ZM20.9384 11.2326C20.9034 10.9347 20.6529 10.7091 20.3529 10.7091L12.9783 10.7087C12.6542 10.7087 12.3914 10.9715 12.3914 11.2957V13.6416C12.3914 13.9657 12.6542 14.2285 12.9783 14.2285H17.1109C16.7302 15.2192 16.0691 16.0708 15.2274 16.6899L16.9827 19.7302C18.7495 18.5329 20.0928 16.7227 20.6845 14.5857C21.0062 13.4239 21.0641 12.3003 20.9384 11.2326ZM12.0001 17.7522C12.7881 17.7521 13.5354 17.5811 14.2124 17.2795L15.9688 20.3217C14.7634 20.9278 13.4158 21.2739 12 21.2739C8.86807 21.2739 6.13763 19.6615 4.52881 17.2652L7.58399 15.5013C8.58106 16.8618 10.1853 17.7522 12.0001 17.7522Z"
        fill={color || 'white'}
      />
    </svg>
  );
};

export default GoogleSvg;
