import React from 'react';
import { color } from 'theme/colors';
import { StyleLoadingSpinner, bubaLoaderProps } from './index.styled';

const BubaLoaderFace = ({ logoColor, logoWidth, wSpn}: bubaLoaderProps) => {
  const centerCalc = wSpn && (logoWidth) ? (wSpn * 2 - logoWidth * 2) / 4 : 7;
  return (
    <svg
      width={logoWidth ? logoWidth.toString(): '36'}
      height={logoWidth ? logoWidth.toString(): '36'}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: 'absolute',
        top: centerCalc ,
        left: centerCalc ,
      }}
    >
      <path
        d="M13.8425 16.4705C14.5656 16.4705 15.1517 15.6322 15.1517 14.598C15.1517 13.5639 14.5656 12.7256 13.8425 12.7256C13.1194 12.7256 12.5332 13.5639 12.5332 14.598C12.5332 15.6322 13.1194 16.4705 13.8425 16.4705Z"
        fill={logoColor || '#F24545'}
      />
      <path
        d="M23.153 16.4695C23.8761 16.4695 24.4623 15.6312 24.4623 14.5971C24.4623 13.5629 23.8761 12.7246 23.153 12.7246C22.4299 12.7246 21.8438 13.5629 21.8438 14.5971C21.8438 15.6312 22.4299 16.4695 23.153 16.4695Z"
        fill={logoColor || '#F24545'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5368 21.1497C24.8154 21.2952 24.9236 21.6395 24.7782 21.9187L24.2736 21.6551C24.7782 21.9187 24.7781 21.919 24.7779 21.9193L24.7775 21.92L24.7767 21.9216L24.7746 21.9254C24.7731 21.9284 24.7712 21.9319 24.7689 21.9361C24.7643 21.9445 24.7583 21.9554 24.7507 21.9687C24.7355 21.9952 24.7142 22.0312 24.6865 22.0752C24.6309 22.1633 24.5494 22.2839 24.4389 22.4262C24.2181 22.7105 23.8805 23.0828 23.4033 23.4531C22.4419 24.1992 20.9353 24.92 18.7315 24.92C16.5294 24.92 14.9637 24.2005 13.9443 23.4651C13.4372 23.0992 13.0682 22.7317 12.8234 22.4517C12.7009 22.3116 12.6092 22.1929 12.5464 22.1067C12.515 22.0636 12.4908 22.0285 12.4736 22.0028C12.465 21.99 12.4582 21.9795 12.453 21.9715C12.4505 21.9675 12.4483 21.9641 12.4466 21.9614L12.4444 21.9578L12.4435 21.9564L12.4431 21.9557C12.4429 21.9555 12.4427 21.9552 12.9266 21.6551L12.4427 21.9552C12.2773 21.6875 12.3598 21.3362 12.627 21.1705C12.8936 21.0052 13.2432 21.0869 13.4092 21.353C13.4091 21.3528 13.409 21.3527 13.4091 21.3527L13.4095 21.3534L13.4099 21.3541M13.4092 21.353C13.4094 21.3532 13.4096 21.3536 13.41 21.3542C13.4114 21.3564 13.4144 21.361 13.419 21.3679C13.4283 21.3817 13.4439 21.4045 13.4661 21.4351C13.5107 21.4962 13.5814 21.5881 13.6798 21.7007C13.8767 21.926 14.1829 22.2322 14.6096 22.54C15.458 23.1521 16.7947 23.78 18.7315 23.78C20.6668 23.78 21.9312 23.1533 22.7061 22.5519C23.097 22.2486 23.3688 21.9472 23.5405 21.7262C23.6262 21.6158 23.6866 21.5259 23.724 21.4666C23.7427 21.4369 23.7556 21.415 23.763 21.4021C23.7667 21.3956 23.769 21.3914 23.77 21.3896C23.7702 21.3893 23.7703 21.389 23.7704 21.3889C23.9165 21.1117 24.2589 21.0046 24.5368 21.1497"
        fill={logoColor || '#F24545'}
      />
    </svg>
  );
};
const BubaLoader = (props: bubaLoaderProps) => {
  const { btwSpn, wSpn, bwSpn, bsSpn, bstSpn, bcSpn, bctSpn, logoColor, logoWidth, logoDisplay } = props;
  return (
    <StyleLoadingSpinner
      btwSpn={btwSpn}
      wSpn={wSpn}
      bwSpn={bwSpn}
      bsSpn={bsSpn}
      bstSpn={bstSpn}
      bcSpn={bcSpn}
      bctSpn={bctSpn}
    >
      <div className="loading-spinner" />
      {!logoDisplay && <BubaLoaderFace wSpn={wSpn} logoWidth={logoWidth} logoColor={logoColor}  />}
    </StyleLoadingSpinner>
  );
};
export default BubaLoader;
