import React from 'react';
import { SvgProps } from './icon.props';

const HatSvg: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || 22}
      height={height || 17}
      viewBox="0 0 22 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7321 6.19008L19.7321 5.64008L10.7321 0.640078H10.6221C10.5608 0.614157 10.4971 0.594068 10.4321 0.580078H10.2421H10.0621C9.99374 0.594077 9.92681 0.614157 9.86207 0.640078H9.75207L0.752065 5.64008C0.598168 5.7273 0.470159 5.85378 0.3811 6.00662C0.29204 6.15945 0.245117 6.33318 0.245117 6.51008C0.245117 6.68697 0.29204 6.8607 0.3811 7.01354C0.470159 7.16638 0.598168 7.29286 0.752065 7.38008L3.24207 8.76008V13.5001C3.24207 14.2957 3.55814 15.0588 4.12075 15.6214C4.68335 16.184 5.44642 16.5001 6.24207 16.5001H14.2421C15.0377 16.5001 15.8008 16.184 16.3634 15.6214C16.926 15.0588 17.2421 14.2957 17.2421 13.5001V8.76008L19.2421 7.64008V10.5001C19.2421 10.7653 19.3474 11.0196 19.535 11.2072C19.7225 11.3947 19.9769 11.5001 20.2421 11.5001C20.5073 11.5001 20.7616 11.3947 20.9492 11.2072C21.1367 11.0196 21.2421 10.7653 21.2421 10.5001V7.06008C21.2418 6.88288 21.1944 6.70895 21.1048 6.55608C21.0152 6.40321 20.8865 6.2769 20.7321 6.19008ZM15.2421 13.5001C15.2421 13.7653 15.1367 14.0196 14.9492 14.2072C14.7616 14.3947 14.5073 14.5001 14.2421 14.5001H6.24207C5.97685 14.5001 5.7225 14.3947 5.53496 14.2072C5.34742 14.0196 5.24207 13.7653 5.24207 13.5001V9.87008L9.75207 12.3701L9.90207 12.4301H9.99207C10.0751 12.4405 10.1591 12.4405 10.2421 12.4301C10.3251 12.4405 10.4091 12.4405 10.4921 12.4301H10.5821C10.6352 12.4189 10.6859 12.3986 10.7321 12.3701L15.2421 9.87008V13.5001ZM10.2421 10.3601L3.30207 6.50008L10.2421 2.64008L17.1821 6.50008L10.2421 10.3601Z"
        fill={color || '#28A55'}
      />
    </svg>
  );
};

export default HatSvg;
