import React from 'react';
import { SvgProps } from './icon.props';

const LogOutSvg: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33301 2.66683C3.1562 2.66683 2.98663 2.73707 2.8616 2.86209C2.73658 2.98712 2.66634 3.15668 2.66634 3.3335V12.6668C2.66634 12.8436 2.73658 13.0132 2.8616 13.1382C2.98663 13.2633 3.1562 13.3335 3.33301 13.3335H5.99967C6.36786 13.3335 6.66634 13.632 6.66634 14.0002C6.66634 14.3684 6.36786 14.6668 5.99967 14.6668H3.33301C2.80257 14.6668 2.29387 14.4561 1.91879 14.081C1.54372 13.706 1.33301 13.1973 1.33301 12.6668V3.3335C1.33301 2.80306 1.54372 2.29436 1.91879 1.91928C2.29387 1.54421 2.80257 1.3335 3.33301 1.3335H5.99967C6.36786 1.3335 6.66634 1.63197 6.66634 2.00016C6.66634 2.36835 6.36786 2.66683 5.99967 2.66683H3.33301Z"
        fill={color || 'white'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1953 4.19526C10.4556 3.93491 10.8777 3.93491 11.1381 4.19526L14.4714 7.5286C14.7318 7.78894 14.7318 8.21106 14.4714 8.4714L11.1381 11.8047C10.8777 12.0651 10.4556 12.0651 10.1953 11.8047C9.93491 11.5444 9.93491 11.1223 10.1953 10.8619L13.0572 8L10.1953 5.13807C9.93491 4.87772 9.93491 4.45561 10.1953 4.19526Z"
        fill={color || 'white'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33301 8.00016C5.33301 7.63197 5.63148 7.3335 5.99967 7.3335H13.9997C14.3679 7.3335 14.6663 7.63197 14.6663 8.00016C14.6663 8.36835 14.3679 8.66683 13.9997 8.66683H5.99967C5.63148 8.66683 5.33301 8.36835 5.33301 8.00016Z"
        fill={color || 'white'}
      />
    </svg>
  );
};

export default LogOutSvg;
