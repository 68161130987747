import React, { useEffect, useState } from 'react';
import { color } from 'theme/colors';
import { useStores } from 'models/root-store/root-store-context';
import { observer } from 'mobx-react-lite';
import firebase from 'firebase';
import { orderBy } from 'lodash';
import Flap from './components/Flap/flap';
import SearchContact from './components/Search/searchContacts';
import { StyledYourConversations } from './index.styled';
import BubaLoader from '../../common/bubaloader/BubaLoader';

const YourConversations: React.FC = observer(function YourConversations() {
  const { contactsStore, renderStore } = useStores();
  const [contacts, setContacts] = useState([{}]);
  const [applicants, setApplicants] = useState([{}]);
  const [isLoader, setIsLoader] = useState(false);
  useEffect(() => {
    contactsStore.getMeContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getApplicants = () => {
    const applicantss =
      contactsStore.penddingContacts.filter((item) => {
        if (!renderStore.renderFavourites) {
          return true;
        }
        return item.favourite;
      }) || [];
    const applicantsSorted = orderBy(applicantss, (o) => o.latestMessage, [
      'desc',
    ]);
    setApplicants(applicantsSorted);
  };

  const getContacts = async () => {
    const contactss = contactsStore.approvedContacts.filter((item) => {
      if (!renderStore.renderFavourites) {
        return true;
      }
      return item.favourite;
    });
    const contactsSorted = orderBy(contactss, (o) => o.latestMessage, ['desc']);
    setContacts(contactsSorted);
    setIsLoader(true)
  };

  useEffect(() => {
    getContacts();
    getApplicants();
  }, [contactsStore.approvedContacts, contactsStore.penddingContacts, renderStore.renderFavourites]);

  return (
    <StyledYourConversations renderFavourite={renderStore.renderFavourites}>
      <div className="buttons-container">
        <button
          type="button"
          className="button-selector button-alls"
          onClick={() => renderStore.setRenderFavourites(false)}
        >
          Todos
        </button>
        <button
          type="button"
          className="button-selector button-favourites"
          onClick={() => renderStore.setRenderFavourites(true)}
        >
          Favoritos
        </button>
      </div>
      {isLoader ? (
        <>
          <SearchContact
            placeHolder={renderStore.renderFlap === 'En contacto' ? "Buscá en contactos" : "Buscá en postulantes"}
            contacts={contacts}
            applicants={applicants}
            setContacts={setContacts}
            setApplicants={setApplicants}
            getApplicants={getApplicants}
            getContacts={getContacts}
          />
          <Flap
            text="Nuevos postulantes"
            number={applicants.length}
            color={color.passionPink}
            data={applicants}
          />
          <Flap
            text="En contacto"
            number={contacts.length}
            color={color.leafGreen}
            data={contacts}
          />
        </>
      ) : (
        <div className='bubaLoader-bubaloader'>
          <BubaLoader
            logoWidth={40} /* width-logo */
            logoColor="red" /* color-logo */
            wSpn={50} /* width-spinner */
            bwSpn={6} /* button-width (tamaño de la ruedita) */
            bctSpn="red" /* button-top-color (color de la ruedita) */
          />
        </div>
      )}
    </StyledYourConversations>
  );
});

export default YourConversations;
