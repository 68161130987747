import React from 'react';
import Icon from 'components/icons/icon';
import { sinceCreated } from 'utils/sinceModifiedPost';
import { iconSegments } from 'utils/iconSegments';
import { StyledPublishedJobCard } from './styledPublishedJobCard';

export default function PublishedJobCard(props: any) {
  const { data, index, setPressed, selected } = props;

  const icon: any =
    iconSegments.find((iconObj) => iconObj.id === data.businessSegmentId)
      ?.iconName || 'others';

  return (
    <StyledPublishedJobCard
      onClick={() => setPressed(index)}
      selected={selected}
    >
      <div className="job-icon">
        <Icon icon={icon || 'others'} />
      </div>
      <div className="job-details">
        <span className="title"> {data.name} </span>
        <span className="cardText"> {sinceCreated(data.modifiedAt)} </span>
      </div>
      {selected && (
        <div className="arrowIcon">
          <Icon icon="arrowRight"/>
        </div>
      )}
    </StyledPublishedJobCard>
  );
}
