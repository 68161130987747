import { types, Instance, SnapshotOut } from 'mobx-state-tree';

export const LoginModel = types
  .model('Login')
  .props({
    userLogged: types.maybe(types.boolean),
    openLogin: types.maybe(types.boolean),
    openRegister: types.maybe(types.boolean),
    step: types.optional(types.string, 'LoginOptions'),
  })
  .actions((self) => {
    function setLogin(userLogged: boolean) {
      self.userLogged = userLogged;
      self.openLogin = false;
      self.openRegister = false;
    }
    function setUserLogged(userLogged: boolean) {
      // Para el login post-registro
      self.userLogged = userLogged;
    }
    function setOpenLogin(open: boolean) {
      self.openRegister = false;
      self.openLogin = open;
    }
    function setOpenRegister(open: boolean) {
      self.openRegister = open;
      self.openLogin = false;
    }
    function setStep(step: string) {
      self.step = step;
    }
    return {
      setLogin,
      setUserLogged,
      setOpenLogin,
      setOpenRegister,
      setStep,
    };
  });

type LoginModelType = Instance<typeof LoginModel>;
export type Login = LoginModelType;
type LoginSnapshotType = SnapshotOut<typeof LoginModel>;
export type LoginSnapshot = LoginSnapshotType;
export const createLoginDefaultModel = () =>
  types.optional(LoginModel, {
    userLogged: false,
    openLogin: false,
    openRegister: false,
  });
