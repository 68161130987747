import { types } from 'mobx-state-tree';

export const TerminationReasonModel = types.model('TerminationReason').props({
  createdAt: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  modifiedAt: types.maybeNull(types.string),
  modifiedBy: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  description: types.maybeNull(types.string),
});
