import styled from 'styled-components';
import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';

interface UserMenuProps {
  menu?: boolean;
}

export const StyledUserMenu = styled.div<UserMenuProps>`
  display: flex;
  align-items: center;

  .options-user {
    display: flex;
    align-items: center;
    position: relative;

    .opt-menu {
      display: flex;
      align-items: center;
      color: ${color.white};
      font-family: 'Lexend';
      font-size: ${px2rem(14)};
      margin-right: ${px2rem(25)};
      cursor: pointer;

      .opt-text {
        display: none;
      }

      .opt-text-hover {
        color: ${color.red};
      }
    }

    .commerce {
      background-color: ${color.darkGrey};
      border: none;
    }

    .commerce > :first-child {
      margin-right: ${px2rem(10)};
    }

    .commerce-dropdown {
      position: absolute;
      z-index: 3000;
      top: ${px2rem(30)};
      right: ${px2rem(20)};
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: ${(props) => (props.menu ? px2rem(14) : '0')} ${px2rem(16)};
      background-color: ${color.palette.primary.purple};
      border-radius: ${px2rem(12)} 0 ${px2rem(12)} ${px2rem(12)};
      width: ${px2rem(219)};
      height: ${(props) => (props.menu ? px2rem(146) : px2rem(0))};
      transition-duration: 300ms;
      overflow: hidden;

      button {
        background-color: transparent;
        border: none;
        cursor: pointer;
      }

      li,
      button > li {
        font-family: 'Lexend';
        font-size: ${px2rem(14)};
        color: ${color.white};
        display: flex;
        align-items: center;

        span {
          margin-left: ${px2rem(8)};
        }
      }
    }
  }

  .logout {
    background-color: ${color.darkGrey};
    border: none;
    cursor: pointer;
    color: ${color.red};
    font-family: 'Lexend Mega';
    font-size: ${px2rem(16)};
    margin-right: ${px2rem(19)};
  }

  @media (min-width: 1440px) {
    .options-user > .opt-menu > .opt-text {
      display: flex;
    }
  }
`;
