import React from 'react';
import { StepperProps } from 'lib/types';
import { StyledStepper } from './index.styled';

const Stepper: React.FC<StepperProps> = ({ step, total }) => {
  return (
    <StyledStepper step={step} total={total}>
      <div className="front-rail">
        <div className="behind-rail" />
        <div className="actual-step">
          {step}/{total}
        </div>
      </div>
    </StyledStepper>
  );
};

export default Stepper;
