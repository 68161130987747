import React from 'react';
import { LinkBtn } from 'components/common/Button';
import { color } from 'theme/colors';
import { useStores } from 'models/root-store/root-store-context';
import { StyledCard } from './styledPublishedJobCard';

export default function NotPublishedOffert() {
  const { skillsModel, renderStore } = useStores();
  return (
    <StyledCard>
      <div className="notRegisteredBox">
        <span className="notRegisteredTitle">
          Todavía no publicaste ninguna oferta laboral
        </span>
        <span className="notRegisteredButton">
          <LinkBtn
            text="Publicar oferta laboral"
            textColor={color.red}
            btnClick={() => {
                skillsModel.removeSkills();
                renderStore.closeLeftComponents();
                renderStore.setRenderPostOffer(!renderStore.renderPostOffer);
                renderStore.setStepPostOffer(1);
                renderStore.setRenderCommerceData(false);
                renderStore.setStepCommcerData(1);;
            }}
          />
        </span>
      </div>
    </StyledCard>
  );
}
