import React, { useEffect, useState } from 'react';
import Button from 'components/common/Button';
import { color } from 'theme/colors';
import { useStores } from 'models/root-store/root-store-context';
import { observer } from 'mobx-react-lite';
import Notification from '../Notification';

const CommerceLocation: React.FC = observer(function Map() {
  const { renderStore, dataCommerceStore } = useStores();
  const [hasLocation, setHasLocation] = useState(false);
  const { location, address } = dataCommerceStore;

  const saveLocation = () => {
    const dataCommerce = { ...dataCommerceStore };
    dataCommerceStore.putDataCommerce(dataCommerce);
    setHasLocation(true);
    renderStore.setRenderNotification(true);
    renderStore.setNotification(
      `Ubicación actualizada extiosamente`,
      5000,
      '',
      '',
    );
  };
  useEffect(() => {
    if ((location && location.lat && location.lng) || address) {
      setHasLocation(true);
    }
  }, [dataCommerceStore.location?.lat, dataCommerceStore.location?.lng]);
  return (
    <>
      <h1 className="title-rightpanel">
        {address || location
          ? '¿Es esta la ubicación correcta de tu lugar?'
          : 'Ayudanos a encontrar la dirección de tu local'}
      </h1>
      <p className="paragraph-rightpanel">
        Escribí la dirección de tu negocio en el campo o posicionalo apretando
        sobre el mapa
      </p>
      <div className="field-wrapper">
        <Button
          text='Crear comercio'
          color={color.red}
          hBtn={48}
          wBtn={328}
          btnClick={() => {
            saveLocation();
            setHasLocation(false);
          }}
          disabled={!hasLocation}
        />
      </div>
        <Button
          text="Agregar más datos"
          color={color.black}
          hBtn={48}
          wBtn={328}
          btnClick={() => {
            renderStore.setStepCommcerData(renderStore.stepCommerceData + 1);
          }}
        />
      {renderStore.renderNotification && renderStore.renderNotificationProps && (
        <Notification
          message={renderStore.renderNotificationProps.message}
          time={renderStore.renderNotificationProps.time}
          render={{
            type: renderStore.renderNotificationProps.renderType,
            btnText: renderStore.renderNotificationProps.renderBtnText,
          }}
        />
      )}
    </>
  );
});

export default CommerceLocation;
