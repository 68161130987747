import { types, Instance, SnapshotOut } from 'mobx-state-tree';
import { Location } from 'models/locationModel';
import api from 'services/api/api';

export const DataCommerceModel = types
  .model('DataCommerce')
  .props({
    name: types.maybeNull(types.string),
    employeesCountId: types.maybeNull(types.number),
    location: types.maybeNull(Location),
    address: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    pictures: types.maybeNull(types.string),
  })
  .actions((self) => {
    function setNameCommerce(name: string) {
      self.name = name;
    }

    function setEmployeeCount(id: number) {
      self.employeesCountId = id;
    }

    function setLocation(latitude: number, longitude: number, address: string) {
      self.location = {
        lat: latitude,
        lng: longitude,
      };
      self.address = address;
    }

    function setDescription(description: string) {
      self.description = description;
    }

    function setPictures(pictures: string) {
      self.pictures = pictures;
    }

    return {
      setNameCommerce,
      setEmployeeCount,
      setLocation,
      setDescription,
      setPictures,
    };
  })
  .actions(() => ({
    putDataCommerce: async (body: any) => {
      await api.business.putBusinessProfile(body);
    },
  }))
  .actions((self) => ({
    getDataCommerce: async (businessId: string) => {
      const request = await api.business.getBusinessProfile(businessId);
      const data = request.businessData;

      if (data) {
        self.setNameCommerce(data.name);
        self.setEmployeeCount(data.employeesCountId);
        if (data.location)
          self.setLocation(data.location.lat, data.location.lng, data.address);
        self.setDescription(data.description);
        self.setPictures(data.pictures);
      }
    },
  }));

type DataCommerceModelType = Instance<typeof DataCommerceModel>;
export type DataCommerce = DataCommerceModelType;
type DataCommerceSnapshotType = SnapshotOut<typeof DataCommerceModel>;
export type DataCommerceSnapshot = DataCommerceSnapshotType;
export const createDataCommerceDefaultModel = () =>
  types.optional(DataCommerceModel, {});
