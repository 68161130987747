import React, { useCallback, useState } from 'react';
import Icon from 'components/icons/icon';
import getFirebase from 'services/firebase/client';
import { useStores } from 'models/root-store/root-store-context';
import { observer } from 'mobx-react-lite';
import api from 'services/api/api';
import { StyledFooter } from './styledFooter';

export const Footer: React.FC<{ name: string }> = observer(function Footer({
  name,
}) {
  const [message, setMessage] = useState('');
  const { renderStore, sessionStore, roomStore } = useStores();

  const handleClick = () => {
    renderStore.setRenderChat(false);
    renderStore.setRenderEmployeeDetail(true);
  };

  const postMessage = useCallback((text: string) => {
    const data = {
      text,
      createdAt: new Date().getTime(),
      user: {
        _id: sessionStore.currentUser?.business?.id,
        email: sessionStore.currentUser?.email || '',
        name: sessionStore.currentUser?.business?.name,
      },
    };

    setMessage('');

    getFirebase()
      .firestore()
      .collection('THREADS')
      .doc(roomStore.roomId)
      .collection('MESSAGES')
      .add(data);
    // eslint-disable-next-lineif
    api.contact.approveContact(roomStore.roomId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      postMessage(message);
    }
  };

  return (
    <StyledFooter>
      <div
        onKeyDown={handleClick}
        onClick={handleClick}
        tabIndex={0}
        className="button"
        role="button"
      >
        <span className="buttonText"> Ver perfil {`de ${name}` || ''}</span>
      </div>

      <div className="inputBox">
        <input
          type="text"
          name="message"
          className="input"
          placeholder="Escribí tu mensaje "
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <div
          tabIndex={0}
          className="circle"
          role="button"
          onKeyPress={() => postMessage(message)}
          onClick={() => postMessage(message)}
        >
          <Icon icon="messages" />
        </div>
      </div>
    </StyledFooter>
  );
});
