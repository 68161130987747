import React, { Suspense, useState } from 'react';
import { RootStoreProvider } from 'models/root-store/root-store-context';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import persist from 'mst-persist';
import api from 'services/api/api';
import ErrorBoundaryUI from 'components/common/ErrorBoundary';
import { rootStore } from 'models/root-store/root-store';
import rollbarConfig from 'utils/rollbarConfig';
import App from 'App';

const SpinnerXL: React.FC = () => <div>Cargando</div>;

const ConnectedApp: React.FC = () => {
  const [appReady, setAppReady] = useState(false);
  persist('bubajobs', rootStore).then(() => {
    if (rootStore.sessionStore.token) {
      api.api.setToken(rootStore.sessionStore.token);
    }
    setAppReady(true);
  });
  if (!appReady) {
    return <SpinnerXL />;
  }

  return (
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary fallbackUI={<ErrorBoundaryUI />}>
        <RootStoreProvider value={rootStore}>
          <Suspense fallback={<SpinnerXL />}>
            <App />
          </Suspense>
        </RootStoreProvider>
      </ErrorBoundary>
    </RollbarProvider>
  );
};

export default ConnectedApp;
