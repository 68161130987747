import { BtnProps, LinkProps } from 'lib/types';
import styled from 'styled-components';
import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';

const getBorder = () => {
  return `${px2rem(1)} solid ${color.white}`;
};

export const StyledButton = styled.button<BtnProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  width: ${(props) => (props.wBtn ? px2rem(props.wBtn) : px2rem(328))};
  height: ${(props) => (props.hBtn ? px2rem(props.hBtn) : px2rem(48))};
  padding: ${px2rem(8)};
  border-radius: ${px2rem(12)};
  border: ${(props) => (props.color === color.red ? 'none' : getBorder())};
  background-color: ${(props) =>
    props.disabled ? color.palette.shades.mediumGray : props.color};
  color: ${color.white};
  cursor: ${(props) => (props.disabled ? 'normal' : 'pointer')};
  margin:${(props) => (props.btnMaring ? props.btnMaring : '')};

  span {
    letter-spacing: ${px2rem(-1)};
    font-family: 'Lexend Mega';
    font-size: ${px2rem(16)};
  }
`;

export const StyledContactBtn = styled(StyledButton)<BtnProps>`
  border: none;
  border-radius: ${px2rem(4)};
  padding: ${px2rem(4)};

  span {
    ${(props)=>props.textSize?`font-size:${props.textSize}px;`:''}
  }
`;

export const StyledLink = styled.button<LinkProps>`
  font-family: ${(props) => props.textFont || 'Lexend'};
  font-size: ${(props) => px2rem(props.textSize || 14)};
  color: ${(props) => props.textColor};
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const StyledIconBtn = styled.button<BtnProps>`
  border: none;
  background-color: transparent;
  cursor: pointer;
  height: 24px;
  width: 24px;
`;
