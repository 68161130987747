import styled from 'styled-components';
import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';
import { CardProps } from 'lib/types';

export const StyledPublishedJobCard = styled.div<CardProps>`
  display: flex;
  width: ${px2rem(315)};
  height: ${px2rem(78)};
  background-color: ${(props) =>
    props.selected ? color.black : color.darkGrey};
  border-radius: 0.5em;
  flex-direction: row;
  padding: 1em;
  box-shadow: 1px 0px 16px rgba(0, 0, 0, 0.5);
  border-width: ${(props) => (props.selected ? '2px' : '0px')};
  border-style: solid;
  border-color: ${(props) => (props.selected ? color.red : color.darkGrey)};
  align-items: center;
  position: relative;


  .job-details {
    margin-left: ${px2rem(12)};
    display: flex;
    flex-direction: column;
  }

  .title {
    font-family: 'Lexend';
    color: ${color.white};
  }

  .cardText {
    color: ${color.palette.shades.lightGray};
    font-family: 'Lexend';
    font-size: ${px2rem(14)};
    align-self: flex-start;
    margin-top: ${px2rem(4)};
  }
  .arrowIcon {
    background-color: ${color.palette.shades.black};
    width: ${px2rem(25)};
    height: ${px2rem(25)};
    position: absolute;
    right: -1.2em;
    align-items: center;
    display: flex;
  }
`;


export const StyledCard = styled.div`
  width: ${px2rem(315)};
  background-color: ${color.darkGrey};
  border-radius: 0.5em;
  margin: auto 0;
  
  .notRegisteredBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em;
  }
  .notRegisteredTitle {
    font-family: 'Lexend Mega';
    color: ${color.white};
    letter-spacing: -0.12em;
    font-size: ${px2rem(20)};
    text-align: center;
  }

  .notRegisteredButton {
    margin-top: 0.2em;
    font-size: ${px2rem(14)};
    font-family: 'Lexend';
    color: ${color.red};
  }
`;