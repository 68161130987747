import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'models/root-store/root-store-context';
import EmployeeCard from 'components/common/employeeCard';
import Icon from 'components/icons/icon';
import { color } from 'theme/colors';
import { progressiveFilterEmployee } from 'utils/filterEmployee';
import { StyledCardsList } from './styledCardsList';

const CardsList: React.FC = observer((): React.ReactElement => {
  const { employeesStore, renderStore, filterStore } = useStores();

  const handleCLick = () => {
    renderStore.setRenderList(true);
    renderStore.setRenderCardList(false);
  };

  return (
    <StyledCardsList>
      <span className="text">Candidatos en la zona</span>
      <div className="scroll">
        {employeesStore.employees.map((employee, index) => {
          const isEmployeeFilter = progressiveFilterEmployee(
            employee,
            filterStore,
          );
          if (isEmployeeFilter) {
            return (
              <div style={{ margin: '0.3em' }}>
                <EmployeeCard
                  index={index}
                  employee={employee}
                  selected={
                    (employeesStore.employeeSelected === employee.id &&
                      renderStore.renderEmployeeDetail) ||
                    (employeesStore.employeeSelected === employee.id &&
                      renderStore.renderChat)
                  }
                  bgSelected={color.black}
                />
              </div>
            );
          }
          return null;
        })}
      </div>
      <button
        onClick={handleCLick}
        className="hideList"
        onKeyPress={handleCLick}
        type="button"
        tabIndex={0}
      >
        <Icon icon="chevronRight" />
      </button>
    </StyledCardsList>
  );
});

export default CardsList;
