import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { StyledUserSettings } from './index.styled';
import Settings from './sections/settings';
import ConfirmChange from './sections/confirmChange';

const UserSettings: React.FC = observer(function UserSettings() {
  const [changePassword, setChangePassword] = useState('');
  return (
    <StyledUserSettings>
      {changePassword === '' && (
        <Settings setChangePassword={setChangePassword} />
      )}
      {changePassword !== '' && (
        <ConfirmChange
          changePassword={changePassword}
          setChangePassword={setChangePassword}
        />
      )}
    </StyledUserSettings>
  );
});
export default UserSettings;
