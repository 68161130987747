import React, { useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import Icon from 'components/icons/icon';
import { color } from 'theme/colors';
import { useStores } from 'models/root-store/root-store-context';
import { observer } from 'mobx-react-lite';
import BubaLoader from '../bubaloader/BubaLoader';
import { StyledSearch } from './index.styled';

const debounceTime = 2000;

const Search: React.FC = observer(function Search(): React.ReactElement {
  const { filterStore, renderStore } = useStores();
  const searchTextRef = useRef<HTMLInputElement | null>(null);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    if (!renderStore.renderClearFilter && searchTextRef.current) {
      searchTextRef.current.value = '';
      setIsSearching(false);
    }
  }, [renderStore.renderClearFilter, isSearching]);

  const handleClick = () => {
    renderStore.setRenderFilter(!renderStore.renderFilter);
  };

  const handleClear = () => {
    filterStore.setClear();
    renderStore.setRenderClearFilter(false);
  };

  const debounceSearch = debounce((value) => {
    if (renderStore.renderClearFilter) {
      filterStore.setSearchText(value);
      setIsSearching(false);
      if (!renderStore.renderCardList) {
        renderStore.closeLeftComponents();
        renderStore.setRenderCardList(true);
        renderStore.setRenderList(false);
      }
    }
  }, debounceTime);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === '') {
      renderStore.setRenderClearFilter(false);
    } else {
      setIsSearching(true);
      renderStore.setRenderClearFilter(true);
    }
    debounceSearch(value);
  };
  return (
    <StyledSearch>
      <input
        ref={searchTextRef}
        type="text"
        name="searchText"
        id="searchText"
        onChange={handleSearch}
        placeholder="Buscá por habilidades..."
      />
      <div className="search-icon">
        {isSearching ? (
          <BubaLoader
            logoWidth={20} /* width-logo */
            logoColor="white" /* color-logo */
            wSpn={24} /* width-spinner */
            bwSpn={2} /* button-width (tamaño de la ruedita) */
            bctSpn="white" /* button-top-color (color de la ruedita) */
          />
        ) : (
          <Icon icon="search" color={color.white} />
        )}
      </div>
      {renderStore.renderClearFilter && (
        <button type="button" className="clear-icon" onClick={handleClear}>
          <Icon icon="closeCircle" color={color.white} />
        </button>
      )}
      <button type="button" className="filter-icon" onClick={handleClick}>
        <Icon
          icon="filters"
          color={renderStore.renderClearFilter ? color.red : color.white}
        />
      </button>
    </StyledSearch>
  );
});

export default Search;
