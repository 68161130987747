import ChevronDownSvg from './chevronDown';
import FiltersSvg from './filters';
import SearchSvg from './search';
import BriefCaseSvg from './briefCase';
import MapPinSvg from './mapPin';
import CalendarSvg from './calendar';
import ShopSvg from './shop';
import ClockSvg from './clock';
import StarSvg from './star';
import UserSvg from './user';
import PhoneSvg from './phone';
import FlagSvg from './flag';
import HatSvg from './hat';
import PlusSvg from './plus';
import MapSvg from './map';
import NotificationSvg from './notification';
import ComercioSvg from './comercio';
import EditSvg from './edit';
import ListSvg from './list';
import LogOutSvg from './logOut';
import ChekSvg from './check';
import MessagesSvg from './messages';
import ArrowRightSvg from './arrowRight';
import EyeSvg from './eye';
import EyeOffSvg from './eyeOff';
import PenSvg from './pen';
import ShareSvg from './share';
import PauseSvg from './pause';
import GoogleSvg from './google';
import FacebookSvg from './facebook';
import EmailSvg from './email';
import CloseCircleSvg from './closeCircle';
import ChevronUpSvg from './chevronUp';
import CloseSvg from './close';
import ThumbDownSvg from './thumDown';
import ThumbUpSvg from './thumbUp';
import BubaLogoSvg from './bubaLogo';
import ChevronLeftSvg from './chevronLeft';
import ChevronRightSvg from './chevronRight';
import ArrowLeftSvg from './arrowLeft';
import CleaningSvg from './cleaning';
import ManagementSvg from './management';
import RestaurantSvg from './restaurant';
import SupermarketSvg from './supermarket';
import HotelSvg from './hotel';
import TransportSvg from './transport';
import ConstructionSvg from './construction';
import RealEstateSvg from './realEstate';
import HealthSvg from './health';
import MechanicsSvg from './mechanics';
import EducationSvg from './education';
import OthersSvg from './others';
import AlertCircleSvg from './alertCircle';
import ResumeSvg from './resume';
import CameraSvg from './camera';
import HeartFilled from './heartFilled';
import HeartOutline from './heartOutline';
import ArrowUpRigth from './arrowUpRight';
import MessageNotification from './messageNotification';
import SettingsSvg from './settings';

export const icons = {
  chevronDown: ChevronDownSvg,
  closeCircle: CloseCircleSvg,
  search: SearchSvg,
  filters: FiltersSvg,
  mapPin: MapPinSvg,
  calendar: CalendarSvg,
  briefCase: BriefCaseSvg,
  shop: ShopSvg,
  messageNotification: MessageNotification,
  clock: ClockSvg,
  star: StarSvg,
  user: UserSvg,
  phone: PhoneSvg,
  flag: FlagSvg,
  hat: HatSvg,
  plus: PlusSvg,
  map: MapSvg,
  notification: NotificationSvg,
  comercio: ComercioSvg,
  edit: EditSvg,
  list: ListSvg,
  logOut: LogOutSvg,
  check: ChekSvg,
  messages: MessagesSvg,
  arrowRight: ArrowRightSvg,
  eye: EyeSvg,
  pause: PauseSvg,
  share: ShareSvg,
  pen: PenSvg,
  email: EmailSvg,
  facebook: FacebookSvg,
  google: GoogleSvg,
  chevronUp: ChevronUpSvg,
  close: CloseSvg,
  thumbUp: ThumbUpSvg,
  thumbDown: ThumbDownSvg,
  buba: BubaLogoSvg,
  chevronLeft: ChevronLeftSvg,
  chevronRight: ChevronRightSvg,
  arrowLeft: ArrowLeftSvg,
  cleaning: CleaningSvg,
  restaurant: RestaurantSvg,
  supermarket: SupermarketSvg,
  hotel: HotelSvg,
  transport: TransportSvg,
  construction: ConstructionSvg,
  management: ManagementSvg,
  realEstate: RealEstateSvg,
  health: HealthSvg,
  mechanics: MechanicsSvg,
  education: EducationSvg,
  others: OthersSvg,
  alertCircle: AlertCircleSvg,
  resume: ResumeSvg,
  camera: CameraSvg,
  heartOutline: HeartOutline,
  heartFilled: HeartFilled,
  arrowUpRigth: ArrowUpRigth,
  eyeOff: EyeOffSvg,
  settings: SettingsSvg,
};

export type IconTypes = keyof typeof icons;
