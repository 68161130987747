import React, { useEffect, useState } from 'react';
import { CustomTextInput } from 'components/common/Input';
import Select from 'components/common/Select';
import Button from 'components/common/Button';
import Label from 'components/common/Label';
import Icon from 'components/icons/icon';
import { Formik } from 'formik';
import { useStores } from 'models/root-store/root-store-context';
import { observer } from 'mobx-react-lite';
import { color } from 'theme/colors';
import { KeysMasters } from 'lib/types';
import { iconSegments } from 'utils/iconSegments';
import { CategoryJobSchema } from 'utils/schema-validations';

interface PositionCatProps {
  modalities: KeysMasters[];
  segments: KeysMasters[];
}

const PositionCategory: React.FC<PositionCatProps> = observer(
  ({ modalities, segments }) => {
    const { JobPositionStore, renderStore } = useStores();
    const [modalitiesOptions, setModalitiesOptions] = useState<string[]>([]);
    const [initialValues, setInitialValues] = useState({
      name: '',
      modality: '',
    });

    useEffect(() => {
      if (modalities) {
        const options = modalities.map((mod) => mod.description);
        setModalitiesOptions(options);
      }
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      setInitialValues({
        name: JobPositionStore.name || '',
        modality: modalitiesOptions[(JobPositionStore.modalityId || 1) - 1],
      });
      // eslint-disable-next-line
    }, [modalitiesOptions]);

    const segmentContent = () => {
      if (JobPositionStore.businessSegmentId === 0) {
        return <span className="field-segment-text">Agregar rubro</span>;
      }

      const iconName: any =
        iconSegments.find(
          (iconObj) => iconObj.id === JobPositionStore.businessSegmentId,
        )?.iconName || 'others';

      return (
        <>
          <div className="field-segment-icon">
            <Icon icon={iconName} />{' '}
          </div>
          <span className="field-segment-text">
            {
              segments[(JobPositionStore.businessSegmentId || 1) - 1]
                .description
            }
          </span>
        </>
      );
    };

    const onSubmit = (values: { name: string; modality: string }) => {
      const { name, modality } = values;
      JobPositionStore.setCategory(
        name,
        modalitiesOptions.indexOf(modality) + 1,
      );

      if (JobPositionStore.id) {
        const { id, ...body } = JobPositionStore;
        JobPositionStore.putJob(body, id);
      }

      renderStore.setStepPostOffer(renderStore.stepPostOffer + 1);
    };

    return (
      <>
        <h1 className="title-rightpanel">Describí el puesto</h1>
        <h2 className="section-rightpanel">
          Agrega los detalles de la búsqueda
        </h2>
        {initialValues.modality && modalitiesOptions && (
          <Formik
            initialValues={initialValues}
            validationSchema={CategoryJobSchema}
            onSubmit={onSubmit}
            validateOnChange
            validateOnBlur={false}
          >
            {({ handleSubmit, values, isValid }) => (
              <>
                <div className="field-wrapper">
                  <CustomTextInput
                    name="name"
                    type="text"
                    placeholder="Describí el puesto"
                    handleSubmit={handleSubmit}
                  />
                </div>
                <div className="field-wrapper">
                  <Select
                    name="modality"
                    id="modality"
                    options={modalitiesOptions}
                    selected={initialValues.modality}
                  />
                </div>
                <div className="btn-panel">
                  <Button
                    text="Confirmar"
                    color={color.red}
                    hBtn={48}
                    wBtn={328}
                    btnClick={handleSubmit}
                    disabled={
                      !isValid ||
                      !values.name ||
                      !values.modality ||
                      JobPositionStore.businessSegmentId === 0
                    }
                  />
                </div>
              </>
            )}
          </Formik>
        )}
        <div className="field-wrapper">
          <Label text="Rubros" />
          <div className="field-segment">
            {segmentContent()}
            <div
              role="button"
              onClick={() => renderStore.setRenderModalSegment(true)}
              onKeyDown={() => renderStore.setRenderModalSegment(true)}
              tabIndex={0}
              className="field-segment-action"
            >
              {JobPositionStore.businessSegmentId === 0 ? (
                <Icon icon="plus" width={20} height={20} />
              ) : (
                <Icon icon="edit" width={20} height={20} />
              )}
            </div>
          </div>
        </div>
      </>
    );
  },
);

export default PositionCategory;
