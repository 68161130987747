import { types, Instance, SnapshotOut } from 'mobx-state-tree';

export const FilterModel = types
  .model('Filter')
  .props({
    minimumAge: types.maybe(types.number),
    maximumAge: types.maybe(types.number),
    experience: types.maybe(types.number),
    reference: types.maybe(types.boolean),
    firstJob: types.maybe(types.boolean),
    searchText: types.maybe(types.string),
    firstName: types.maybe(types.string),
    lastName: types.maybe(types.string),
  })
  .actions((self) => {
    function setFilter(
      minimumAge: number,
      maximumAge: number,
      experience: number,
      reference: boolean,
      firstJob: boolean,
    ) {
      self.minimumAge = minimumAge;
      self.maximumAge = maximumAge;
      self.experience = experience;
      self.reference = reference;
      self.firstJob = firstJob;
    }

    function setSearchText(text: string) {
      self.searchText = text;
    }

    function setClear() {
      self.minimumAge = 16;
      self.maximumAge = 85;
      self.experience = -1;
      self.reference = false;
      self.firstJob = false;
      self.searchText = '';
    }

    return {
      setFilter,
      setSearchText,
      setClear,
    };
  });

type FilterModelType = Instance<typeof FilterModel>;
export type Filter = FilterModelType;
type FilterSnapshotType = SnapshotOut<typeof FilterModel>;
export type FilterSnapshot = FilterSnapshotType;

export const createFilterDefaultModel = () =>
  types.optional(FilterModel, {
    minimumAge: 16,
    maximumAge: 85,
    experience: -1,
    reference: false,
    firstJob: false,
  });
