import React from 'react';
import { SvgProps } from './icon.props';

const CleaningSvg: React.FC<SvgProps> = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.2" cx="20" cy="20" r="20" fill="#FF7A96" />
      <path
        d="M23.4217 12.4175C21.4581 12.4151 19.5638 13.1436 18.1079 14.4612C16.4295 12.3109 13.3958 11.7805 11.0877 13.2341C8.77939 14.6875 7.94651 17.6524 9.16024 20.0952C10.374 22.5379 13.2401 23.6649 15.7925 22.7032C15.9002 23.0566 16.0333 23.4017 16.1904 23.7361C14.6381 24.758 13.9488 26.6832 14.4996 28.4581C15.0504 30.2329 16.7086 31.4297 18.5666 31.3934C20.4247 31.3571 22.0347 30.0965 22.5158 28.3013C25.4529 28.6398 28.3359 27.3241 30.005 24.8836C31.6739 22.4432 31.8544 19.2792 30.4737 16.6648C29.093 14.0503 26.3783 12.4153 23.4217 12.4175ZM9.37801 17.7313C9.37486 15.7724 10.6268 14.0317 12.4849 13.4114C14.343 12.7911 16.3896 13.4307 17.5639 14.9986C16.2036 16.4635 15.4487 18.3893 15.4509 20.3882C15.4521 20.9163 15.5054 21.4426 15.61 21.96C14.2085 22.5218 12.6193 22.3503 11.3698 21.5025C10.1203 20.6547 9.37393 19.2414 9.37801 17.7313ZM18.4874 30.6363C16.6007 30.6363 15.0714 29.107 15.0714 27.2203C15.0714 25.3338 16.6007 23.8043 18.4874 23.8043C20.3741 23.8043 21.9034 25.3338 21.9034 27.2203C21.9014 29.106 20.3732 30.6343 18.4874 30.6363ZM23.4217 27.5999C23.1624 27.598 22.9035 27.5823 22.6459 27.5524C22.6546 27.4427 22.6626 27.3324 22.6626 27.2203C22.6626 25.8205 21.9607 24.5137 20.7939 23.7403C19.6268 22.9669 18.1499 22.83 16.8606 23.3754C16.6719 22.9658 16.5229 22.5392 16.4158 22.1013C16.2794 21.5404 16.2102 20.9654 16.2101 20.3882C16.2101 16.4055 19.4389 13.1766 23.4217 13.1766C27.4046 13.1766 30.6333 16.4055 30.6333 20.3882C30.6333 24.3712 27.4046 27.5999 23.4217 27.5999Z"
        fill="#FF7A96"
      />
      <path
        d="M12.0342 25.7018C12.0342 25.4922 11.8642 25.3223 11.6546 25.3223C11.445 25.3223 11.2751 25.4922 11.2751 25.7018C11.2751 26.3308 10.7652 26.8405 10.1364 26.8405C9.92678 26.8405 9.75684 27.0104 9.75684 27.2201C9.75684 27.4297 9.92678 27.5996 10.1364 27.5996C10.7652 27.5996 11.2751 28.1095 11.2751 28.7383C11.2751 28.9479 11.445 29.1179 11.6546 29.1179C11.8642 29.1179 12.0342 28.9479 12.0342 28.7383C12.0342 28.1095 12.544 27.5996 13.1729 27.5996C13.3825 27.5996 13.5524 27.4297 13.5524 27.2201C13.5524 27.0104 13.3825 26.8405 13.1729 26.8405C12.544 26.8405 12.0342 26.3308 12.0342 25.7018ZM11.6546 27.6115C11.5442 27.4625 11.4122 27.3307 11.2634 27.2201C11.4122 27.1096 11.5442 26.9776 11.6546 26.8288C11.7653 26.9776 11.897 27.1096 12.046 27.2201C11.897 27.3307 11.7653 27.4625 11.6546 27.6115Z"
        fill="#FF7A96"
      />
      <path
        d="M27.2165 12.0379C27.2165 12.2475 27.3864 12.4174 27.596 12.4174C27.8056 12.4174 27.9756 12.2475 27.9756 12.0379C27.9756 11.409 28.4854 10.8992 29.1143 10.8992C29.3239 10.8992 29.4938 10.7292 29.4938 10.5196C29.4938 10.31 29.3239 10.1401 29.1143 10.1401C28.4854 10.1401 27.9756 9.6304 27.9756 9.00138C27.9756 8.79177 27.8056 8.62183 27.596 8.62183C27.3864 8.62183 27.2165 8.79177 27.2165 9.00138C27.2165 9.6304 26.7066 10.1401 26.0778 10.1401C25.8682 10.1401 25.6982 10.31 25.6982 10.5196C25.6982 10.7292 25.8682 10.8992 26.0778 10.8992C26.7066 10.8992 27.2165 11.409 27.2165 12.0379ZM27.596 10.1284C27.7067 10.2772 27.8384 10.4092 27.9875 10.5196C27.8384 10.6303 27.7067 10.762 27.596 10.911C27.4856 10.762 27.3536 10.6303 27.2048 10.5196C27.3536 10.4092 27.4856 10.2772 27.596 10.1284Z"
        fill="#FF7A96"
      />
      <path
        d="M28.3555 20.3881C27.7267 20.3881 27.2168 19.8784 27.2168 19.2494C27.2168 19.0398 27.0469 18.8699 26.8372 18.8699C26.6276 18.8699 26.4577 19.0398 26.4577 19.2494C26.4577 19.8784 25.9478 20.3881 25.319 20.3881C25.1094 20.3881 24.9395 20.5581 24.9395 20.7677C24.9395 20.9773 25.1094 21.1472 25.319 21.1472C25.9478 21.1472 26.4577 21.6571 26.4577 22.2859C26.4577 22.4955 26.6276 22.6655 26.8372 22.6655C27.0469 22.6655 27.2168 22.4955 27.2168 22.2859C27.2168 21.6571 27.7267 21.1472 28.3555 21.1472C28.5651 21.1472 28.735 20.9773 28.735 20.7677C28.735 20.5581 28.5651 20.3881 28.3555 20.3881ZM26.8372 21.1591C26.7268 21.0101 26.5948 20.8783 26.446 20.7677C26.5948 20.6572 26.7268 20.5253 26.8372 20.3764C26.9479 20.5253 27.0797 20.6572 27.2287 20.7677C27.0797 20.8783 26.9479 21.0101 26.8372 21.1591Z"
        fill="#FF7A96"
      />
      <path
        d="M24.1804 17.3517C24.1804 17.142 24.0104 16.9721 23.8008 16.9721C23.172 16.9721 22.6621 16.4624 22.6621 15.8334C22.6621 15.6238 22.4922 15.4539 22.2826 15.4539C22.0729 15.4539 21.903 15.6238 21.903 15.8334C21.903 16.4624 21.3932 16.9721 20.7643 16.9721C20.5547 16.9721 20.3848 17.142 20.3848 17.3517C20.3848 17.5613 20.5547 17.7312 20.7643 17.7312C21.3932 17.7312 21.903 18.2411 21.903 18.8699C21.903 19.0795 22.0729 19.2494 22.2826 19.2494C22.4922 19.2494 22.6621 19.0795 22.6621 18.8699C22.6621 18.2411 23.172 17.7312 23.8008 17.7312C24.0104 17.7312 24.1804 17.5613 24.1804 17.3517ZM22.2826 17.7431C22.1721 17.5941 22.0401 17.4623 21.8913 17.3517C22.0401 17.2412 22.1721 17.1092 22.2826 16.9604C22.3932 17.1092 22.525 17.2412 22.674 17.3517C22.525 17.4623 22.3932 17.5941 22.2826 17.7431Z"
        fill="#FF7A96"
      />
      <path
        d="M13.1729 24.1836H13.932V24.9427H13.1729V24.1836Z"
        fill="#FF7A96"
      />
      <path
        d="M9.37695 29.4976H10.1361V30.2567H9.37695V29.4976Z"
        fill="#FF7A96"
      />
      <path
        d="M31.0127 28.3589C31.0127 27.7301 30.5029 27.2202 29.874 27.2202C29.2452 27.2202 28.7354 27.7301 28.7354 28.3589C28.7354 28.9879 29.2452 29.4976 29.874 29.4976C30.5029 29.4976 31.0127 28.9879 31.0127 28.3589ZM29.4945 28.3589C29.4945 28.1493 29.6644 27.9793 29.874 27.9793C30.0836 27.9793 30.2536 28.1493 30.2536 28.3589C30.2536 28.5685 30.0836 28.7384 29.874 28.7384C29.6644 28.7384 29.4945 28.5685 29.4945 28.3589Z"
        fill="#FF7A96"
      />
      <path
        d="M20.0052 11.2787C20.0052 10.4403 19.3254 9.7605 18.487 9.7605C17.6485 9.7605 16.9688 10.4403 16.9688 11.2787C16.9688 12.1174 17.6485 12.797 18.487 12.797C19.3254 12.797 20.0052 12.1174 20.0052 11.2787ZM17.7279 11.2787C17.7279 10.8595 18.0678 10.5196 18.487 10.5196C18.9062 10.5196 19.2461 10.8595 19.2461 11.2787C19.2461 11.6981 18.9062 12.0378 18.487 12.0378C18.0678 12.0378 17.7279 11.6981 17.7279 11.2787Z"
        fill="#FF7A96"
      />
      <path
        d="M22.2822 10.5195H23.0413V11.2786H22.2822V10.5195Z"
        fill="#FF7A96"
      />
      <path
        d="M23.8008 24.5632H24.5599V25.3223H23.8008V24.5632Z"
        fill="#FF7A96"
      />
      <path
        d="M26.0781 29.4976H26.8372V30.2567H26.0781V29.4976Z"
        fill="#FF7A96"
      />
      <path
        d="M13.1729 18.4902H13.932V19.2494H13.1729V18.4902Z"
        fill="#FF7A96"
      />
      <path
        d="M10.1369 18.5246L10.896 18.4562C10.8941 18.4381 10.7442 16.6316 11.7443 15.5335C12.318 14.9509 13.1161 14.6449 13.9325 14.6947V13.9356C12.9015 13.8811 11.8977 14.2779 11.1825 15.0228C9.95729 16.3649 10.1304 18.4373 10.1369 18.5246Z"
        fill="#FF7A96"
      />
      <path
        d="M23.8008 13.9355V14.6947C23.9906 14.6947 28.4144 14.7757 29.1189 20.0587L29.8711 19.9584C29.0766 14.0047 23.8536 13.9355 23.8008 13.9355Z"
        fill="#FF7A96"
      />
    </svg>
  );
};

export default CleaningSvg;
