import { types } from 'mobx-state-tree';

export const Location = types.model('Location').props({
  lat: types.number,
  lng: types.number,
});

export const LocationData = types.model('LocationData').props({
  location: types.maybeNull(Location),
  distance: types.maybeNull(types.number),
  distanceString: types.maybeNull(types.string),
});
