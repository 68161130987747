import { types, Instance, SnapshotOut } from 'mobx-state-tree';
import api from 'services/api/api';

export const RegistrationModel = types
  .model('Registration')
  .props({
    commerceName: types.maybe(types.string),
    phoneNumber: types.maybe(types.string),
    email: types.maybe(types.string),
    verificationCode: types.maybe(types.string),
    verificationId: types.maybe(types.string),
    registrationMethod: types.maybe(types.string),
    step: types.optional(types.string, 'RegisterOptions'),
  })
  .actions((self) => {
    function setPhone(phone: string) {
      self.phoneNumber = phone;
    }
    function setEmail(email: string) {
      self.email = email;
    }
    function setVerificationId(verificationId: string) {
      self.verificationId = verificationId;
    }
    function setCommerceName(commerceName: string) {
      self.commerceName = commerceName;
    }
    function setStep(step: string) {
      self.step = step;
    }
    function setMethod(method: string) {
      self.registrationMethod = method;
    }
    return {
      setPhone,
      setEmail,
      setVerificationId,
      setCommerceName,
      setStep,
      setMethod,
    };
  })
  .actions(() => ({
    putBusiness: async (body: any) => {
      await api.business.putBusinessProfile(body);
    },
  }));

type RegistrationModelType = Instance<typeof RegistrationModel>;
export type Registration = RegistrationModelType;
type RegistrationSnapshotType = SnapshotOut<typeof RegistrationModel>;
export type RegistrationSnapshot = RegistrationSnapshotType;
export const createRegistrationDefaultModel = () =>
  types.optional(RegistrationModel, {});
