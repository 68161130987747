import moment from 'moment';

export const sinceCreated = (modifiedAt: string) => {
    const today = moment(new Date());
    const postDate = moment(modifiedAt);
    const duration = moment.duration(today.diff(postDate));
    const since = Math.round(duration.asDays());

    const days = since === 1 ? 'día' : 'días';

    return `Hace ${since} ${days}`;
  };