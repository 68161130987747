import React, { useEffect, useState } from 'react';
import Stepper from 'components/common/Stepper';
import CommerceEmployees from 'components/common/StepsCommerce/employees';
import CommerceLocation from 'components/common/StepsCommerce/location';
import RegisterCommerce from 'components/common/StepsCommerce/registerName';
import CommerceDescription from 'components/common/StepsCommerce/description';
import CommercePhotos from 'components/common/StepsCommerce/photos';
import { StyledRightPanel } from 'theme/rigthPanel.styled';
import { IconBtn } from 'components/common/Button';
import { useStores } from 'models/root-store/root-store-context';
import { observer } from 'mobx-react-lite';

const CommerceData: React.FC = observer(() => {
  const { renderStore, dataCommerceStore, sessionStore, mastersStore } =
    useStores();
  const [isLoad, setIsLoad] = useState(false);

  const handleLeft = () => {
    if (renderStore.stepCommerceData > 1) {
      renderStore.setStepCommcerData(renderStore.stepCommerceData - 1);
    } else {
      renderStore.setRenderCommerceData(false);
    }
  };

  useEffect(() => {
    if (renderStore.stepCommerceData === 1 && sessionStore.currentUser?.business?.id && !isLoad) {
      dataCommerceStore.getDataCommerce(
        sessionStore.currentUser!.business!.id!,
      );
      setIsLoad(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <StyledRightPanel>
      <div className="header-panel">
        <IconBtn icon="arrowLeft" btnClick={handleLeft} />
        <Stepper step={renderStore.stepCommerceData} total={5} />
        <IconBtn
          icon="close"
          btnClick={() => renderStore.setRenderCommerceData(false)}
        />
      </div>
      {dataCommerceStore.name === null && <RegisterCommerce />}
      {dataCommerceStore.name && renderStore.stepCommerceData === 1 && (
        <RegisterCommerce />
      )}
      {renderStore.stepCommerceData === 2 && <CommerceLocation />}
      {renderStore.stepCommerceData === 3 && (
        <CommerceEmployees employeesCounts={mastersStore.employeesCount} />
      )}
      {renderStore.stepCommerceData === 4 && <CommerceDescription />}
      {renderStore.stepCommerceData === 5 && <CommercePhotos />}
    </StyledRightPanel>
  );
});

export default CommerceData;
