import React from 'react';
import { SvgProps } from './icon.props';

const PauseSvg: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5.02441"
        y="4.02557"
        width="5.00919"
        height="15.9744"
        rx="1"
        fill={color || '#0D0D0D'}
      />
      <rect
        x="13.1719"
        y="4.02557"
        width="4.78854"
        height="15.9744"
        rx="1"
        fill={color || '#0D0D0D'}
      />
    </svg>
  );
};

export default PauseSvg;
