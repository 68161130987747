import styled from 'styled-components';
import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';

export const StyledPublishedOffers = styled.div`
  display: flex;
  flex-direction: column;
  width: ${px2rem(363)};
  height: 100%;
  background-color: ${color.black};
  padding-left: ${px2rem(24)};
  padding-right: ${px2rem(24)};
  padding-top: ${px2rem(18)};
  -ms-overflow-style: none;

  .text {
    color: ${color.palette.shades.mediumGray};
    font-family: 'Lexend';
    margin-bottom: 1em;
  }

  .textBottom {
    color: ${color.palette.shades.mediumGray};
    font-family: 'Lexend';
    margin: ${px2rem(10)} 0;
  }

  .scroll {
    display: flex;
    flex-direction: column;
    overflow-y: 'scroll';
    height: 95vh;
    width: ${px2rem(345)};
    padding-right: 3em;
    align-self: center;
    justify-self: center;
    overflow-x: hidden;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
