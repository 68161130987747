import React from 'react';
import { SvgProps } from './icon.props';

const ManagementSvg: React.FC<SvgProps> = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.2" cx="20" cy="20" r="20" fill="#28A555" />
      <path
        d="M29.3438 17.125C29.3438 17.0297 29.3059 16.9383 29.2385 16.8709C29.1711 16.8035 29.0797 16.7656 28.9844 16.7656H22.875V11.0156C22.875 10.9203 22.8371 10.8289 22.7697 10.7615C22.7023 10.6941 22.6109 10.6562 22.5156 10.6562C22.1543 10.6582 21.7938 10.6894 21.4375 10.7497V9.57812C21.4375 9.48281 21.3996 9.3914 21.3322 9.32401C21.2648 9.25661 21.1734 9.21875 21.0781 9.21875H12.4531C12.3579 9.21946 12.2665 9.25677 12.198 9.32297L9.32297 12.198C9.25677 12.2665 9.21946 12.3579 9.21875 12.4531V30.4219C9.21875 30.5172 9.25661 30.6086 9.32401 30.676C9.3914 30.7434 9.48281 30.7812 9.57812 30.7812H21.0781C21.1734 30.7812 21.2648 30.7434 21.3322 30.676C21.3996 30.6086 21.4375 30.5172 21.4375 30.4219V24.2191C21.7938 24.2793 22.1543 24.3106 22.5156 24.3125C24.3261 24.3109 26.0619 23.591 27.3421 22.3108C28.6222 21.0306 29.3421 19.2948 29.3438 17.4844C29.3438 17.3873 29.3402 17.2903 29.3366 17.1933C29.3409 17.1708 29.3433 17.1479 29.3438 17.125ZM22.1562 11.3858V16.9777L17.7863 21.3477C17.0736 20.4765 16.6156 19.4252 16.4629 18.3101C16.3102 17.195 16.4686 16.0593 16.9208 15.0286C17.3729 13.9978 18.1012 13.1121 19.0251 12.4693C19.949 11.8264 21.0326 11.4515 22.1562 11.3858ZM12.0938 10.4442V12.0938H10.4442L12.0938 10.4442ZM20.7188 30.0625H9.9375V12.8125H12.4531C12.5484 12.8125 12.6398 12.7746 12.7072 12.7072C12.7746 12.6398 12.8125 12.5484 12.8125 12.4531V9.9375H20.7188V10.9042C19.274 11.2965 17.9985 12.1534 17.0891 13.3426C16.1798 14.5319 15.6871 15.9873 15.6871 17.4844C15.6871 18.9814 16.1798 20.4369 17.0891 21.6261C17.9985 22.8153 19.274 23.6722 20.7188 24.0645V30.0625ZM22.5156 23.5938C20.9319 23.5938 19.4106 22.9763 18.275 21.8723L22.663 17.4844H28.625C28.6227 19.104 27.9783 20.6566 26.8331 21.8018C25.6878 22.9471 24.1352 23.5915 22.5156 23.5938Z"
        fill="#28A555"
      />
      <path
        d="M23.9531 16.0469H30.4219V16.0447C30.4697 16.0436 30.5169 16.0329 30.5605 16.0133C30.6041 15.9936 30.6433 15.9654 30.6758 15.9302C30.7083 15.8951 30.7334 15.8538 30.7496 15.8087C30.7658 15.7637 30.7728 15.7159 30.7701 15.6681C30.6747 13.925 29.9148 12.2848 28.6467 11.0851C27.3786 9.88537 25.6988 9.21743 23.9531 9.21875C23.8578 9.21875 23.7664 9.25661 23.699 9.32401C23.6316 9.39141 23.5938 9.48281 23.5938 9.57813V15.6875C23.5938 15.7828 23.6316 15.8742 23.699 15.9416C23.7664 16.009 23.8578 16.0469 23.9531 16.0469ZM30.0201 15.3281H24.3125V9.94792C25.7444 10.0312 27.1012 10.616 28.1449 11.5998C29.1886 12.5837 29.8525 13.9036 30.0201 15.3281Z"
        fill="#28A555"
      />
      <path
        d="M15.3281 22.875C14.6884 22.875 14.0631 23.0647 13.5312 23.4201C12.9993 23.7755 12.5848 24.2806 12.34 24.8716C12.0952 25.4626 12.0311 26.113 12.1559 26.7404C12.2807 27.3678 12.5887 27.9441 13.0411 28.3964C13.4934 28.8488 14.0697 29.1568 14.6971 29.2816C15.3245 29.4064 15.9749 29.3423 16.5659 29.0975C17.1569 28.8527 17.662 28.4382 18.0174 27.9063C18.3728 27.3744 18.5625 26.7491 18.5625 26.1094C18.5612 25.252 18.22 24.4301 17.6137 23.8238C17.0074 23.2175 16.1855 22.8763 15.3281 22.875ZM15.3281 28.625C14.8306 28.625 14.3442 28.4775 13.9305 28.201C13.5168 27.9246 13.1944 27.5317 13.004 27.0721C12.8136 26.6124 12.7638 26.1066 12.8608 25.6186C12.9579 25.1306 13.1975 24.6824 13.5493 24.3306C13.9011 23.9787 14.3494 23.7392 14.8374 23.6421C15.3253 23.545 15.8311 23.5948 16.2908 23.7852C16.7505 23.9756 17.1434 24.2981 17.4198 24.7118C17.6962 25.1255 17.8438 25.6118 17.8438 26.1094C17.843 26.7763 17.5777 27.4157 17.1061 27.8874C16.6345 28.359 15.9951 28.6242 15.3281 28.625Z"
        fill="#28A555"
      />
      <path
        d="M16.4062 26.8281C16.4051 27.0504 16.3355 27.267 16.2069 27.4483C16.0783 27.6297 15.8969 27.767 15.6875 27.8416V28.2656H14.9688V27.8416C14.7593 27.767 14.578 27.6297 14.4494 27.4483C14.3208 27.267 14.2511 27.0504 14.25 26.8281H14.9688C14.9688 26.8992 14.9898 26.9687 15.0293 27.0278C15.0688 27.0869 15.1249 27.1329 15.1906 27.1601C15.2563 27.1873 15.3285 27.1945 15.3982 27.1806C15.4679 27.1667 15.532 27.1325 15.5822 27.0822C15.6325 27.032 15.6667 26.9679 15.6806 26.8982C15.6945 26.8285 15.6873 26.7563 15.6601 26.6906C15.6329 26.6249 15.5869 26.5688 15.5278 26.5293C15.4687 26.4898 15.3992 26.4688 15.3281 26.4688C15.0753 26.4668 14.8312 26.376 14.6385 26.2123C14.4458 26.0486 14.3168 25.8224 14.2739 25.5732C14.2311 25.324 14.2772 25.0677 14.4043 24.8491C14.5313 24.6305 14.7311 24.4634 14.9688 24.3772V23.9531H15.6875V24.3772C15.8969 24.4518 16.0783 24.5891 16.2069 24.7704C16.3355 24.9518 16.4051 25.1683 16.4062 25.3906H15.6875C15.6875 25.3195 15.6664 25.2501 15.6269 25.191C15.5874 25.1319 15.5313 25.0858 15.4657 25.0586C15.4 25.0314 15.3277 25.0243 15.258 25.0382C15.1883 25.052 15.1243 25.0862 15.074 25.1365C15.0237 25.1868 14.9895 25.2508 14.9757 25.3205C14.9618 25.3902 14.9689 25.4625 14.9961 25.5282C15.0233 25.5938 15.0694 25.6499 15.1285 25.6894C15.1876 25.7289 15.257 25.75 15.3281 25.75C15.6138 25.7509 15.8875 25.8647 16.0895 26.0667C16.2915 26.2687 16.4054 26.5425 16.4062 26.8281Z"
        fill="#28A555"
      />
      <path d="M11.375 14.25H14.9688V14.9688H11.375V14.25Z" fill="#28A555" />
      <path d="M11.375 16.4062H14.9688V17.125H11.375V16.4062Z" fill="#28A555" />
      <path
        d="M11.375 18.5625H14.9688V19.2812H11.375V18.5625Z"
        fill="#28A555"
      />
      <path
        d="M11.375 20.7188H14.9688V21.4375H11.375V20.7188Z"
        fill="#28A555"
      />
      <path d="M22.875 25.75H24.3125V26.4688H22.875V25.75Z" fill="#28A555" />
      <path d="M25.0312 25.75H30.7812V26.4688H25.0312V25.75Z" fill="#28A555" />
      <path d="M22.875 27.9062H24.3125V28.625H22.875V27.9062Z" fill="#28A555" />
      <path
        d="M25.0312 27.9062H30.7812V28.625H25.0312V27.9062Z"
        fill="#28A555"
      />
      <path
        d="M22.875 30.0625H24.3125V30.7812H22.875V30.0625Z"
        fill="#28A555"
      />
      <path
        d="M25.0312 30.0625H30.7812V30.7812H25.0312V30.0625Z"
        fill="#28A555"
      />
    </svg>
  );
};

export default ManagementSvg;
