import { types, Instance, SnapshotOut } from 'mobx-state-tree';
import api from 'services/api/api';

export const MasterType = types.model('MasterType').props({
  id: types.number,
  description: types.string,
});

export const MastersModel = types
  .model('Masters')
  .props({
    businessSegment: types.array(MasterType),
    documentType: types.array(MasterType),
    educationStatus: types.array(MasterType),
    employeesCount: types.array(MasterType),
    employmentRelationship: types.array(MasterType),
    gender: types.array(MasterType),
    maritalStatus: types.array(MasterType),
    modality: types.array(MasterType),
    remuneration: types.array(MasterType),
    terminationReason: types.array(MasterType),
    userType: types.array(MasterType),
  })
  .actions((self) => {
    function setMasters(masters: any) {
      self.businessSegment = masters.businessSegment;
      self.documentType = masters.documentType;
      self.educationStatus = masters.educationStatus;
      self.employeesCount = masters.employeesCount;
      self.employmentRelationship = masters.employmentRelationship;
      self.gender = masters.gender;
      self.maritalStatus = masters.maritalStatus;
      self.modality = masters.modality;
      self.remuneration = masters.remuneration;
      self.terminationReason = masters.terminationReason;
      self.userType = masters.userType;
    }

    return {
      setMasters,
    };
  })
  .actions((self) => ({
    getMasters: async () => {
      const response: { kind: string; mastersData: Masters } =
        await api.masters.getMasters();
      if (response.kind === 'ok') {
        const { mastersData } = response;
        self.setMasters(mastersData);
      }
    },
  }));

type MastersModelType = Instance<typeof MastersModel>;
export type Masters = MastersModelType;
type MastersSnapshotType = SnapshotOut<typeof MastersModel>;
export type MastersSnapshot = MastersSnapshotType;
export const createMastersDefaultModel = () => types.optional(MastersModel, {});
