import React from 'react';
import { SvgProps } from './icon.props';

const StarSvg: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || 16}
      height={height || 15}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99986 0.666664C8.2536 0.666664 8.48535 0.810712 8.59766 1.03825L10.5027 4.89764L14.7629 5.52034C15.014 5.55703 15.2224 5.73304 15.3007 5.97438C15.3789 6.21571 15.3134 6.48055 15.1317 6.65757L12.0495 9.65957L12.7769 13.9006C12.8198 14.1508 12.717 14.4035 12.5117 14.5527C12.3064 14.7019 12.0342 14.7215 11.8096 14.6034L7.99986 12.5999L4.19016 14.6034C3.96555 14.7215 3.69335 14.7019 3.48803 14.5527C3.28272 14.4035 3.17989 14.1508 3.22278 13.9006L3.95018 9.65958L0.868035 6.65757C0.686289 6.48055 0.620774 6.21571 0.699026 5.97438C0.777278 5.73304 0.985731 5.55703 1.23677 5.52034L5.49702 4.89764L7.40205 1.03825C7.51437 0.810712 7.74611 0.666664 7.99986 0.666664ZM7.99986 2.8395L6.53766 5.80175C6.44064 5.99831 6.25318 6.13462 6.03628 6.16632L2.76546 6.6444L5.13168 8.94909C5.28893 9.10225 5.3607 9.32301 5.3236 9.53936L4.76531 12.7944L7.68956 11.2566C7.88381 11.1545 8.1159 11.1545 8.31016 11.2566L11.2344 12.7944L10.6761 9.53936C10.639 9.32301 10.7108 9.10225 10.868 8.94909L13.2342 6.6444L9.96344 6.16632C9.74654 6.13462 9.55908 5.99831 9.46205 5.80175L7.99986 2.8395Z"
        fill={color || '#FBBA00'}
      />
    </svg>
  );
};

export default StarSvg;
