import { types, Instance, SnapshotOut } from 'mobx-state-tree';
import api from 'services/api/api';

export const MeJobModel = types.model('MeJob').props({
  businessId: types.string,
  businessSegmentId: types.number,
  description: types.string,
  genderId: types.maybeNull(types.number),
  id: types.string,
  modalityId: types.number,
  maximumAge: types.number,
  minimumAge: types.number,
  modifiedAt: types.string,
  name: types.string,
  skills: types.maybeNull(types.string),
  state: types.string,
});

export const MeJobsModel = types
  .model('MeJobs')
  .props({
    jobs: types.optional(types.array(MeJobModel), []),
    index: types.number,
  })
  .actions((self) => {
    function getJobs(jobs: any) {
      self.jobs = jobs;
    }

    function setIndex(index: number) {
      self.index = index;
    }

    return {
      getJobs,
      setIndex,
    };
  })
  .actions((self) => ({
    getMeJobs: async () => {
      const response = await api.me.getMeJobs();
      self.getJobs(response.jobs);
    },
  }));

type MeJobsModelType = Instance<typeof MeJobsModel>;
export type MeJobs = MeJobsModelType;
type MeJobsSnapshotType = SnapshotOut<typeof MeJobsModel>;
export type MeJobsSnapshot = MeJobsSnapshotType;

export const MeJobsModelDefault = () =>
  types.optional(MeJobsModel, { jobs: [], index: 0 });
