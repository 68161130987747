import { ApisauceInstance, create } from 'apisauce';
import { rootStore } from 'models/root-store/root-store';
import { ApiConfig, DEFAULT_API_CONFIG } from './api-config';
import { BusinessApi } from './business';
import { ContactApi } from './contact';
import { EmployeeApi } from './employee';
import { EmployeesApi } from './employees';
import { JobApi } from './job';
import { MastersApi } from './masters';
import { MeUserApi } from './me';
import { UserApi } from './user';

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce!: ApisauceInstance;

  /**
   * Configurable options.
   */
  config: ApiConfig;

  token: string | null;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
    this.token = null;
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    // construct the apisauce instance
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: 'application/json',
      },
    });
  }

  removeToken() {
    this.token = null;
    this.apisauce.setHeaders({
      Accept: 'application/json',
    });
    this.apisauce.deleteHeader('Authorization');
  }

  setToken(token: string) {
    this.token = token;
    this.apisauce.setHeaders({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    });
  }
}

const baseApi = new Api();
baseApi.setup();
const api = {
  api: baseApi,
  business: new BusinessApi(baseApi),
  contact: new ContactApi(baseApi),
  employee: new EmployeeApi(baseApi),
  employees: new EmployeesApi(baseApi),
  job: new JobApi(baseApi),
  masters: new MastersApi(baseApi),
  me: new MeUserApi(baseApi),
  user: new UserApi(baseApi),
};

const responseMonitor = (response: any) => {
  if (response.status === 401) {
    rootStore.resetStore();
    api.api.removeToken();
    // TODO: Mostrar mensaje de sesión expirada
  }
};

api.api.apisauce.addMonitor(responseMonitor);

export default api;
