import styled from 'styled-components';
import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';

export const StyledCardsListButton = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  width: ${px2rem(110)};
  height: ${px2rem(32)};
  background-color: ${color.palette.shades.darkGrey};
  border-radius: 0px ${px2rem(8)} ${px2rem(8)} 0px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: ${px2rem(101)};
  z-index: 90000;

  .buttonText {
    color: ${color.palette.shades.lightGray};
    font-family: 'Lexend';
    text-align: center;
    margin-left: 0.5em;
    font-size: ${px2rem(14)};
  }
`;
