import styled from 'styled-components';
import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';
import { FlapProps } from 'lib/types';

export const StyledFlap = styled.div<FlapProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${px2rem(360)};
  cursor: pointer;
  flex: 1;
  ${(props) => (props.open ? '' : `height: ${px2rem(60)}`)};
  background-color: ${color.darkGrey};
  border-radius: ${px2rem(12)} ${px2rem(12)} 0 0;
  box-shadow: 0px -6px 16px rgba(0, 0, 0, 0.4);
  padding-top: ${px2rem(18)};
  margin-top: -0.5em;
  

  .flap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 ${px2rem(30)} 0 ${px2rem(24)};
    cursor: pointer;

    .flap-title {
      display: flex;
      align-items: center;

      .flap-title-text {
        color: ${(props) => props.color};
        font-family: 'Lexend Mega';
      }

      .flap-circle-count {
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${px2rem(18)};
        height: ${px2rem(18)};
        border-radius: ${px2rem(20)};
        background-color: ${(props) => props.color};
        margin-left: ${px2rem(8)};
        font-family: 'Lexend Mega';

        .circle-count-number {
          color: ${color.black};
          font-size: ${px2rem(12)};
          align-self: center;
        }
      }
    }
  }

  .scroll-cards {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 98%;
    height: calc(80vh - 190px);
    margin-top: 1.5em;
    align-self: center;
    justify-self: center;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }

    .card-wrapper {
      margin: 0.3em 0;
    }
  }
`;
