import React from 'react';
import { NotificationProps } from 'lib/types';
import { useStores } from 'models/root-store/root-store-context';
import { observer } from 'mobx-react-lite';
import { StyledNotification } from './index.styled';

const Notification: React.FC<NotificationProps> = observer(
  function Notification({ message, time = 10000, render }) {
    const { renderStore } = useStores();

    const killNotification = () => {
      renderStore.setRenderNotification(false);
      renderStore.setNotification('', 8500, '', '');
    };

    const handleClick = () => {
      if (render.type === 'postOffer') {
        renderStore.setRenderPublishedOffers(true);
      }
      killNotification();
    };

    setTimeout(() => {
      killNotification();
    }, time);

    return (
      <StyledNotification>
        <span className="text-notification">{message}</span>
        {render.btnText && (
          <div
            className="btn-notification"
            role="button"
            tabIndex={0}
            onClick={handleClick}
            onKeyPress={handleClick}
          >
            {render.btnText}
          </div>
        )}
      </StyledNotification>
    );
  },
);

export default Notification;
