import React from 'react';
import { IconBtn } from 'components/common/Button';
import PositionCategory from 'components/common/StepsJob/category';
import PositionRequirement from 'components/common/StepsJob/requirement';
import PositionSkills from 'components/common/StepsJob/skills';
import PositionDescription from 'components/common/StepsJob/description';
import Stepper from 'components/common/Stepper';
import { StyledRightPanel } from 'theme/rigthPanel.styled';
import { useStores } from 'models/root-store/root-store-context';
import { observer } from 'mobx-react-lite';

const PostOffer: React.FC = observer(() => {
  const { renderStore, JobPositionStore, skillsModel, mastersStore } =
    useStores();
  const totalStep = 4;
  /**
   * PositionCategory
   * PositionRequirement
   * PositionDescription
   */

  const handleStepSub = () => {
    if (renderStore.stepPostOffer > 1) {
      renderStore.setStepPostOffer(renderStore.stepPostOffer - 1);
    } else {
      renderStore.setRenderPostOffer(false);
      JobPositionStore.resetValues();
    }
  };

  const handleClose = () => {
    renderStore.setRenderPostOffer(false);
    JobPositionStore.resetValues();
    skillsModel.removeSkills();
  };

  return (
    <StyledRightPanel>
      <>
        <div className="header-panel">
          <IconBtn icon="arrowLeft" btnClick={handleStepSub} />
          <Stepper step={renderStore.stepPostOffer} total={totalStep} />
          <IconBtn icon="close" btnClick={handleClose} />
        </div>
        {renderStore.stepPostOffer === 1 && (
          <PositionCategory
            modalities={mastersStore.modality}
            segments={mastersStore.businessSegment}
          />
        )}
        {renderStore.stepPostOffer === 2 && (
          <PositionRequirement genders={mastersStore.gender} />
        )}
        {renderStore.stepPostOffer === 3 && <PositionSkills />}
        {renderStore.stepPostOffer === 4 && <PositionDescription />}
      </>
    </StyledRightPanel>
  );
});

export default PostOffer;
