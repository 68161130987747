import React from 'react';
import { SvgProps } from './icon.props';

const EditSvg: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25278 2.58579C1.62785 2.21071 2.13656 2 2.66699 2H7.33366C7.70185 2 8.00033 2.29848 8.00033 2.66667C8.00033 3.03486 7.70185 3.33333 7.33366 3.33333H2.66699C2.49018 3.33333 2.32061 3.40357 2.19559 3.5286C2.07056 3.65362 2.00033 3.82319 2.00033 4V13.3333C2.00033 13.5101 2.07056 13.6797 2.19559 13.8047C2.32061 13.9298 2.49018 14 2.66699 14H12.0003C12.1771 14 12.3467 13.9298 12.4717 13.8047C12.5968 13.6797 12.667 13.5101 12.667 13.3333V8.66667C12.667 8.29848 12.9655 8 13.3337 8C13.7018 8 14.0003 8.29848 14.0003 8.66667V13.3333C14.0003 13.8638 13.7896 14.3725 13.4145 14.7475C13.0395 15.1226 12.5308 15.3333 12.0003 15.3333H2.66699C2.13656 15.3333 1.62785 15.1226 1.25278 14.7475C0.877706 14.3725 0.666992 13.8638 0.666992 13.3333V4C0.666992 3.46957 0.877706 2.96086 1.25278 2.58579Z"
        fill={color || 'white'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3337 1.91927C13.1354 1.91927 12.9453 1.99803 12.8051 2.13822L6.60232 8.34098L6.24992 9.75057L7.65951 9.39818L13.8623 3.19541C14.0025 3.05522 14.0812 2.86508 14.0812 2.66682C14.0812 2.46856 14.0025 2.27841 13.8623 2.13822C13.7221 1.99803 13.5319 1.91927 13.3337 1.91927ZM11.8623 1.19541C12.2525 0.805172 12.7818 0.585938 13.3337 0.585938C13.8856 0.585938 14.4148 0.805172 14.8051 1.19541C15.1953 1.58565 15.4146 2.11493 15.4146 2.66682C15.4146 3.2187 15.1953 3.74798 14.8051 4.13822L8.47174 10.4716C8.3863 10.557 8.27925 10.6176 8.16203 10.6469L5.49536 11.3136C5.26818 11.3704 5.02786 11.3038 4.86227 11.1382C4.69668 10.9726 4.63012 10.7323 4.68691 10.5051L5.35358 7.83846C5.38288 7.72124 5.4435 7.61419 5.52894 7.52875L11.8623 1.19541Z"
        fill={color || 'white'}
      />
    </svg>
  );
};

export default EditSvg;
