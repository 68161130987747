import React from 'react';
import { useFormikContext } from 'formik';
import { SelectProps } from 'lib/types';
import { StyledSelect } from './index.styled';

const AddFormikFieldProps = (name: string) => {
  const formikContext = useFormikContext();
  if (!formikContext) return {};
  const { errors, values, handleChange } = formikContext;
  // @ts-ignore
  const error = errors[name];
  // @ts-ignore
  const value = values[name];

  return { error, value, handleChange: handleChange(name) };
};

const Select: React.FC<SelectProps> = ({
  name,
  id,
  selected,
  options,
  width,
}) => {
  const { handleChange } = AddFormikFieldProps(name);

  return (
    <StyledSelect
      name={name}
      id={id}
      onChange={handleChange}
      defaultValue={selected}
      wSelect={width}
    >
      {options &&
        options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
    </StyledSelect>
  );
};

export const CustomSelect: React.FC<SelectProps> = ({
  name,
  id,
  selected,
  options,
  width,
  onChange,
  value,
}) => {
  return (
    <StyledSelect
      name={name}
      id={id}
      onChange={onChange}
      value={value}
      defaultValue={selected}
      wSelect={width}
    >
      {options &&
        options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
    </StyledSelect>
  );
};

export default Select;
