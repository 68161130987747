import React from 'react';
import moment from 'moment';
import Icon from 'components/icons/icon';
import { Experience } from 'models/employees/experience/experienceModel';
import { color } from 'theme/colors';
import CircleWicon from './circleWicon';
import { StyledCard } from './styles.dislpayComponents';

const ExperienceCard: React.FC<Experience> = ({ experience }) => {
  const startYear = moment(experience.start).format('YYYY');
  const startMonth = moment(experience.start).format('MM');
  const endMonth = moment(experience.end).format('MM');
  const endYear = moment(experience.end).format('YYYY');
  const months = [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sept',
    'Oct',
    'Nov',
    'Dic',
  ];
  const formatMonth = (month: any) => {
    if (month[0] === 0) {
      month = month[1];
    }
    return months[month - 1];
  };

  const from = `${formatMonth(startMonth)} ${startYear} `;
  const to = experience.end ? `${formatMonth(endMonth)}   ${endYear}` : '';
  return (
    <StyledCard>
      <div className="upperBox">
        <div className="iconBox">
          <CircleWicon
            color="rgb(203, 136, 255,0.3)"
            height={60}
            width={60}
            icon="shop"
            iconColor={color.lightPurple}
          />
        </div>

        <div className="data-container">
          <span className="cardTitle">{experience.company || ''} </span>

          <div className="data-wrapper">
            <div className="data-item">
              <span className="icon-wrapper">
                <Icon icon="briefCase" color={color.palette.shades.lightGray} />
              </span>
              <span className="data-text">{experience.job || ''} </span>
            </div>

            <div className="data-item">
              <span className="icon-wrapper">
                <Icon icon="calendar" color={color.palette.shades.lightGray} />
              </span>
              <span className="data-text">
                {from || ''} - {to || ''}
              </span>
            </div>

            <div className="data-item">
              <span className="icon-wrapper">
                <Icon icon="clock" color={color.palette.shades.lightGray} />
              </span>
              <span className="data-text"> Full time</span>
            </div>
          </div>
        </div>
      </div>

      {experience?.references?.length && (
        <>
          <div className="blackLine" />

          <div className="hasRef">
            <Icon icon="star" />
            <span className="text" style={{ color: '#FAF5E6' }}>
              Tiene referencias
            </span>
          </div>
        </>
      )}
    </StyledCard>
  );
};

export default ExperienceCard;
