import React, {Dispatch, SetStateAction} from 'react';
import { useStores } from 'models/root-store/root-store-context';
import Button, { IconBtn } from 'components/common/Button';
import { color } from 'theme/colors';
import { PasswordSchema } from 'utils/schema-validations';
import { Formik } from 'formik';
import { CustomPasswordInput } from 'components/common/Input';

interface IProps {
    changePassword: string;
    setChangePassword: Dispatch<SetStateAction<string>>;
  }

const ConfirmChange = ({changePassword, setChangePassword}: IProps) => {
    const initialValues = {
        password: '',
      };
  return (
    <>
      <>
        <div className="header-panel">
          <IconBtn icon="arrowLeft" btnClick={() => {
            setChangePassword('')
          }} />
          <h2 className="title-rightpanel">Modificar contraseña</h2>
        </div>
        <p className="footer-content-rightpanel">
          Para confirmar el cambio de contraseña es necesario validar tu
          contraseña actual
        </p>
      </>
        <br />
      <>
      <Formik
          initialValues={initialValues}
          onSubmit={(values: any) => {
            console.log(values)
        }}
          validationSchema={PasswordSchema}
          validateOnBlur={false}
          validateOnChange
        >
          {({ handleSubmit, values, isValid }) => (
            <div>
              <p className="subsection-subtittle-rightpanel">
                Contraseña actual
              </p>
              <div className="field-wrapper">
                <CustomPasswordInput name="password" autoComplete='new-password'/>
              </div>
             
              <div className="field-wrapper">
                <Button
                  text="Confirmar cambio"
                  color={color.red}
                  hBtn={48}
                  wBtn={328}
                  btnClick={() => (isValid ? handleSubmit() : null)}
                  disabled={!isValid}
                />
              </div>
            </div>
          )}
        </Formik>
      </>
    </>
  );
};
export default ConfirmChange;
