import React from 'react';
import { SvgProps } from './icon.props';

const PhoneSvg: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || 12}
      height={height || 12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.82 8.1735L9.12669 7.98017C8.72003 7.9335 8.32003 8.0735 8.03336 8.36017L6.80669 9.58684C4.92003 8.62684 3.37336 7.08684 2.41336 5.1935L3.64669 3.96017C3.93336 3.6735 4.07336 3.2735 4.02669 2.86684L3.83336 1.18684C3.75336 0.513503 3.18669 0.00683594 2.50669 0.00683594H1.35336C0.600027 0.00683594 -0.0266396 0.633503 0.0200271 1.38684C0.37336 7.08017 4.92669 11.6268 10.6134 11.9802C11.3667 12.0268 11.9934 11.4002 11.9934 10.6468V9.4935C12 8.82017 11.4934 8.2535 10.82 8.1735Z"
        fill={color || 'white'}
      />
    </svg>
  );
};

export default PhoneSvg;
