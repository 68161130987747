import styled from 'styled-components';
import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';

export const StyledCheckbox = styled.input`
  -webkit-appearance: none;
  width: ${px2rem(18)};
  min-width: ${px2rem(18)};
  height: ${px2rem(18)};
  min-height: ${px2rem(18)};
  padding: 0;
  margin: 0 ${px2rem(11)} 0 0;
  border: 1px solid ${color.palette.shades.lightGray};
  border-radius: ${px2rem(2)};
  background-color: ${color.black};
  color: ${color.palette.shades.lightGray};
  font-family: 'Lexend';
  font-size: ${px2rem(16)};
  appearance: none;
  cursor: pointer;
  display: grid;
  place-content: center;

  &::before {
    content: '✔';
    color: ${color.white};
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    font-size: 13px;
  }

  &:checked::before {
    transform: scale(1);
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-right: ${px2rem(20)};
`;
