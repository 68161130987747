import styled from 'styled-components';
import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';

export const StyledRightPanel = styled.div`
  background-color: ${color.black};
  max-width: ${px2rem(364)};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${px2rem(16)} ${px2rem(19)};

  .header-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: ${px2rem(10)} 0 ${px2rem(33)} 0;
  }

  .title-rightpanel,
  .section-rightpanel {
    color: ${color.white};
    font-family: 'Lexend';
    font-size: ${px2rem(16)};
    width: 100%;
    text-align: center;
  }

  .title-rightpanel {
    font-family: 'Lexend Mega';
    font-size: ${px2rem(20)};
    letter-spacing: -0.12em;
    margin: 0 ${px2rem(10)} ${px2rem(25)} ${px2rem(10)};
  }

  /* .section-rightpanel {
    margin-bottom: ${px2rem(24)};
  } */

  .paragraph-rightpanel {
    font-family: 'Lexend';
    color: ${color.white};
    font-size: ${px2rem(14)};
    margin-top: ${px2rem(8)};
    margin-bottom: ${px2rem(35)};
    text-align: left;
  }

  .fields-flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .field-wrapper {
    position: relative;
    width: min-content;
    margin-bottom: ${px2rem(11.3)};
    margin-top: ${px2rem(6)};
  }

  .icon-input-right {
    position: absolute;
    margin-bottom: ${px2rem(11.3)};
    margin-top: ${px2rem(11.3)};
    right: 10px;
    bottom: 0px;
  }

  .have-account {
    color: ${color.white};
    font-family: 'Lexend';
    font-size: ${px2rem(14)};
    margin-top: ${px2rem(31)};
  }

  .version {
    position: absolute;
    bottom: ${px2rem(14)};
  }

  .wrapper-texts {
    display: flex;
    flex-direction: column;
    margin: ${px2rem(15)} ${px2rem(10)};

    span {
      font-family: 'Lexend';
      color: ${color.white};
      font-size: ${px2rem(14)};
      text-align: center;
    }
  }

  .btn-panel {
    position: absolute;
    bottom: 25px;
  }

  .field-segment {
    position: relative;
    display: flex;
    align-items: center;
    width: ${px2rem(328)};
    height: ${px2rem(48)};
    border: 1px solid black;
    border-radius: ${px2rem(12)};
    background-color: ${color.darkGrey};
  }

  .field-segment-icon {
    height: 30px;
    padding-left: ${px2rem(14)};
  }

  .field-segment-icon > * {
    width: 30px;
    height: 30px;
  }

  .field-segment-text {
    color: ${color.white};
    font-family: 'Lexend';
    font-size: ${px2rem(16)};
    padding-left: ${px2rem(14)};
  }

  .field-segment-action {
    position: absolute;
    right: 12px;
    bottom: 10px;
    cursor: pointer;
  }

  .ability-tags {
    display: flex;
    flex-wrap: wrap;
  }
`;
