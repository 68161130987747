import React from 'react';
import { observer } from 'mobx-react-lite';
import Button, { IconBtn } from 'components/common/Button';
import Segment from 'components/common/Segment';
import { iconSegments } from 'utils/iconSegments';
import { color } from 'theme/colors';
import { useStores } from 'models/root-store/root-store-context';
import { StyledModal } from './index.styled';

const SelectBusinessSegment: React.FC = observer(() => {
  const { mastersStore } = useStores();

  return (
    <>
      {mastersStore.businessSegment.map((segment) => (
        <Segment
          id={segment.id}
          key={`seg-${segment.id}`}
          description={segment.description}
          icon={iconSegments[segment.id - 1]?.iconName || 'others'}
        />
      ))}
    </>
  );
});

const Modal: React.FC = observer(() => {
  const { renderStore, mastersStore } = useStores();

  const handleClose = () => {
    if (renderStore.renderModalSegment) {
      renderStore.setRenderModalSegment(false);
    }
  };

  return (
    <StyledModal>
      <div className="modal-content">
        <div className="modal-close">
          <IconBtn icon="close" btnClick={handleClose} />
        </div>
        <div className="modal-head">
          <div className="modal-title">Agregar rubro</div>
          <div className="modal-subtitle">
            Seleccioná el rubro que represente el puesto de trabajo
          </div>
        </div>
        <div className="modal-body">
          <SelectBusinessSegment />
        </div>
        <div className="modal-footer">
          <Button text="Confirmar" color={color.red} btnClick={handleClose} />
        </div>
      </div>
    </StyledModal>
  );
});

export default Modal;
