import React from 'react';
import ReactDOM from 'react-dom';
import ConnectedApp from 'AppConnected';

declare global {
  interface Window {
    recaptchaVerifier: any;
  }
}

window.recaptchaVerifier = window.recaptchaVerifier || {};

ReactDOM.render(
  <React.StrictMode>
    <ConnectedApp />
  </React.StrictMode>,
  document.getElementById('root'),
);
