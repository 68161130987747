import styled from 'styled-components';
import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';

interface SearchPropsStyle {
  isClear?: boolean;
}

export const StyledSearchContacs = styled.div<SearchPropsStyle>`
  width: ${px2rem(315)};
  height: ${px2rem(40)};
  position: relative;
  margin-bottom: 30px;

  input {
    width: 100%;
    height: ${px2rem(40)};
    padding: 0 ${px2rem(76)} 0 ${px2rem(50)};
    border: none;
    border-radius: ${px2rem(12)};
    background-color: ${color.palette.shades.darkGrey};
    font-family: 'Lexend';
    font-size: ${px2rem(16)};
    color: ${color.white};
  }


  .search-icon {
    position: absolute;
    cursor: pointer;
    top: ${px2rem(9)};
    left: ${px2rem(13)};
  }

  .clear-icon {
    display: ${(props) => (props.isClear ? 'none' : 'flex')};
    position: absolute;
    cursor: pointer;
    top: ${px2rem(9)};
    right: ${px2rem(13)};
    background: none;
    border: none;
  }

`;
