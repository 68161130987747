import styled from 'styled-components';
import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';

interface InputProps {
  isCode?: boolean;
  tiny?: boolean;
}

export const StyledInput = styled.input<InputProps>`
  width: ${(props) => (props.isCode ? px2rem(48) : px2rem(328))};
  height: ${px2rem(48)};
  margin: ${(props) => (props.isCode ? '0 3px' : '0')};
  padding-left: ${px2rem(18)};
  padding-right: ${(props) => (props.isCode ? 0 : px2rem(40))};
  border-radius: ${px2rem(12)};
  border: ${px2rem(1)} solid ${color.white};
  background-color: ${color.black};
  margin-top: ${px2rem(8)};
  color: ${color.white};
  font-family: 'Lexend';
  font-size: ${px2rem(16)};

  -moz-appearance: textfield;
  &:hover,
  &:focus {
    -moz-appearance: textfield;
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

export const StyledTextarea = styled.textarea`
  width: ${px2rem(328)};
  height: ${px2rem(120)};
  margin-top: ${px2rem(8)};
  padding: ${px2rem(6)} ${px2rem(10)} ${px2rem(6)} ${px2rem(20)};
  border-radius: ${px2rem(12)};
  border: ${px2rem(1)} solid ${color.white};
  background-color: ${color.black};
  color: ${color.white};
  font-family: 'Lexend';
  font-size: ${px2rem(14)};
  resize: none;
`;

export const StyledTinyInput = styled(StyledInput)<InputProps>`
  width: ${(props) => (props.tiny ? px2rem(140) : px2rem(328))};
`;
