import React from 'react';
import LoginOptions from 'components/common/LoginOptions';
import RegisterCode from 'components/common/RegisterCode';
import Icon from 'components/icons/icon';
import { IconBtn } from 'components/common/Button';
import { StyledRightPanel } from 'theme/rigthPanel.styled';
import { color } from 'theme/colors';
import { useStores } from 'models/root-store/root-store-context';
import { observer } from 'mobx-react-lite';

const Login: React.FC = observer(function Login() {
  const { loginStore } = useStores();
  /**
   * LoginOptions
   * LoginCode (Phone)
   */

  const handleClose = () => {
    loginStore.setOpenLogin(false);
    loginStore.setStep('LoginOptions');
  };

  return (
    <StyledRightPanel>
      <>
        <div className="header-panel">
          <Icon icon="close" color={color.black} />
          <h2 className="section-rightpanel">Ingresá a tu perfil</h2>
          <IconBtn icon="close" btnClick={handleClose} />
        </div>
        {loginStore.step === 'LoginOptions' && <LoginOptions />}
        {loginStore.step === 'LoginCode' && <RegisterCode />}
        <div id="recaptcha-container" />
      </>
    </StyledRightPanel>
  );
});

export default Login;
