import React, { useEffect, useState } from 'react';
import Slider from 'rc-slider';
import { CustomSelect } from 'components/common/Select';
import Button, { LinkBtn } from 'components/common/Button';
import Label, { Error } from 'components/common/Label';
import { Checkbox } from 'components/common/Checkbox';
import 'rc-slider/assets/index.css';
import { color } from 'theme/colors';
import { useStores } from 'models/root-store/root-store-context';
import { observer } from 'mobx-react-lite';
import { StyledFilter } from './index.styled';

interface FilterProps {
  initialValues: {
    minimumAge: number;
    maximumAge: number;
    experience: number;
    reference: boolean;
    firstJob: boolean;
  };
}

const ages = [16, 18, 21, 25, 30, 35, 40, 45, 50, 55, 65, 70, 75, 80, 85];

const Filter: React.FC<FilterProps> = observer(({ initialValues }) => {
  const { filterStore, renderStore, sessionStore, loginStore } = useStores();
  const [values, setValues] = useState({
    minimumAge: 16,
    maximumAge: 85,
    experience: -1,
    reference: false,
    firstJob: false,
  });

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  const handleAges = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (logged) {
      const { name, selectedIndex } = e.target;
      setValues({ ...values, [name]: ages[selectedIndex] });
    }
  };

  const handleExp = (value: number) => {
    if (logged) {
      setValues({ ...values, experience: value });
    }
  };

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
      if (logged && ((name === 'firstJob' && values.reference === false) || (name === 'reference' && values.firstJob === false))) {
        setValues({ ...values, [name]: checked });
    }
  };

  const handleSubmit = () => {
    if(logged){
      if (!renderStore.renderCardList) {
        renderStore.setRenderCardList(true);
        renderStore.setRenderList(false);
      }
      filterStore.setFilter(
        values.minimumAge,
        values.maximumAge,
        values.experience,
        values.reference,
        values.firstJob,
      );
      renderStore.setRenderClearFilter(true);
      renderStore.setRenderFilter(false);
      renderStore.setRenderYourConversations(false);
      renderStore.setRenderPublishedOffers(false);
    }
  };
  const logged = !!sessionStore.token;
  return (
    <StyledFilter>
      <div className="title-filter">
        <h2>Filtrar</h2>
      </div>
      <>
        <div className="fields-age">
          <div className="field-age">
            <Label text="Edad mínima" />
            <CustomSelect
              name="minimumAge"
              id="minimumAge"
              options={ages.map(String)}
              value={values.minimumAge.toString()}
              selected={initialValues.minimumAge.toString()}
              width={156}
              onChange={handleAges}
            />
          </div>
          <div className="field-age">
            <Label text="Edad máxima" />
            <CustomSelect
              name="maximumAge"
              id="maximumAge"
              options={ages.map(String)}
              value={values.maximumAge.toString()}
              selected={initialValues.maximumAge.toString()}
              width={156}
              onChange={handleAges}
            />
          </div>
        </div>
        {values.minimumAge > values.maximumAge && (
          <Error text="¡La edad mínima no puede ser mayor a la máxima!" />
        )}
        <div className="fields-exp">
          <span className="title-field">Años de experiencia</span>
          <Slider
            step={1}
            min={-1}
            max={40}
            value={values.experience}
            defaultValue={-1}
            onChange={handleExp}
            handleStyle={{
              backgroundColor: '#C1C1C1',
              width: '16px',
              height: '16px',
              border: 'none',
              boxShadow: 'none',
            }}
            trackStyle={{ backgroundColor: '#6e6e6e' }}
            railStyle={{ backgroundColor: '#6e6e6e' }}
          />
          <Label
            text={
              values.experience === -1 ? 'Todos' : values.experience.toString()
            }
          />
        </div>
        <div className="fields-checkbox">
          <Checkbox
            text="Con Referencia"
            name="reference"
            checked={values.reference}
            onChange={handleCheckbox}
          />
          <Checkbox
            text="Primer Trabajo"
            name="firstJob"
            checked={values.firstJob}
            onChange={handleCheckbox}
          />
        </div>
        <div className="btn-wrapper">
          {!logged && <p className="have-account">
            ¡<LinkBtn
              text="Registrate"
              textColor={color.red}
              textSize={14}
              btnClick={() => {
                renderStore.closeLeftComponents();
                loginStore.setOpenRegister(true);
              }}
            />{' '}
            para poder acceder a los filtros completos!
          </p>}
          <Button
            text="Aplicar"
            color={color.red}
            hBtn={48}
            wBtn={328}
            btnClick={handleSubmit}
            disabled={values.minimumAge > values.maximumAge}
          />
        </div>
      </>
    </StyledFilter>
  );
});

export default Filter;
