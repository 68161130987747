import React from 'react';
import { SvgProps } from './icon.props';

const MapPinSvg: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00016 1.33333C6.58567 1.33333 5.22912 1.89524 4.22893 2.89543C3.22873 3.89562 2.66683 5.25218 2.66683 6.66667C2.66683 8.73204 4.01065 10.7362 5.48413 12.2915C6.20724 13.0548 6.93261 13.6796 7.47795 14.1139C7.6809 14.2755 7.85818 14.4101 8.00016 14.5151C8.14215 14.4101 8.31942 14.2755 8.52238 14.1139C9.06772 13.6796 9.79309 13.0548 10.5162 12.2915C11.9897 10.7362 13.3335 8.73204 13.3335 6.66667C13.3335 5.25218 12.7716 3.89562 11.7714 2.89543C10.7712 1.89524 9.41465 1.33333 8.00016 1.33333ZM8.00016 15.3333C7.63036 15.888 7.63019 15.8879 7.63 15.8878L7.6281 15.8865L7.62365 15.8835L7.60838 15.8732C7.59539 15.8644 7.57688 15.8517 7.5532 15.8353C7.50585 15.8025 7.43781 15.7548 7.35202 15.6928C7.18053 15.5689 6.93774 15.3881 6.64738 15.1569C6.06772 14.6954 5.29309 14.0286 4.51619 13.2085C2.98967 11.5972 1.3335 9.26796 1.3335 6.66667C1.3335 4.89856 2.03587 3.20286 3.28612 1.95262C4.53636 0.702379 6.23205 0 8.00016 0C9.76827 0 11.464 0.702379 12.7142 1.95262C13.9645 3.20286 14.6668 4.89856 14.6668 6.66667C14.6668 9.26796 13.0107 11.5972 11.4841 13.2085C10.7072 14.0286 9.93261 14.6954 9.35295 15.1569C9.06259 15.3881 8.8198 15.5689 8.6483 15.6928C8.56252 15.7548 8.49448 15.8025 8.44712 15.8353C8.42345 15.8517 8.40493 15.8644 8.39195 15.8732L8.37667 15.8835L8.37223 15.8865L8.37082 15.8875C8.37063 15.8876 8.36996 15.888 8.00016 15.3333ZM8.00016 15.3333L8.36996 15.888C8.14603 16.0373 7.85394 16.0371 7.63 15.8878L8.00016 15.3333Z"
        fill={color || 'black'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00016 5.33333C7.26378 5.33333 6.66683 5.93029 6.66683 6.66667C6.66683 7.40305 7.26378 8 8.00016 8C8.73654 8 9.3335 7.40305 9.3335 6.66667C9.3335 5.93029 8.73654 5.33333 8.00016 5.33333ZM5.3335 6.66667C5.3335 5.19391 6.5274 4 8.00016 4C9.47292 4 10.6668 5.19391 10.6668 6.66667C10.6668 8.13943 9.47292 9.33333 8.00016 9.33333C6.5274 9.33333 5.3335 8.13943 5.3335 6.66667Z"
        fill={color || 'black'}
      />
    </svg>
  );
};
export default MapPinSvg;
