import React from 'react';
import { useStores } from 'models/root-store/root-store-context';
import { observer } from 'mobx-react-lite';
import Icon from 'components/icons/icon';
import { StyledFooterButton } from './styles.dislpayComponents';

export const FooterButton: React.FC = observer(() => {
  const { renderStore } = useStores();

  const handleClick = () => {
    renderStore.setRenderEmployeeDetail(false);
    renderStore.setRenderChat(true);
  };

  return (
    <StyledFooterButton onClick={() => handleClick()}>
      <Icon icon="messages" /> <span className="text">Ver mensajes</span>
    </StyledFooterButton>
  );
});
