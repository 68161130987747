/* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */

import React, { useEffect, useState } from 'react';
import { LinkBtn } from 'components/common/Button';
import { color } from 'theme/colors';
import { useStores } from 'models/root-store/root-store-context';
import getFirebase from 'services/firebase/client';
import firebase from 'firebase';
import { CustomSlotInput } from '../Input';
import { Error } from '../Label';

const RegisterCode: React.FC = () => {
  const [timer, setTimer] = useState(60);
  const { registrationStore, sessionStore, loginStore, renderStore } =
    useStores();
  const [isEmployee, setIsEmployee] = useState(false);
  const [errorAuth, setErrorAuth] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((lastTimerCount) => {
        lastTimerCount <= 1 && clearInterval(interval);
        return lastTimerCount - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const verifier = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
      },
    );
  };

  const resendVerificationPhone = async () => {
    const phoneProvider = new (getFirebase().auth.PhoneAuthProvider)();
    const { phoneNumber } = registrationStore;
    verifier();
    const appVerifier = window.recaptchaVerifier;

    phoneProvider
      .verifyPhoneNumber(phoneNumber!, appVerifier)
      .then((verificationId) => {
        registrationStore.setVerificationId(verificationId);
        setTimer(60);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onCodeCompleted = async (code: any) => {
    try {
      const credential = getFirebase().auth.PhoneAuthProvider.credential(
        registrationStore.verificationId!,
        code,
      );
      const userCredential = await getFirebase()
        .auth()
        .signInWithCredential(credential)
        .catch((error) => {
          const errorCode = error.code;
          switch (errorCode) {
            case 'auth/invalid-verification-code':
              setErrorAuth('¡El código es invalido!');
              break;
            case 'auth/code-expired':
              setErrorAuth('¡El código expiro!');
              break;
            default:
              console.error(error);
              break;
          }
          if (errorCode === 'auth/account-exists-with-different-credential') {
            console.log('Email already associated with another account.');
          } else {
            console.error(error);
          }
        });
      if (userCredential) {
        const token = Object.entries(userCredential.user!)[5][1].b.i;
        sessionStore.setToken(token);
        const body = {
          phoneNumber: registrationStore.phoneNumber,
          userTypeId: 2,
        };

        if (loginStore.openRegister) {
          sessionStore.postUser(body).then((res) => {
            if (res === 'ConflictError') {
              setErrorAuth('Estas intentando loguearte?');
            } else {
              loginStore.setUserLogged(true);
              registrationStore.setStep('RegisterCommerce');
            }
          });
        } else {
          await sessionStore.getMeUser();
          if (sessionStore.currentUser?.userTypeId === 1) {
            setIsEmployee(true);
            // setValidationError('isEmployee');
            return;
          }
          loginStore.setLogin(true);
          loginStore.setStep('LoginOptions');
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <h1 className="title-rightpanel">
        Ingresá el código que recibiste por SMS
      </h1>
      <div>
        <CustomSlotInput onCodeCompleted={onCodeCompleted} />
      </div>
      <div
        className="field-wrapper"
        style={{ width: '100%', marginTop: '10px' }}
      >
        {errorAuth === 'Estas intentando loguearte?' ? (
          <Error
            text={errorAuth}
            errClick={() => {
              renderStore.closeLeftComponents();
              loginStore.setOpenLogin(true);
              registrationStore.setStep('RegisterOptions');
            }}
          />
        ) : errorAuth && (
          <Error text={errorAuth} />
        )}
      </div>
      <div className="wrapper-texts">
        <span>¿No recibiste el código?</span>
        <LinkBtn
          text={timer > 0 ? `Reenviar en ${timer} segundos` : 'Reenviar'}
          textColor={color.red}
          textSize={14}
          textFont="Lexend"
          btnClick={resendVerificationPhone}
          disabled={timer > 0}
        />
      </div>
      <div className="wrapper-texts">
        <span>
          Revisá que el nro. {registrationStore.phoneNumber} sea correcto
        </span>
        <LinkBtn
          text="Modificar teléfono"
          textColor={color.red}
          textSize={14}
          textFont="Lexend"
          btnClick={() => {
            if (loginStore.openLogin) {
              loginStore.setStep('LoginOptions');
            } else {
              registrationStore.setStep('RegisterCredentials');
            }
          }}
        />
        {isEmployee && (
          <span style={{ color: color.red, paddingTop: 20 }}>
            El nro ingresado esta registrado como empleado{' '}
          </span>
        )}
      </div>
    </>
  );
};

export default RegisterCode;
