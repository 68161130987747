import { types, Instance, SnapshotOut } from 'mobx-state-tree';

export const SkillModel = types.model('Skill').props({
  skillId: types.number,
  skillName: types.string,
  color: types.string,
});

export const SkillsModel = types
  .model('skills')
  .props({
    skills: types.optional(types.array(SkillModel), []),
    count: types.optional(types.number, 1),
  })
  .actions((self) => {
    function addSkill(skillName: string, color: string) {
      if(skillName !== ''){
        self.skills.push({
          skillId: self.count,
          skillName,
          color,
        });
        self.count += 1;
      }
    }

    function removeSkillById(skillId: number) {
      const index = self.skills.findIndex((sk) => sk.skillId === skillId);
      self.skills.splice(index, 1);
    }

    function removeSkills() {
      self.skills.length = 0;
    }

    return {
      addSkill,
      removeSkillById,
      removeSkills,
    };
  });

type SkillsModelType = Instance<typeof SkillsModel>;
export type Skills = SkillsModelType;
type SkillsSnapshotType = SnapshotOut<typeof SkillsModel>;
export type SkillsSnapshot = SkillsSnapshotType;

export const  SkillsModelDefault = () =>
  types.optional(SkillsModel, { skills: [] });
