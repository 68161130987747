import React from 'react';
import { useStores } from 'models/root-store/root-store-context';
import Button, { LinkBtn } from 'components/common/Button';
import { color } from 'theme/colors';
import { StyledAuthOpts } from './index.styled';

const AuthOptions: React.FC = () => {
  const { loginStore, registrationStore, renderStore } = useStores();

  return (
    <StyledAuthOpts>
      <div style={{ marginRight: '19px' }}>
        <LinkBtn
          text="Ingresar"
          textColor={color.red}
          textSize={16}
          textFont="Lexend Mega"
          btnClick={() => {
            renderStore.closeLeftComponents();
            loginStore.setOpenLogin(true);
            loginStore.setStep('LoginOptions');
          }}
        />
      </div>
      <Button
        text="Registrarme"
        color={color.red}
        wBtn={150}
        hBtn={48}
        btnClick={() => {
          renderStore.closeLeftComponents();
          loginStore.setOpenRegister(true);
          registrationStore.setStep('RegisterOptions');
        }}
      />
    </StyledAuthOpts>
  );
};

export default AuthOptions;
