import { types, Instance, SnapshotOut } from 'mobx-state-tree';
import { UserModel } from 'models/session/userModel';
import { LocationData, Location } from 'models/locationModel';
import { DocumentTypeModel } from './documentTypeModel';
import { GenderModel } from '../genderModel';

export const EmployeeModel: any = types.model('Employee').props({
  modifiedAt: types.string,
  state: types.string,
  id: types.string,
  userId: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  documentNumber: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  location: types.maybeNull(Location),
  picture: types.maybeNull(types.string),
  birthdate: types.maybeNull(types.string),
  firstJob: types.maybeNull(types.boolean),
  profession: types.maybeNull(types.string),
  remuneration: types.maybeNull(types.number),
  howYouWork: types.maybeNull(types.string),
  presentationCompleted: types.maybeNull(types.boolean),
  skills: types.maybeNull(types.string),
  skillsCompleted: types.maybeNull(types.boolean),
  genderId: types.maybeNull(types.number),
  documentTypeId: types.maybeNull(types.number),
  educationCompleted: types.maybeNull(types.boolean),
  experienceCompleted: types.maybeNull(types.boolean),
  gender: types.maybeNull(GenderModel),
  documentType: types.maybeNull(DocumentTypeModel),
  user: types.maybeNull(UserModel),
  experienceYears: types.maybeNull(types.number),
  hasReferences: types.maybeNull(types.boolean),
  locationData: types.maybeNull(LocationData),
});

type EmployeeModelType = Instance<typeof EmployeeModel>;
export type Employee = EmployeeModelType;
type EmployeeSnapshotType = SnapshotOut<typeof EmployeeModel>;
export type EmployeeSnapshot = EmployeeSnapshotType;
export const createEmployeeDefaultModel = () =>
  types.optional(EmployeeModel, {});
