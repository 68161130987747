import { types, Instance, SnapshotOut } from 'mobx-state-tree';
import { EmployeeModel } from '../employeeModel';
import { TerminationReasonModel } from './terminationReasonModel';
import { ReferenceModel } from './referenceModel';
import { BusinessSegmentModel } from './businessSegmentModel';

export const ExperienceModel: any = types.model('Experience').props({
  createdAt: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  modifiedAt: types.maybeNull(types.string),
  modifiedBy: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  company: types.maybeNull(types.string),
  job: types.maybeNull(types.string),
  start: types.maybeNull(types.string),
  end: types.maybeNull(types.string),
  employeeId: types.maybeNull(types.string),
  terminationReasonId: types.maybeNull(types.number),
  modalityId: types.maybeNull(types.number),
  businessSegmentId: types.maybeNull(types.number),
  employee: types.maybeNull(EmployeeModel),
  terminationReason: types.maybeNull(TerminationReasonModel),
  references: types.maybeNull(types.array(ReferenceModel)),
  businessSegmentModel: types.maybeNull(BusinessSegmentModel),
});

type ExperienceModelType = Instance<typeof ExperienceModel>;
export type Experience = ExperienceModelType;
type ExperienceSnapshotType = SnapshotOut<typeof ExperienceModel>;
export type ExperienceSnapshot = ExperienceSnapshotType;
export const createExperienceDefaultModel = () =>
  types.optional(ExperienceModel, {});
