import React from 'react';
import { FooterButton } from './footerButton';
import { StyledFooter } from './styles.dislpayComponents';

interface FooterProps {
  name: string;
}

const Footer: React.FC<FooterProps> = ({ name }) => {
  return (
    <StyledFooter>
      <div className="footerBox">
        <span className="footerText">Ya estás en contacto con {name}</span>
      </div>
      <FooterButton />
    </StyledFooter>
  );
};

export default Footer;
