import React from 'react';
import CommerceNameForm from 'components/common/forms/CommerceName';
import { useStores } from 'models/root-store/root-store-context';

const RegisterCommerce: React.FC = () => {
  const { loginStore, registrationStore, renderStore, dataCommerceStore } =
    useStores();
  const handleCommerce = (name: string) => {
    if (renderStore.renderCommerceData) {
      dataCommerceStore.setNameCommerce(name);
      renderStore.setStepCommcerData(renderStore.stepCommerceData + 1);
    } else {
      registrationStore.setCommerceName(name); // TODO: Realizar validación con validationError?
      const body = {
        name,
      };
      registrationStore.putBusiness(body);
      registrationStore.setStep('RegisterOptions');
      loginStore.setLogin(true);
      // TODO: Lanzar modal de bienvenida, si y solo si ingresa el nombre del comercio y está registrándos
    }
  };

  return (
    <>
      <h1 className="title-rightpanel">¿Cuál es el nombre de tu comercio?</h1>
      <CommerceNameForm onSubmit={handleCommerce} />
    </>
  );
};

export default RegisterCommerce;
