import React from 'react';
import { LinkBtn } from 'components/common/Button';
import { color } from 'theme/colors';
import { useStores } from 'models/root-store/root-store-context';
import { StyledCard } from './styles.dislpayComponents';

export default function NotRegisteredCard() {
  const { loginStore, registrationStore } = useStores();
  return (
    <StyledCard>
      <div className="notRegisteredBox">
        <span className="notRegisteredTitle">
          Registrá tu comercio en Buba para ver los perfiles
        </span>
        <span className="notRegisteredButton">
          <LinkBtn
            text="Registrar mi comercio"
            textColor={color.red}
            btnClick={() => {
              loginStore.setOpenRegister(true);
              registrationStore.setStep('RegisterOptions');
            }}
          />
        </span>
        {/* <span>ó</span>
        <span className="notRegisteredButton">
          <LinkBtn
            text="Ingresa a tu cuenta"
            textColor={color.red}
            btnClick={() => {
              loginStore.setOpenRegister(true);
              registrationStore.setStep('RegisterOptions');
            }}
          />
        </span> */}
      </div>
    </StyledCard>
  );
}
