import React, { useState } from 'react';
import Button, { LinkBtn } from 'components/common/Button';
import EmailForm from 'components/common/forms/Email';
import { color } from 'theme/colors';
import { Divider } from 'theme/divider.styles';
import { AuthProps } from 'lib/types';
import { useStores } from 'models/root-store/root-store-context';
import { normalizePhoneNumber } from 'utils/common';
import getFirebase, {
  googleAuthProvider,
  facebookAuthProvider,
} from 'services/firebase/client';
import firebase from 'firebase';
import PhoneForm from '../forms/Phone';

const LoginOptions: React.FC = () => {
  const { loginStore, sessionStore, registrationStore } = useStores();
  const [loginMethod, setLoginMethod] = useState('phone');

  const verifier = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
      },
    );
  };

  const loginWithPhone = (values: AuthProps, setValidationError: any) => {
    const phoneProvider = new (getFirebase().auth.PhoneAuthProvider)();
    const { phoneNumber } = values;
    const normalizedPhoneNumber = normalizePhoneNumber(phoneNumber!);
    verifier();
    const appVerifier = window.recaptchaVerifier;

    phoneProvider
      .verifyPhoneNumber(normalizedPhoneNumber, appVerifier)
      .then((verificationId) => {
        registrationStore.setPhone(normalizedPhoneNumber);
        registrationStore.setVerificationId(verificationId);
        loginStore.setStep('LoginCode');
      })
      .catch((error: any) => {
        setValidationError(error.code || error.message);
      });
  };

  const loginWithEmail = async (values: AuthProps, setValidationError: any) => {
    const auth = getFirebase().auth();
    try {
      const userCredential = await auth.signInWithEmailAndPassword(
        values.email!,
        values.password!,
      );
      if (userCredential) {
        // @ts-ignore
        const token = Object.entries(userCredential.user)[5][1].b.i;
        sessionStore.setToken(token);
        await sessionStore.getMeUser();
        if (sessionStore.currentUser?.userTypeId === 1) {
          setValidationError('isEmployee');
          return;
        }
        if (!userCredential.user?.emailVerified) {
          console.log(userCredential);
          console.log('Sin verificar email');
        }
      }
      loginStore.setLogin(true);
    } catch (error: any) {
      setValidationError(error.code || error.message);
    }
  };

  const handleAuth = async (provider: any) => {
    // TODO: Chequear que esta función funcione igual para Google
    await getFirebase()
      .auth()
      .signInWithPopup(provider)
      .then((result: any) => {
        const token: any = Object.entries(result.user!)[6][1];
        sessionStore.setToken(token);
        if (token) {
          loginStore.setLogin(true);
          registrationStore.setStep('RegisterCommerce');
        }
      })
      .catch((error) => {
        console.log('[handleAuth error]:', error);
      });
  };

  return (
    <>
      <h1 className="title-rightpanel">Hola de nuevo</h1>
      <div>
        {loginMethod === 'email' ? (
          <EmailForm onSubmit={loginWithEmail} />
        ) : (
          <div className="field-wrapper">
            <PhoneForm onSubmit={loginWithPhone} />
          </div>
        )}
        <Divider>
          <div />ó<div />
        </Divider>
        {loginMethod === 'email' ? (
          <div className="field-wrapper">
            <Button
              text="Ingresar con Teléfono"
              color={color.black}
              wBtn={328}
              hBtn={48}
              icon="phone"
              btnClick={() => setLoginMethod('phone')}
            />
          </div>
        ) : (
          <div className="field-wrapper">
            <Button
              text="Ingresar con email"
              color={color.black}
              wBtn={328}
              hBtn={48}
              icon="email"
              btnClick={() => setLoginMethod('email')}
            />
          </div>
        )}
        <div className="field-wrapper">
          <Button
            text="Ingresar con Facebook"
            color={color.black}
            wBtn={328}
            hBtn={48}
            icon="facebook"
            btnClick={() => {
              handleAuth(facebookAuthProvider);
            }}
          />
        </div>
        <div className="field-wrapper">
          <Button
            text="Ingresar con Google"
            color={color.black}
            wBtn={328}
            hBtn={48}
            icon="google"
            btnClick={() => handleAuth(googleAuthProvider)}
          />
        </div>
      </div>
      <p className="have-account">¿No tenés un perfil en Buba?</p>
      <LinkBtn
        text="Registrate y creá tu cuenta"
        textColor={color.red}
        textSize={14}
        btnClick={() => {
          loginStore.setOpenRegister(true);
        }}
      />
    </>
  );
};

export default LoginOptions;
