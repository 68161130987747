import { ApiResponse } from 'apisauce';
import { Api } from './api';
import { getGeneralApiProblem } from './api-problem';

export class UserApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async postUserProfile(body: any): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(
        '/user',
        body,
      );
      // the typical ways to die when calling an api
      if (!response.ok) {
        if (response.data.error.name === 'ConflictError') {
          return response.data.error;
        }
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const userData = response.data;
      return { kind: 'ok', userData };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
