import { useEffect, useState } from 'react';

export default function useUserLocation() {
  const [coords, setCoords] = useState({});
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCoords({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (error) => {
        setCoords({
          lat: -34.5955257,
          lng: -58.3842745,
        });
        console.log(error);
      },
    );
  }, []);

  return coords;
}
