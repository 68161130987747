import React from 'react';
import { SvgProps } from './icon.props';

const ClockSvg: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || 16}
      height={height || 17}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00033 2.50001C4.68662 2.50001 2.00033 5.1863 2.00033 8.5C2.00033 11.8137 4.68662 14.5 8.00033 14.5C11.314 14.5 14.0003 11.8137 14.0003 8.5C14.0003 5.1863 11.314 2.50001 8.00033 2.50001ZM0.666992 8.5C0.666992 4.44992 3.95024 1.16667 8.00033 1.16667C12.0504 1.16667 15.3337 4.44992 15.3337 8.5C15.3337 12.5501 12.0504 15.8333 8.00033 15.8333C3.95024 15.8333 0.666992 12.5501 0.666992 8.5Z"
        fill={color || '#C1C1C1'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99967 3.83333C8.36786 3.83333 8.66634 4.13181 8.66634 4.49999V8.08797L10.9645 9.23704C11.2938 9.4017 11.4273 9.80215 11.2626 10.1315C11.098 10.4608 10.6975 10.5943 10.3682 10.4296L7.70153 9.09628C7.47568 8.98335 7.33301 8.75251 7.33301 8.5V4.49999C7.33301 4.13181 7.63148 3.83333 7.99967 3.83333Z"
        fill={color || '#C1C1C1'}
      />
    </svg>
  );
};

export default ClockSvg;
