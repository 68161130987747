/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import Icon from 'components/icons/icon';
import { ContactBtn } from 'components/common/Button';
import { color } from 'theme/colors';
import { randomColor } from 'utils/randomColor';
import { useStores } from 'models/root-store/root-store-context';
import { Employee } from 'models/employees/employeeModel';
import defaultRed from 'assets/images/defaultRed.svg';
import Display from './components/display';
import Footer from './components/footer';
import { StyledEmployeeDetail } from './employeeDetail.styled';

export const EmployeeDetail: React.FC = observer(() => {
  const {
    renderStore,
    employeesStore,
    contactsStore,
    sessionStore,
    roomStore,
    skillsModel,
    loginStore,
    registrationStore,
  } = useStores();
  const [employee, setEmployee] = useState<Employee | null>(null);
  const [showPhone, setShowPhone] = useState<boolean>(false);
  const logged = !!sessionStore.token;
  const getEmployee = () => {
    const employeeData: Employee | null = employeesStore.getEmployeeById();
    if (employeeData) {
      setEmployee(employeeData);
    }
  };

  useEffect(() => {
    getEmployee();
    setShowPhone(false);
    // eslint-disable-next-line
  }, [employeesStore.employeeSelected]);

  skillsModel.removeSkills();
  employee?.skills
    ?.split(',')
    .map((skill: string) => skillsModel.addSkill(skill, randomColor()));

  const inContact = () => {
    return (
      contactsStore.approvedContacts.filter(
        (contact) => contact.id === employee.id,
      ).length > 0
    );
  };

  const pendingContact = () => {
    return (
      contactsStore.penddingContacts.filter(
        (contact) => contact.id === employee.id,
      ).length > 0
    );
  };
  const enterChat = () => {
    const contactEmployee = contactsStore.approvedContacts.filter(
      (contact) => contact.id === employee.id,
    );
    const applicantEmployee = contactsStore.penddingContacts.filter(
      (contact) => contact.id === employee.id,
    );
    const contactedEmployee =
      contactEmployee.length > 0
        ? contactEmployee[0]
        : applicantEmployee.length > 0
        ? applicantEmployee[0]
        : false;
    if (inContact() || pendingContact()) {
      roomStore.setRoom(contactedEmployee ? contactedEmployee.contactId : '');
      return true;
    }
    return false;
  };

  function handleImg() {
    if (!logged) {
      renderStore.closeLeftComponents();
      loginStore.setOpenRegister(true);
      registrationStore.setStep('RegisterOptions');
    }
  }
  return (
    <StyledEmployeeDetail>
      <div
        onKeyDown={() => renderStore.setRenderEmployeeDetail(false)}
        tabIndex={0}
        role="button"
        onClick={() => {
          renderStore.setRenderEmployeeDetail(false);
        }}
        className="close"
      >
        <Icon icon="close" />
      </div>
      {employee && (
        <>
          <div className="header">
            <div role="article">
              <img
                className={`photo ${logged ? '' : 'photo-blur'}`}
                src={employee.picture || defaultRed}
                alt="dudePic"
                onClick={() => handleImg()}
                onKeyDown={handleImg}
                role="presentation"
              />
            </div>
            <div className="titleBox">
              <span className="title">
                {employee.firstName || ''}{' '}
                {logged ? employee.lastName || '' : ''}
              </span>
              <div className="MaindataBox">
                <div className="iconText">
                  <Icon
                    icon="briefCase"
                    color={color.palette.shades.lightGray}
                  />
                  <span className="text">
                    {employee.profession || 'Primer trabajo'}
                  </span>
                </div>
                {sessionStore.currentUser && sessionStore.currentUser.business && (
                  <div className="iconText">
                    <Icon
                      icon="mapPin"
                      color={color.palette.shades.lightGray}
                    />
                    <span className="text">
                      {employee?.locationData?.distanceString}
                    </span>
                  </div>
                )}
                <div className="iconText">
                  <Icon
                    icon="calendar"
                    color={color.palette.shades.lightGray}
                  />
                  <span className="text">
                    {moment().diff(employee.birthdate, 'years')} años
                  </span>
                </div>
              </div>
              <div
                className="MaindataBox"
                style={{ alignContent: 'space-between' }}
              >
                {inContact() ? (
                  <ContactBtn
                    hBtn={32}
                    wBtn={160}
                    color={color.darkGrey}
                    icon="check"
                    text="En contacto"
                  />
                ) : pendingContact() ? (
                  <ContactBtn
                    hBtn={32}
                    wBtn={200}
                    textSize={13}
                    color={color.darkGrey}
                    icon="check"
                    text="Solicitud pendiente"
                  />
                ) : !logged ? (
                  <ContactBtn
                    hBtn={32}
                    wBtn={132}
                    color="#717173"
                    icon="plus"
                    text="Conectar"
                    btnClick={() => {
                      renderStore.closeLeftComponents();
                      loginStore.setOpenRegister(true);
                      registrationStore.setStep('RegisterOptions');
                    }}
                  />
                ) : (
                  <ContactBtn
                    hBtn={32}
                    wBtn={132}
                    color={color.red}
                    icon="plus"
                    text="Conectar"
                    disabled={!logged}
                    btnClick={() => {
                      contactsStore.postContact(employee.id);
                    }}
                  />
                )}
                {logged && employee?.user?.phoneNumber ? (
                  <ContactBtn
                    hBtn={32}
                    wBtn={170}
                    color="#717173"
                    icon="phone"
                    text={
                      showPhone ? `  ${employee.user.phoneNumber}` : 'Teléfono'
                    }
                    btnClick={() => {
                      setShowPhone(true);
                      registrationStore.setStep('RegisterOptions');
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="textBox">
                <span className="midText">{employee.howYouWork || ''}</span>

                <span className="midTextDark">Remuneración pretendida</span>
                <span className="midText">$ {employee.remuneration || ''}</span>
              </div>
            </div>
          </div>

          <Display employee={employee} />

          {enterChat() && (
            <Footer name={`${employee.firstName} ${employee.lastName}`} />
          )}
        </>
      )}
    </StyledEmployeeDetail>
  );
});
