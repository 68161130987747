import React from 'react';
import { SvgProps } from './icon.props';

const ChevronRightSvg: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || 8}
      height={height || 14}
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.70711 0.292893C8.09763 0.683418 8.09763 1.31658 7.70711 1.70711L2.41421 7L7.70711 12.2929C8.09763 12.6834 8.09763 13.3166 7.70711 13.7071C7.31658 14.0976 6.68342 14.0976 6.29289 13.7071L0.292893 7.70711C-0.0976318 7.31658 -0.0976317 6.68342 0.292893 6.29289L6.29289 0.292893C6.68342 -0.0976312 7.31658 -0.0976311 7.70711 0.292893Z"
        fill={color || '#C1C1C1'}
      />
    </svg>
  );
};

export default ChevronRightSvg;
