import React from 'react';
import Icon from 'components/icons/icon';
import { StyledCircleWicon } from './styles.dislpayComponents';

export default function CircleWicon(props: any) {
  const { color, icon, iconColor, height, width, iconHeight, iconWidth } =
    props;
  return (
    <StyledCircleWicon height={height || 32} width={width || 32} color={color}>
      <Icon
        icon={icon}
        color={iconColor}
        height={iconHeight || 32}
        width={iconWidth || 32}
      />
    </StyledCircleWicon>
  );
}
