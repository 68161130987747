import React from 'react';
import { icons } from '.';
import { IconProps } from './icon.props';

const Icon: React.FC<IconProps> = (props) => {
  const { icon, color, height, width } = props;
  return icons[icon]({ color, height, width });
};

export default Icon;
