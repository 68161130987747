import React, { useEffect, useState } from 'react';
import Label from 'components/common/Label';
import Select from 'components/common/Select';
import Button from 'components/common/Button';
import { Formik } from 'formik';
import { color } from 'theme/colors';
import { useStores } from 'models/root-store/root-store-context';
import { KeysMasters } from 'lib/types';

interface CommerceEmployeesProps {
  employeesCounts: KeysMasters[];
}

const CommerceEmployees: React.FC<CommerceEmployeesProps> = ({
  employeesCounts,
}) => {
  const { renderStore, dataCommerceStore } = useStores();
  const [employeesOptions, setEmployeesOptions] = useState<string[]>([]);
  const [initialValues, setInitialValues] = useState({
    employeesCount:
      employeesOptions[(dataCommerceStore.employeesCountId || 1) - 1],
  });

  useEffect(() => {
    if (employeesCounts) {
      const options: string[] = employeesCounts
        ? employeesCounts.map((employee: KeysMasters) => employee.description)
        : [];
      setEmployeesOptions(options);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setInitialValues({
      employeesCount:
        employeesOptions[(dataCommerceStore.employeesCountId || 1) - 1],
    });
    // eslint-disable-next-line
  }, [employeesOptions]);

  return (
    <>
      <h1 className="title-rightpanel">
        Ayudanos a validar algunos datos de tu negocio
      </h1>
      {initialValues.employeesCount && employeesCounts && (
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            const { employeesCount } = values;
            const employeesId = employeesCounts.filter(
              (employee: KeysMasters) =>
                employee.description === employeesCount,
            );
            dataCommerceStore.setEmployeeCount(
              employeesId ? employeesId[0].id : 0,
            );
            dataCommerceStore.putDataCommerce({ ...dataCommerceStore });
            renderStore.setStepCommcerData(renderStore.stepCommerceData + 1);
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Label text="Cantidad de empleados" />
              {employeesOptions && (
                <div className="field-wrapper">
                  <Select
                    name="employeesCount"
                    id="employeesCount"
                    options={employeesOptions}
                    selected={initialValues.employeesCount}
                  />
                </div>
              )}
              <div className="field-wrapper">
                <Button
                  text="Continuar"
                  color={color.red}
                  hBtn={48}
                  wBtn={328}
                  btnClick={handleSubmit}
                />
              </div>
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default CommerceEmployees;
