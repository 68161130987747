import { types, Instance, SnapshotOut } from 'mobx-state-tree';
import api from 'services/api/api';
import { EducationModel } from './educationModel';

export const EducationsModel: any = types
  .model('Educations')
  .props({
    loading: types.boolean,
    employeeEducations: types.optional(types.array(EducationModel), []),
  })
  .actions((self) => {
    function setLoading() {
      self.loading = true;
    }
    function setEducations(educations: typeof EducationModel) {
      self.employeeEducations = educations;
      self.loading = false;
    }
    return {
      setLoading,
      setEducations,
    };
  })
  .actions((self) => ({
    getMeEducations: async (employeeId: string) => {
      const response = await api.employee.getEducation(employeeId);
      if (response) {
        self.setEducations(response.education);
      }
    },
  }));

type EducationsModelType = Instance<typeof EducationsModel>;
export type Educations = EducationsModelType;
type EducationsSnapshotType = SnapshotOut<typeof EducationsModel>;
export type EducationsSnapshot = EducationsSnapshotType;
export const createEducationsDefaultModel = () =>
  types.optional(EducationsModel, {
    loading: false,
    employeeEducations: [],
  });
