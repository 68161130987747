import styled from 'styled-components';
import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';

export const StyledLabel = styled.label`
  font-family: 'Lexend';
  font-size: ${px2rem(14)};
  color: ${color.palette.shades.lightGray};
  width: fit-content;
  margin-bottom: ${px2rem(4)};
`;

export const StyledError = styled.span<{ field?: boolean }>`
  display: flex;
  align-items: center;
  margin: ${px2rem(4)};

  .error-text {
    padding-left: ${px2rem(8)};
    font-family: 'Lexend';
    font-size: ${(props) => (props.field ? px2rem(12) : px2rem(16))};
    color: ${color.palette.system.error};
  }
`;
