import { types, Instance, SnapshotOut } from 'mobx-state-tree';
import api from 'services/api/api';
import { EmployeeModel } from './employeeModel';

export const EmployeesModel = types
  .model('Employees')
  .props({
    loading: types.boolean,
    employees: types.optional(types.array(EmployeeModel), []),
    employeeSelected: types.maybeNull(types.string), // id de employee
  })
  .views((self) => ({
    getEmployeeById(id = self.employeeSelected) {
      return id ? self.employees.find((employee) => employee.id === id) : null;
    },
  }))
  .actions((self) => {
    function setLoading() {
      self.loading = true;
    }
    function setEmployees(employees: any) {
      self.employees = employees;
      self.loading = false;
    }
    function setEmployeeSelected(id: string | null) {
      self.employeeSelected = id;
    }
    return {
      setLoading,
      setEmployees,
      setEmployeeSelected,
    };
  })
  .actions((self) => ({
    getMeEmployees: async () => {
      const response = await api.employees.getEmployees();
      if (response.employees) {
        self.setEmployees(response.employees.data);
      }
    },
  }));

type EmployeesModelType = Instance<typeof EmployeesModel>;
export type Employees = EmployeesModelType;
type EmployeesSnapshotType = SnapshotOut<typeof EmployeesModel>;
export type EmployeesSnapshot = EmployeesSnapshotType;
export const createEmployeesDefaultModel = () =>
  types.optional(EmployeesModel, {
    loading: false,
    employees: [],
    employeeSelected: null,
  });
