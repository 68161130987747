import { ApiResponse } from 'apisauce';
import { Api } from './api';
import { getGeneralApiProblem } from './api-problem';

export class MastersApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getMasters(): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        '/masters',
      );
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const mastersData = response.data;
      return { kind: 'ok', mastersData };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
