import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';
import { MessageProps } from 'lib/types';
import styled from 'styled-components';

export const StyledMessage = styled.div<MessageProps>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isCurrentUser ? 'flex-end' : 'flex-start')};
  align-self: ${(props) => (props.isCurrentUser ? 'flex-end' : 'flex-start')};
  height: ${px2rem(81)};
  background-color: ${color.black};
  margin-bottom: ${px2rem(28)};

  .box {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
  }

  .photoBox {
    display: flex;
    flex-direction: column;
    background-color: ${color.black};
    width: ${px2rem(41)};
    height: ${px2rem(61)};
    margin-right: ${px2rem(8)};
  }

  .photoBubble {
    background-color: ${color.black};
    width: ${px2rem(40)};
    height: ${px2rem(40)};
    border-radius: ${px2rem(20)};
  }

  .messageBox {
    background-color: ${(props) =>
      props.isCurrentUser ? color.happyYellow : color.darkGrey};
    display: flex;
    max-width: ${px2rem(400)};
    height: fit-content;
    border-radius: 0px ${px2rem(8)} ${px2rem(8)} ${px2rem(8)};
    align-items: center;
    padding: 1em;
  }

  .messageText {
    font-family: 'Lexend';
    font-size: ${px2rem(14)};
    font-weight: bold;
    color: ${(props) => (props.isCurrentUser ? color.black : color.white)};
    overflow-wrap: break-word;
    word-break: break-word;
  }

  .dateText {
    font-family: 'Lexend';
    margin-left: ${px2rem(48)};
    font-size: ${px2rem(12)};
    color: ${color.palette.shades.lightGray};
  }
`;
