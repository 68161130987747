import {
  applySnapshot,
  getSnapshot,
  Instance,
  SnapshotOut,
  types,
} from 'mobx-state-tree';
import { createContactsDefaultModel } from 'models/conversations/contactsModel';
import { roomDefaultModel } from 'models/conversations/room';
import { MessagesDefaultModel } from 'models/conversations/messages';
import { createEducationsDefaultModel } from 'models/employees/education/educationsModel';
import { createEmployeesDefaultModel } from 'models/employees/employeesModel';
import { createExperiencesDefaultModel } from 'models/employees/experience/experiencesModel';
import { createFilterDefaultModel } from 'models/filter/filterModel';
import { createMastersDefaultModel } from 'models/masters/mastersModel';
import { RegistrationModel } from 'models/registration/registrationModel';
import { createRenderDefaultModel } from 'models/render/renderModel';
import { SkillsModelDefault } from 'models/render/skillsModel';
import { createDataCommerceDefaultModel } from 'models/session/dataCommerceModel';
import { createJobPositionDefaultModel } from 'models/session/jobPositionModel';
import { createLoginDefaultModel } from 'models/session/loginModel';
import { MeJobsModelDefault } from 'models/session/meJobsModel';
import { createSessionDefaultModel } from 'models/session/sessionModel';

export const resetStore = (self: any) => {
  let initialState: any;
  return {
    afterCreate() {
      initialState = getSnapshot(self);
    },
    resetStore() {
      applySnapshot(self, initialState);
    },
  };
};

export const RootStoreModel = types
  .model('RootStore')
  .props({
    loginStore: createLoginDefaultModel(),
    sessionStore: createSessionDefaultModel(),
    renderStore: createRenderDefaultModel(),
    registrationStore: types.optional(RegistrationModel, {}),
    employeesStore: createEmployeesDefaultModel(),
    JobPositionStore: createJobPositionDefaultModel(),
    dataCommerceStore: createDataCommerceDefaultModel(),
    educationsStore: createEducationsDefaultModel(),
    experiencesStore: createExperiencesDefaultModel(),
    mastersStore: createMastersDefaultModel(),
    filterStore: createFilterDefaultModel(),
    skillsModel: SkillsModelDefault(),
    meJobsStore: MeJobsModelDefault(),
    contactsStore: createContactsDefaultModel(),
    messagesStore: MessagesDefaultModel(),
    roomStore: roomDefaultModel(),
  })
  .actions(resetStore);

export const rootStore = RootStoreModel.create();
rootStore.afterCreate();
export type RootStore = Instance<typeof RootStoreModel>;
export type RootStoreSnapshot = SnapshotOut<typeof RootStoreModel>;
