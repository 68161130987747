import { ApiResponse } from 'apisauce';
import { Api } from './api';
import { getGeneralApiProblem } from './api-problem';

export class JobApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async postJobs(body: any): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(
        '/job',
        body,
      );
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const jobsData = response.data;
      return { kind: 'ok', jobsData };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async putJob(body: any, jobId: string): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.put(
        `/job/${jobId}`,
        body,
      );
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const jobsData = response.data;
      return { kind: 'ok', jobsData };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async putJobPause(body: any): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.put(
        '/job/pause',
        body,
      );
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const jobData = response.data;
      return { kind: 'ok', jobData };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async putJobResume(body: any): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.put(
        '/job/resume',
        body,
      );
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const jobData = response.data;
      return { kind: 'ok', jobData };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
