import styled from 'styled-components';
import { px2rem } from 'theme/styleUtils';

export interface bubaLoaderProps {
  className?: string; 
  btwSpn?: number; // button-top-width
  wSpn?: number; // width En px!
  bwSpn?: number; // button-width En px!
  bsSpn?: string; // button-style
  bstSpn?: string; // button-top-width
  bcSpn?: string; // button-color (color del fondo)
  bctSpn?: string; // button-top-color (color de la ruedita)
  logoDisplay?: boolean // para mostrar o ocultar logo
  logoColor?: string; // color-logo
  logoWidth?: number; // width-logo
}
export const StyleLoadingSpinner = styled.div<bubaLoaderProps>`
position: relative;
@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: ${(props) => (props.wSpn ? px2rem(props.wSpn) : px2rem(50))};
    height: ${(props) => (props.wSpn ? px2rem(props.wSpn) : px2rem(50))};
    border-width: ${(props) => (props.bwSpn ? px2rem(props.bwSpn) : px2rem(6))};
    border-style: ${(props) => (props.bsSpn ? props.bsSpn : 'solid')};
    border-color: ${(props) => (props.bcSpn ? props.bcSpn : 'transparent')};
    border-top-width: ${(props) => (props.bwSpn ? px2rem(props.bwSpn) : px2rem(6))};
    border-top-style: ${(props) => (props.bsSpn ? props.bsSpn : 'solid')};
    border-top-color: ${(props) => (props.bctSpn ? props.bctSpn : 'red')};
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
  
`;