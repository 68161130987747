import React from 'react';
import defaultRed from 'assets/images/defaultRed.svg';
import Icon from 'components/icons/icon';
import { color } from 'theme/colors';
import { observer } from 'mobx-react-lite';
import { useStores } from 'models/root-store/root-store-context';
import moment from 'moment';
import { Employee } from 'models/employees/employeeModel';
import { StyledCard } from './index.styled';

interface EmployeeCardType {
  index: number;
  employee: Employee;
  selected: boolean | undefined;
  bgSelected: string;
  hiring?: boolean;
  disabled?: boolean;
}

const EmployeeCard: React.FC<EmployeeCardType> = observer(
  ({
    index,
    employee,
    selected,
    bgSelected,
    hiring,
    disabled,
  }: EmployeeCardType) => {
    const {
      renderStore,
      employeesStore,
      contactsStore,
      roomStore,
      sessionStore,
    } = useStores();

    const toggleFavourite = (contactId: string, value: boolean): void => {
      if (disabled) {
        return;
      }
      if (renderStore.renderFlap) {
        if (value) {
          contactsStore.setFavourite(contactId);
        } else {
          contactsStore.unsetFavourite(contactId);
        }
      }
    };

    const inContact = () => {
      return (
        contactsStore.approvedContacts.filter(
          (contact) =>
            contact.id === employee.id
        ).length > 0
      );
    };

    const pendingContact = () => {
      return (
        contactsStore.penddingContacts.filter(
          (contact) =>
            contact.id === employee.id
        ).length > 0
      );
    };
    const handleClick = () => {
      if (disabled) {
        return;
      }
      if (renderStore.renderChat) {
        renderStore.setRenderChat(false);
      }
      employeesStore.setEmployeeSelected(employee.id);
      const contactSelected = contactsStore.contacts.filter(
        (contact) =>
          contact.contacted.id === renderStore.renderSelectedEmployeeId,
      );
      if (renderStore.renderYourConversations) {
        renderStore.setRenderEmployeeDetail(false);
        const contactEmployee = contactsStore.approvedContacts.filter(
          (contact) => contact.id === employee.id,
        );
        const applicantEmployee = contactsStore.penddingContacts.filter(
          (contact) => contact.id  === employee.id,
        );
        const employeeComprobation = () => {
          if (contactEmployee.length > 0) {
            return contactEmployee[0];
          }
          if (applicantEmployee.length > 0) {
            return applicantEmployee[0];
          }
          return false;
        };
        renderStore.setRenderChat(true);
        const contactedEmployee = employeeComprobation();
        if (inContact() || pendingContact()) {
          roomStore.setRoom(contactedEmployee ? contactedEmployee.contactId : '');
        }
      } else {
        if (contactSelected.length > 0) {
          roomStore.setRoom(contactSelected[0].id);
        } else {
          roomStore.setRoom('');
        }
        renderStore.setRenderEmployeeDetail(true);
      }
    };

    const logged = sessionStore.token;
    return (
      <StyledCard
        onClick={() => handleClick()}
        selected={selected}
        bgSelected={bgSelected}
        hiring={hiring}
      >
        <div className="photo-card-employee">
          <img
            className={`photo ${logged ? '' : 'photo-blur'}`}
            src={employee.picture || defaultRed}
            alt="dudePic"
          />
        </div>

        <div className="info-card-employee">
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <span className="name-employee">
              {employee.firstName} {logged ? employee.lastName : ''}
            </span>
            {employee.favourite === undefined && inContact() ? (
              <div className="contacted-employee">
                <Icon icon="check" color={color.palette.shades.lightGray} />
              </div>
            ) : null}
            {employee.favourite !== undefined ? (
              <button
                type="button"
                className="favourite-employee"
                onClick={() =>
                  toggleFavourite(employee.contactId, !employee.favourite)
                }
              >
                <Icon
                  icon={employee.favourite ? 'heartFilled' : 'heartOutline'}
                  color={color.palette.shades.lightGray}
                />
              </button>
            ) : null}
          </div>

          <div className="row-employee-card">
            <Icon icon="briefCase" color={color.palette.shades.lightGray} />
            <span className="text-employee-card">
              {employee.profession || 'Primer trabajo'}
            </span>
          </div>

          {sessionStore.currentUser &&
            sessionStore.currentUser.business &&
            employee.locationData && (
              <div className="row-employee-card">
                <Icon icon="mapPin" color={color.palette.shades.lightGray} />
                <span className="text-employee-card">
                  Distancia {employee.locationData.distanceString}
                </span>
              </div>
            )}

          {!hiring && (
            <div className="row-employee-card">
              <Icon icon="calendar" color={color.palette.shades.lightGray} />
              <span className="text-employee-card">
                {moment().diff(employee.birthdate, 'years')} años
              </span>
            </div>
          )}
        </div>

        <div className="arrowIcon">
          {selected && <Icon icon="arrowRight" />}
        </div>
        {selected && <div className="border-black" />}
      </StyledCard>
    );
  },
);

export default EmployeeCard;
