import { types, Instance, SnapshotOut } from 'mobx-state-tree';
import api from 'services/api/api';
import { ExperienceModel } from './experienceModel';

export const ExperiencesModel: any = types
  .model('Experiences')
  .props({
    loading: types.boolean,
    employeeExperiences: types.optional(types.array(ExperienceModel), []),
  })
  .actions((self) => {
    function setLoading() {
      self.loading = true;
    }
    function setExperiences(experience: any) {
      self.employeeExperiences = experience;
      self.loading = false;
    }
    return {
      setLoading,
      setExperiences,
    };
  })
  .actions((self) => ({
    getMeExperiences: async (employeeId: string) => {
      const response = await api.employee.getExperience(employeeId);
      if (response) {
        self.setExperiences(response.experience);
      }
    },
  }));

type ExperiencesModelType = Instance<typeof ExperiencesModel>;
export type Experiences = ExperiencesModelType;
type ExperiencesSnapshotType = SnapshotOut<typeof ExperiencesModel>;
export type ExperiencesSnapshot = ExperiencesSnapshotType;
export const createExperiencesDefaultModel = () =>
  types.optional(ExperiencesModel, {
    loading: false,
    employeeExperiences: [],
  });
