import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'models/root-store/root-store-context';
import PublishedJobCard from './components/publishedJobsCard';
import NotPublishedOffert from './components/notPublishedOffert';
import { StyledPublishedOffers } from './styledPublishedOffers';

const PublishedOffers: React.FC = observer(function PublishedOffers() {
  const [pressed, setPressed] = useState<any>();
  const { renderStore, meJobsStore } = useStores();
  const [jobs, setJobs] = useState(meJobsStore.jobs);

  useEffect(() => {
    meJobsStore.getMeJobs();
    setJobs(meJobsStore.jobs);
    renderStore.setLoadJobs(false);
    // eslint-disable-next-line
  }, [meJobsStore.jobs, renderStore.renderLoadJobs]);

  const handleClick = (index: number) => {
    meJobsStore.setIndex(index);
    renderStore.setRenderJobDetail(true);
  };

  return (
    <StyledPublishedOffers>
      {jobs.length ? (
        <>
          <span className="text">Tus ofertas publicadas</span>
          <div className="scroll">
            {jobs &&
              jobs.map((item, index) => (
                <div
                  onKeyDown={() => handleClick(index)}
                  role="button"
                  tabIndex={0}
                  onClick={() => handleClick(index)}
                  style={{ margin: '0.3em' }}
                >
                  <PublishedJobCard
                    setPressed={setPressed}
                    index={index}
                    data={item}
                    selected={pressed === index && true}
                  />
                </div>
              ))}
          </div>
          <span className="textBottom">
            Siempre te notificaremos cuando se postulen personas interesadas.
          </span>
        </>
      ) : (
        <NotPublishedOffert />
      )}
    </StyledPublishedOffers>
  );
});
export default PublishedOffers;
