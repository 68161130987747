import React, { useState } from 'react';
import Button from 'components/common/Button';
import { Formik } from 'formik';
import { color } from 'theme/colors';
import { useStores } from 'models/root-store/root-store-context';
import getFirebase from 'services/firebase/client';
import Icon from 'components/icons/icon';
import Notification from '../Notification';

const CommercePhotos: React.FC = () => {
  const { renderStore, dataCommerceStore, sessionStore } = useStores();
  const hiddenFileInput: any = React.useRef();
  const [previewURL, setPreviewURL] = useState<any>();
  const storage = getFirebase().storage();

  const initialValues: any = {};
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      <h1 className="title-rightpanel">Subí algunas fotos del espacio/lugar</h1>
      <p className="paragraph-rightpanel">
        El objetivo de estas fotos es mostrar tu espacio de trabajo
      </p>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const { file } = values;
          if (file) {
            const uploadTask = storage
              .ref(`/company/${sessionStore.currentUser?.authId}/${file.name}`)
              .put(file);
            uploadTask.on(
              'state_changed',
              (snapshot) => {
                renderStore.setRenderNotification(true);
                renderStore.setNotification(
                  `enviado exitosamente`,
                  5000,
                  '',
                  '',
                );
              },
              (error) => {
                renderStore.setRenderNotification(true);
                renderStore.setNotification(
                  `Ocurrio un error ${error}`,
                  5000,
                  '',
                  '',
                );
              },
              () =>
                storage
                  .ref(`/company/${sessionStore.currentUser?.authId}`)
                  .child(file.name)
                  .getDownloadURL()
                  .then((url) => {
                    console.log(url);
                    dataCommerceStore.setPictures(url);
                    const dataCommerce = { ...dataCommerceStore };
                    dataCommerceStore.putDataCommerce(dataCommerce);
                  }),
            );
          } else {
            const dataCommerce = { ...dataCommerceStore };
            renderStore.setRenderNotification(true);
            renderStore.setNotification(`enviado exitosamente`, 5000, '', '');
            dataCommerceStore.putDataCommerce(dataCommerce);
          }
          renderStore.setRenderCommerceData(false);
          renderStore.setStepCommcerData(1);
        }}
      >
        {({ handleSubmit, setFieldValue }) => (
          <>
            <button
              onClick={handleClick}
              style={{
                alignItems: 'center',
                alignSelf: 'center',
                backgroundColor: 'rgba(242, 69, 69, 0.3)',
                borderColor: color.red,
                borderRadius: 8,
                borderStyle: 'dashed',
                borderWidth: 1,
                height: 90,
                marginBottom: 20,
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
              type="button"
            >
              <Icon icon="camera" />
              <span style={{ color: color.white }}>Subir Imagen</span>
            </button>
            <input
              style={{ display: 'none' }}
              ref={hiddenFileInput}
              id="file"
              name="file"
              type="file"
              accept="image/png, image/gif, image/jpeg"
              onChange={(event: any) => {
                setFieldValue('file', event.currentTarget.files[0]);
                setPreviewURL(URL.createObjectURL(event.target.files[0]));
              }}
            />
            {(dataCommerceStore.pictures || previewURL) && (
              <img
                style={{
                  height: 130,
                  width: 130,
                  borderRadius: 20,
                  marginBottom: 20,
                }}
                src={previewURL || dataCommerceStore.pictures}
                alt="previewURL"
              />
            )}
            <div className="field-wrapper">
              <Button
                text="Crear comercio"
                color={color.red}
                hBtn={48}
                wBtn={328}
                btnClick={handleSubmit}
              />
            </div>
          </>
        )}
      </Formik>
      {renderStore.renderNotification && renderStore.renderNotificationProps && (
        <Notification
          message={renderStore.renderNotificationProps.message}
          time={renderStore.renderNotificationProps.time}
          render={{
            type: renderStore.renderNotificationProps.renderType,
            btnText: renderStore.renderNotificationProps.renderBtnText,
          }}
        />
      )}
    </>
  );
};

export default CommercePhotos;
