import { createGlobalStyle } from 'styled-components';
import { color } from './colors';
import { px2rem } from './styleUtils';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body{
    -webkit-font-smoothing: antialiased;
  }

  li {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }

  .bg-block {
    position: absolute;
    background-color: rgba(31, 31, 31, 0.3);
    width: 100%;
    height: 100%;
    z-index: 910000;
  }

  .search-map {
    &:focus {
      border-radius: 12px 12px 0 0;
    }
  }

  .pac-container {
    width: ${px2rem(388)};
    border: 1px solid white;
    background-color: ${color.black};
    border-radius: 0 0 12px 12px
  }

  .pac-logo {
    &:after {
      display: none;
    }
  }

  .pac-item, .pac-item > .pac-item-query {
    font-family: 'Lexend';
    font-size: ${px2rem(12)};
    color: ${color.palette.shades.mediumGray};

    &:hover {
      background-color: ${color.darkGrey};
    }
  }

  .pac-item-selected {
    background-color: ${color.darkGrey};
  }
  
  .pac-item > .pac-item-query {
    color: ${color.white}
  }

  *, *::after, *::before {
	user-select: none;
	-webkit-user-drag: none;
	-webkit-app-region: no-drag;
}
`;
