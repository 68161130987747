export const emailErrors = {
  'auth/user-not-found': 'El email ingresado no existe',
  'auth/wrong-password': 'La contraseña ingresada es errónea',
  'auth/email-already-in-use': 'El email ya está en uso',
  isEmployee: 'El email ingresado esta registrado como empleado',
};

export const phoneErrors = {
  'auth/too-many-requests':
    'Ocurrió un problema al iniciar sesión. Intente más tarde nuevamente',
  'reCAPTCHA has already been rendered in this element':
    'Ocurrió un problema al verificar',
  isEmployee: 'El nro ingresado esta registrado como empleado',
};
