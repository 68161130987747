import React from 'react';
import Icon from 'components/icons/icon';
import { color } from 'theme/colors';
import { useStores } from 'models/root-store/root-store-context';
import { StyledCardsListButton } from './styledCardListButton';

export default function CardsListButton() {
  const { renderStore } = useStores();

  const handleClick = () => {
    renderStore.setRenderList(false);
    renderStore.setRenderCardList(true);
  };

  return (
    <StyledCardsListButton onClick={handleClick}>
      <Icon icon="chevronLeft" color={color.palette.shades.lightGray} />
      <span className="buttonText"> Ver listado</span>
    </StyledCardsListButton>
  );
}
