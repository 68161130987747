import styled from 'styled-components';
import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';

export const StyledEmployeeDetail = styled.div`
  display: flex;
  background-color: ${color.black};
  width: ${px2rem(545)};
  min-width: ${px2rem(545)};
  height: calc(100vh - 80px);
  padding: 0 ${px2rem(16)};
  flex-direction: column;
  position: relative;

  .header {
    display: flex;
    width: 100%;
    flex-direction: row;
  }
  .close {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: ${px2rem(15)};
    margin-bottom: 2vh;
    cursor: pointer;
  }
  .titleBox {
    display: flex;
    flex-direction: column;
    margin-left: ${px2rem(16)};
    width: ${px2rem(304)};
  }

  .photo {
    width: ${px2rem(140)};
    height: ${px2rem(140)};
    border-radius: ${px2rem(8)};
    object-fit: cover;
  }

  .photo-blur {
    background: rgba(255, 255, 255, 0.01);
    filter: blur(6.99248px);
    border-radius: 80px;
  }

  .title {
    font-family: 'Lexend Mega';
    color: ${color.white};
    font-style: normal;
    font-weight: 400;
    margin-top: ${px2rem(-10)};
    font-size: ${px2rem(24)};
    line-height: ${px2rem(32)};
    letter-spacing: -0.12em;
  }
  .MaindataBox {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .text {
    color: ${color.palette.shades.lightGray};
    font-family: 'Lexend';
    margin-left: ${px2rem(8)};
    font-size: ${px2rem(14)};
  }

  .midText {
    color: ${color.palette.shades.lightGray};
    font-family: 'Lexend';
    font-size: ${px2rem(14)};
    width: 100%;
  }

  .midTextDark {
    color: ${color.palette.shades.mediumGray};
    font-family: 'Lexend';
    font-size: ${px2rem(14)};
    margin-top: ${px2rem(8)};
  }
  .textBox {
    display: flex;
    flex-direction: column;
    margin-bottom: ${px2rem(24)};
    margin-top: 0.5em;
  }
  .midSection {
    display: flex;
    height: ${px2rem(72)};
    flex-direction: row;
    margin-top: ${px2rem(24)};
  }
  .midTextBox {
    width: 50%;
  }
`;
