import { types, Instance, SnapshotOut } from 'mobx-state-tree';
import api from 'services/api/api';
import { UserModel } from './userModel';

export const SessionModel = types
  .model('Session')
  .props({
    currentUser: types.maybe(UserModel),
    token: types.maybeNull(types.string),
    jobIndex: types.maybe(types.number),
  })
  .actions((self) => {
    function setToken(token: string | null) {
      self.token = token;
      api.api.setToken(token || '');
    }

    function setCurrentUserOffline(user: any) {
      self.currentUser = user;
    }

    function setJobIndex(index: number) {
      self.jobIndex = index;
    }

    function logOut() {
      setCurrentUserOffline(undefined);
      setToken(null);
      api.api.removeToken();
    }

    return {
      setToken,
      setCurrentUserOffline,
      logOut,
      setJobIndex,
    };
  })

  .actions((self) => ({
    getMeUser: async () => {
      api.api.apisauce.setHeaders({
        Authorization: `Bearer ${self.token}`,
      });
      const request = await api.me.getMeUserProfile();
      if (request.userProfile.userTypeId === 1) {
        // 1 = employee, 2 = business
        self.logOut();
      }
      self.setCurrentUserOffline(request.userProfile);
    },
  }))
  .actions((self) => ({
    postUser: async (body: any) => {
      api.api.apisauce.setHeaders({
        Authorization: `Bearer ${self.token}`,
      });
      const request = await api.user.postUserProfile(body);
      if (request.name === 'ConflictError') {
        return 'ConflictError';
      }
      self.setCurrentUserOffline(request.userData);
      return true;
    },
  }));

type SessionModelType = Instance<typeof SessionModel>;
export type Session = SessionModelType;
type SessionSnapshotType = SnapshotOut<typeof SessionModel>;
export type SessionSnapshot = SessionSnapshotType;
export const createSessionDefaultModel = () => types.optional(SessionModel, {});
