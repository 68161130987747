import React from 'react';
import { SvgProps } from './icon.props';

const UserSvg: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.97631 10.3093C3.60143 9.6842 4.44928 9.33301 5.33333 9.33301H10.6667C11.5507 9.33301 12.3986 9.6842 13.0237 10.3093C13.6488 10.9344 14 11.7823 14 12.6663V13.9997C14 14.3679 13.7015 14.6663 13.3333 14.6663C12.9651 14.6663 12.6667 14.3679 12.6667 13.9997V12.6663C12.6667 12.1359 12.456 11.6272 12.0809 11.2521C11.7058 10.8771 11.1971 10.6663 10.6667 10.6663H5.33333C4.8029 10.6663 4.29419 10.8771 3.91912 11.2521C3.54405 11.6272 3.33333 12.1359 3.33333 12.6663V13.9997C3.33333 14.3679 3.03486 14.6663 2.66667 14.6663C2.29848 14.6663 2 14.3679 2 13.9997V12.6663C2 11.7823 2.35119 10.9344 2.97631 10.3093Z"
        fill="#C1C1C1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99984 2.66634C6.89527 2.66634 5.99984 3.56177 5.99984 4.66634C5.99984 5.77091 6.89527 6.66634 7.99984 6.66634C9.10441 6.66634 9.99984 5.77091 9.99984 4.66634C9.99984 3.56177 9.10441 2.66634 7.99984 2.66634ZM4.6665 4.66634C4.6665 2.82539 6.15889 1.33301 7.99984 1.33301C9.84079 1.33301 11.3332 2.82539 11.3332 4.66634C11.3332 6.50729 9.84079 7.99967 7.99984 7.99967C6.15889 7.99967 4.6665 6.50729 4.6665 4.66634Z"
        fill={color || '#C1C1C1'}
      />
    </svg>
  );
};

export default UserSvg;
