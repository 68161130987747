import React, { useState, useEffect } from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  Autocomplete,
} from '@react-google-maps/api';
import SearchMap from 'components/common/Search/searchMap';
import bubaLogo from 'assets/images/bubaMapPin.svg';
import bubaMarker from 'assets/images/bubaMarker.svg';
import shopPin from 'assets/images/shopPin.svg';
import negativeBuba from 'assets/images/negativeBuba.svg';
import negativeBubaMarker from 'assets/images/negativeBubaMarker.svg';
import useUserLocation from 'hooks/useUserLocation';
import { useStores } from 'models/root-store/root-store-context';
import { observer } from 'mobx-react-lite';
import { Employee } from 'models/employees/employeeModel';
import { progressiveFilterEmployee } from 'utils/filterEmployee';
import Controls from './components/controls';

const containerStyle = {
  width: '100vw',
  height: '96vh',
};

/**
 * @example Ejemplo basico - const stylesObj = { disableDefaultUI: true }
 * @description Parametros opcionales para los controles de estilos en el mapa
 * @param zoomControl - Boolean - Opcional
 * @param mapTypeControl - Boolean - Opcional
 * @param scaleControl - Boolean - Opcional
 * @param streetViewControl - Boolean - Opcional
 * @param rotateControl - Boolean - Opcional
 * @param fullscreenControl - Boolean - Opcional
 */

const stylesObj = {
  disableDefaultUI: true,
  minZoom: 4,
  maxZoom: 18,

  styles: [
    {
      featureType: 'all',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ],
};

const Map = observer(function Map() {
  const {
    renderStore,
    dataCommerceStore,
    employeesStore,
    filterStore,
    contactsStore,
    roomStore,
    loginStore,
  } = useStores();
  const [zoom, setZoom] = useState(16);
  const [centerMap, setCenterMap] = useState<any>();
  const [address, setAddress] = useState<string>(
    dataCommerceStore.address || 'sarasa',
  );
  const [selectedEmployeePosition, setSelectedEmployeePosition] =
    useState<any>();
  const [map, setMap] = useState<any>(null);
  const coords: any = useUserLocation();

  const center = {
    lat: dataCommerceStore.location?.lat || coords.lat,
    lng: dataCommerceStore.location?.lng || coords.lng,
  };

  const [asAutocomplete, setAutocomplete] = useState(null);

  const onLoad = (autocomplete: any) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    try {
      if (asAutocomplete !== null) {
        // @ts-ignore
        const addressInput = asAutocomplete.getPlace().formatted_address;
        // @ts-ignore
        const latitude = asAutocomplete.getPlace().geometry.location.lat();
        // @ts-ignore
        const longitude = asAutocomplete.getPlace().geometry.location.lng();

        dataCommerceStore.setLocation(latitude, longitude, addressInput);
        setAddress(addressInput);
      }
    } catch (err: unknown) {
      console.log(err);
    }
  };

  const getCoordinates = (e: any) => {
    if (renderStore.stepCommerceData === 2) {
      const latitude = e.latLng.lat();
      const longitude = e.latLng.lng();
      setAddress('');
      dataCommerceStore.setLocation(latitude, longitude, '');
    }
  };

  const handleAddress = (e: any) => {
    setAddress(e.target.value);
  };

  useEffect(() => {
    if (employeesStore.employeeSelected) {
      const employeeData = employeesStore.getEmployeeById();
      if (employeeData) {
        const { lat, lng } = employeeData.location;
        setSelectedEmployeePosition({ lat, lng });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeesStore.employeeSelected]);

  useEffect(() => {
    setAddress(dataCommerceStore.address || '');
  }, [dataCommerceStore.address]);

  const renderEmployeeDetail = () => {
    renderStore.setRenderEmployeeDetail(true);
    renderStore.setRenderChat(false)

    const contactSelected = contactsStore.contacts.find(
      (contact) => contact.contacted.id === employeesStore.employeeSelected,
    );

    if (contactSelected) {
      roomStore.setRoom(contactSelected.id);
    } else {
      roomStore.setRoom('');
    }
  };

  const renderBubaMarker = (employeeId: string) => {
    const isContacted = contactsStore.approvedContacts.filter(
      (contact) => contact.id === employeeId,
    );
    const isApplicant = contactsStore.penddingContacts.filter(
      (applicant) => applicant.id === employeeId,
    );
    if (employeeId === employeesStore.employeeSelected) {
      if (isContacted.length > 0 || isApplicant.length > 0) {
        return negativeBubaMarker;
      }
      return bubaMarker;
    }
    if (isContacted.length > 0 || isApplicant.length > 0) {
      return negativeBuba;
    }
    return bubaLogo;
  };

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyDc2epW6kFpJf_GkMuAsGH7C6I530kEhCU"
      libraries={['places']}
    >
      <div style={{ width: '100vw', height: '92vh', overflow: 'hidden' }}>
        <div style={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
          <GoogleMap
            options={stylesObj}
            mapContainerStyle={containerStyle}
            center={selectedEmployeePosition || centerMap || center}
            zoom={zoom}
            onClick={(e) => getCoordinates(e)}
            onLoad={(resp) => setMap(resp)}
            onZoomChanged={() => map && setZoom(map.getZoom())}
          >
            {(loginStore.openLogin ||
              loginStore.openRegister ||
              renderStore.renderPostOffer ||
              (renderStore.renderCommerceData &&
                renderStore.stepCommerceData !== 2)) &&
              !renderStore.renderModalSegment && <div className="bg-block" />}
            {renderStore.stepCommerceData === 2 && (
              <Autocomplete
                onLoad={onLoad}
                onPlaceChanged={onPlaceChanged}
                restrictions={{
                  country: 'ar',
                }}
              >
                <SearchMap address={address} handleAddress={handleAddress} />
              </Autocomplete>
            )}
            {employeesStore.employees.map((item) => {
              if (item.location) {
                const employee: Employee = { ...item };
                const isEmployeeFilter = progressiveFilterEmployee(
                  employee,
                  filterStore,
                );
                if (isEmployeeFilter) {
                  return (
                    <Marker
                      key={employee.id}
                      onClick={() => {
                        renderStore.setRenderChat(false)
                        employeesStore.setEmployeeSelected(employee.id);
                        renderEmployeeDetail();
                        renderStore.setRenderSelectedEmployeeId(item.id);
                        renderStore.setRenderPublishedOffers(false);
                        renderStore.setRenderJobDetail(false);
                      }}
                      icon={{
                        url: renderBubaMarker(employee.id),
                        scale: 0.1,
                      }}
                      position={{
                        lat: item.location.lat,
                        lng: item.location.lng,
                      }}
                    />
                  );
                }
              }
              return null;
            })}
            <></>
            {dataCommerceStore.location && (
              <Marker
                icon={{
                  url: shopPin,
                  scale: 0.1,
                }}
                position={{
                  lat: dataCommerceStore.location.lat || -34.60129,
                  lng: dataCommerceStore.location.lng || -58.38964,
                }}
              />
            )}
            <Controls
              setCenterMap={() => {
                setSelectedEmployeePosition(null);
                setCenterMap(center);
              }}
              center={center}
              setZoom={setZoom}
              zoom={zoom}
            />
          </GoogleMap>
        </div>
      </div>
    </LoadScript>
  );
});

export default React.memo(Map);
