import React from 'react';
import { SvgProps } from './icon.props';

const ChevronDownSvg: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || 14}
      height={height || 8}
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292894 1.70711C-0.0976309 1.31658 -0.0976308 0.683416 0.292894 0.292892C0.683418 -0.0976326 1.31658 -0.0976326 1.70711 0.292892L7 5.58579L12.2929 0.292893C12.6834 -0.0976316 13.3166 -0.0976315 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711Z"
        fill={color || '#C1C1C1'}
      />
    </svg>
  );
};

export default ChevronDownSvg;
