import React from 'react';
import zoomIn from 'assets/images/zoomIn.svg';
import zoomOut from 'assets/images/zoomOut.svg';
import location from 'assets/images/location.svg';
import { StyledControls } from './styledControls';

function Controls(props: any) {
  const { setZoom, zoom, setCenterMap, center } = props;

  return (
    <StyledControls>
      <button
        type="button"
        className="button"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          if (zoom < 18) {
            setZoom(zoom + 1);
          }
        }}
      >
        <img src={zoomIn} alt="zoomIn" />
      </button>

      <button
        type="button"
        className="button"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          if (zoom > 4) {
            setZoom(zoom - 1);
          }
        }}
      >
        <img src={zoomOut} alt="zoomOut" />
      </button>

      <button
        type="button"
        className="button"
        style={{ cursor: 'pointer' }}
        onClick={() => setCenterMap(center)}
      >
        <img src={location} alt="location" />
      </button>
    </StyledControls>
  );
}
export default Controls;
