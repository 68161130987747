import React, { useEffect, useState, useRef } from 'react';
import Icon from 'components/icons/icon';
import getFirebase from 'services/firebase/client';
import { TypeMessage } from 'lib/types';
import { useStores } from 'models/root-store/root-store-context';
import { observer } from 'mobx-react-lite';
import { Employee } from 'models/employees/employeeModel';
import {
  format,
  isYesterday,
  isToday,
  formatRelative,
  isThisWeek,
} from 'date-fns';
import { StyledChat } from './styled.chat';
import Message from './components/message/message';
import { Footer } from './components/footer/footer';

interface WeekType<T> {
  [key: string]: T;
}

const days: Array<string> = [
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sábado',
  'Domingo',
];

const dateParser = (createdAt: number) => {
  const date = new Date(createdAt);
  const day = days[date.getDay() - 1];
  if (isToday(date)) {
    return format(date, "'Hoy, 'H:mm");
  }
  if (isYesterday(date)) {
    return format(date, "'Ayer, 'H:mm");
  }
  if (!isThisWeek(date)) {
    return format(date, 'dd/MM/yy, H:mm');
  }
  return `${day} ${format(date, 'H:mm')}`;
};

export const Chat: React.FC = observer(() => {
  const { renderStore, sessionStore, employeesStore, roomStore, messagesStore } = useStores();
  const chatEndRef = useRef(null);
  const [employee, setEmployee] = useState<Employee | null>(null);

  const getEmployee = () => {
    const employeeData: Employee | null = employeesStore.getEmployeeById();
    if (employeeData) {
      setEmployee(employeeData);
    }
  };

  const scrollToBottom = () => {
    // @ts-ignore
    chatEndRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  };

  const handleClick = () => {
    renderStore.setRenderChat(false);
  };

  useEffect(() => {
    if (renderStore.renderEmployeeDetail) {
      renderStore.setRenderEmployeeDetail(false);
    }
    messagesStore.getMessagesFirebase(roomStore.roomId)
    getEmployee();
    // eslint-disable--line
  }, [roomStore.roomId]);

  useEffect(() => {
    scrollToBottom();
  }, [messagesStore.messages]);

  return (
    <StyledChat>
      <div className="header">
        <div />
        {employee && (
          <span tabIndex={0} role="button" className="title">
            {`${employee.firstName} ${employee.lastName}`}
          </span>
        )}
        <div
          onKeyDown={handleClick}
          onClick={handleClick}
          tabIndex={0}
          role="button"
        >
          <Icon icon="close" />
        </div>
      </div>
      <div className="scroll">
        {messagesStore.messages &&
          messagesStore.messages.map((message: any) =>
            // eslint-disable-next-line no-underscore-dangle
            message.user._id === sessionStore.currentUser?.business?.id
              ? message.text !== '' && (
                  <Message
                    message={message.text}
                    date={dateParser(message.createdAt)}
                    isCurrentUser
                  />
                )
              : message.text !== '' && (
                  <Message
                    message={message.text}
                    date={dateParser(message.createdAt)}
                    employeePicture={employee?.picture}
                  />
                ),
          )}
        <div ref={chatEndRef} />
      </div>
      <div className="footer">
        {employee && roomStore.roomId !== '' && (
          <Footer name={`${employee.firstName} ${employee.lastName}`} />
        )}
      </div>
    </StyledChat>
  );
});
