import styled from 'styled-components';

export const AppStyled = styled.div`
  max-height: 100vh;
  height: 100vh;
  max-width: 100vw;
  width: 100vw;

  .main-app {
    display: flex;
    flex-direction: row;
    flex: 1;
    position: relative;
    height: calc(100vh - 80px); // 80px por el alto del menú
    overflow-y: hidden;
  }
`;
