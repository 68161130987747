import styled from 'styled-components';
import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';

export const StyledFooter = styled.div`
  display: flex;
  background-color: ${color.palette.shades.darkGrey};
  width: ${px2rem(545)};
  height: ${px2rem(140.32)};
  flex-direction: column;
  padding: ${px2rem(16)};

  .button {
    width: ${px2rem(512)};
    height: ${px2rem(48)};
    border-radius: ${px2rem(12)};
    border: 1px solid ${color.white};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .buttonText {
    font-family: 'Lexend Mega';
    color: ${color.white};
    font-size: ${px2rem(16)};
  }

  .input {
    width: ${px2rem(449)};
    height: ${px2rem(48)};
    background-color: ${color.black};
    border-radius: ${px2rem(12)};
    border-width: 1px;
    border-style: groove;
    border-color: ${color.white};
    display: flex;
    color: ${color.palette.shades.lightGray};
    align-items: center;
    padding-left: 0.5em;
    font-family: 'Lexend';
  }

  .inputBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: ${px2rem(16)};
  }
  .circle {
    width: ${px2rem(49)};
    height: ${px2rem(48)};
    background-color: ${color.red};
    border-radius: ${px2rem(24)};
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
