import styled from 'styled-components';
import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';

export const StyledChat = styled.div`
  display: flex;
  background-color: ${color.black};
  width: ${px2rem(545)};
  height: 100%;
  padding-top: ${px2rem(20)};
  flex-direction: column;
  justify-content: space-between;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: ${px2rem(20)};
    padding-left: ${px2rem(16)};
    padding-right: ${px2rem(16)};
  }

  .title {
    color: ${color.palette.shades.lightGray};
    font-size: ${px2rem(24)};
    font-family: 'Lexend';
    align-self: center;
  }

  .scroll {
    padding-left: ${px2rem(16)};
    padding-right: ${px2rem(16)};
    overflow-y: scroll;
    width: ${px2rem(545)};
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    flex: 1;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
