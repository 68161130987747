import { color } from 'theme/colors';

const colorsList = [
  color.leafGreen,
  color.happyYellow,
  color.lightPurple,
  color.passionPink,
];

export const randomColor = () =>
  colorsList[Math.floor(Math.random() * colorsList.length)];
