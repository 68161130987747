import { types, Instance } from 'mobx-state-tree';
import { EmployeeModel } from 'models/employees/employeeModel';
import { EducationStatusModel } from './educationStatusModel';

export const EducationModel: any = types.model('Education').props({
  createdAt: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  modifiedAt: types.maybeNull(types.string),
  modifiedBy: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  institution: types.maybeNull(types.string),
  studyType: types.maybeNull(types.string),
  educationStatusId: types.maybeNull(types.number),
  start: types.maybeNull(types.string),
  end: types.maybeNull(types.string),
  employeeId: types.maybeNull(types.string),
  educationStatus: types.maybeNull(EducationStatusModel),
  employee: types.maybeNull(EmployeeModel),
});

type EducationModelType = Instance<typeof EducationModel>;
export type Education = EducationModelType;
