import React from 'react';
import moment from 'moment';
import Icon from 'components/icons/icon';
import { color } from 'theme/colors';
import { Education } from 'models/employees/education/educationModel';
import EduWicon from './eduWicon';
import { StyledCard } from './styles.dislpayComponents';

const EducationCard: React.FC<Education> = ({
  education,
}): React.ReactElement<Education> => {
  const startYear = moment(education.start).format('YYYY');
  const startMonth = moment(education.start).format('MM');
  const endMonth = moment(education.end).format('MM');
  const endYear = moment(education.end).format('YYYY');
  const months = [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sept',
    'Oct',
    'Nov',
    'Dic',
  ];
  const formatMonth = (month: any) => {
    if (month[0] === 0) {
      month = month[1];
    }
    return months[month - 1];
  };

  const from = `${formatMonth(startMonth)} ${startYear} `;
  const to = education.end ? `${formatMonth(endMonth)}   ${endYear}` : '';

  return (
    <StyledCard>
      <div className="upperBox">
        <div className="edu-box">
          <EduWicon
            color="rgba(40, 165, 85, 0.2)"
            icon="hat"
            iconColor={color.leafGreen}
            height={90}
            width={50}
            iconHeight={30}
            iconWidth={30}
          />
        </div>

        <div className="data-container">
          <span className="cardTitle">{education.name || ''}</span>

          <div className="data-wrapper" style={{ marginBottom: '2em' }}>
            <div className="data-item" style={{ width: 120 }}>
              <span className="icon-wrapper">
                <Icon
                  icon="flag"
                  height={16}
                  width={16}
                  color={color.palette.shades.lightGray}
                />
              </span>
              <span className="text">{education.institution || ''}</span>
            </div>

            <div className="data-item">
              <span className="icon-wrapper">
                <Icon icon="star" color={color.palette.shades.lightGray} />
              </span>
              <span className="text">{education.studyType || ''}</span>
            </div>

            <div className="data-item">
              <span className="icon-wrapper">
                <Icon icon="calendar" color={color.palette.shades.lightGray} />
              </span>
              <span className="text">
                {from || ''} - {to || ''}
              </span>
            </div>
          </div>
        </div>
      </div>
    </StyledCard>
  );
};

export default EducationCard;
