import { types, Instance, SnapshotOut } from 'mobx-state-tree';
import { EmployeeModel } from 'models/employees/employeeModel';
import { LocationData, Location } from 'models/locationModel';
import getFirebase from 'services/firebase/client';
import api from 'services/api/api';

export const FirebaseContactModel = types.model('UnreadMessagesModel').props({
  businessId: types.maybeNull(types.string),
  businessUserId: types.maybeNull(types.string),
  contactId: types.maybeNull(types.string),
  employeeAuthId: types.maybeNull(types.string),
  employeeId: types.maybeNull(types.string),
  employeeUserId: types.maybeNull(types.string),
  latestMessage: types.maybeNull(types.Date),
  latestMessageEmployee: types.maybeNull(types.Date),
  latestMessageBusiness: types.maybeNull(types.Date),
  unreadMessagesBusiness: types.maybeNull(types.boolean),
  unreadMessagesEmployee: types.maybeNull(types.boolean),
});

export const ContactModel = types.model('Contact').props({
  id: types.string,
  contacted: EmployeeModel,
  status: types.string,
  locationData: types.maybeNull(LocationData),
  favourite: types.boolean,
});

export const ContactsModel = types
  .model('Contacts')
  .props({
    contacts: types.optional(types.array(ContactModel), []),
    firebaseContacts: types.optional(types.array(FirebaseContactModel), []),
  })
  .actions((self) => {
    function setContacts(contacts: any) {
      self.contacts = contacts;
    }

    function clearContacts() {
      self.contacts.length = 0;
    }

    function setFirebaseContacts(unreadMessages: any) {
      self.firebaseContacts = unreadMessages;
    }

    return { setContacts, clearContacts, setFirebaseContacts };
  })
  .actions((self) => ({
    getMeContacts: async () => {
      const request = await api.me.getMeContacts();
      const { contacts } = request;
      if (contacts) {
        self.setContacts(contacts);
      }
    },
    getFirebaseContacts: (user: string) => {
      getFirebase()
        .firestore()
        .collection('THREADS')
        .where('businessId', '==', user)
        .onSnapshot((messages) => {
          const resp = messages.docs.map((doc) => {
            const data = doc.data();
            data.latestMessage = new Date(data.latestMessage?.seconds * 1000);
            data.latestMessageEmployee = new Date(data.latestMessageEmployee?.seconds * 1000);
            data.latestMessageBusiness = new Date(data.latestMessageBusiness?.seconds * 1000);
            return data;
          });
          self.setFirebaseContacts(resp);
        });
    },
  }))
  .views((self) => ({
    get hasUnreadMessages() {
      return self.firebaseContacts.findIndex((item) => item.unreadMessagesBusiness) !== -1;
    },
    get approvedContacts() {
      const contacts = self.contacts.filter((contact) => contact.status === 'Approved')
      return contacts.map((contact, index) => {
        const firebaseContact = self.firebaseContacts.find(
          (firestoreContact: any) => contact.id === firestoreContact.contactId,
        );
        return {
          ...contact.contacted,
          contactId: contact.id,
          favourite: contact.favourite,
          locationData: contact.locationData,
          index,
          unreadMessages: firebaseContact?.unreadMessagesBusiness,
          latestMessage: firebaseContact?.latestMessage
        };
      })
    },
    get penddingContacts() {
      const contacts = self.contacts.filter((contact) => contact.status === 'Pending')
      return contacts.map((contact, index) => {
        const firebaseContact = self.firebaseContacts.find(
          (firestoreContact: any) => contact.id === firestoreContact.contactId,
        );
        return {
          ...contact.contacted,
          contactId: contact.id,
          favourite: contact.favourite,
          locationData: contact.locationData,
          index,
          unreadMessages: firebaseContact?.unreadMessagesBusiness,
          latestMessage: firebaseContact?.latestMessage
        };
      })
    }
  }))
  .actions((self) => ({
    setFavourite: async (id: string) => {
      await api.contact.setFavourite(id);
      self.getMeContacts();
    },
    unsetFavourite: async (id: string) => {
      await api.contact.unsetFavourite(id);
      self.getMeContacts();
    },
  }))

  .actions((self) => ({
    postContact: async (id: string) => {
      await api.contact.postContact({
        employeeId: id,
      });
      self.getMeContacts();
    },
  }));

type ContactsModelType = Instance<typeof ContactsModel>;
export type Contacts = ContactsModelType;
type ContactsSnapshotType = SnapshotOut<typeof ContactsModel>;
export type ContactsSnapshot = ContactsSnapshotType;
export const createContactsDefaultModel = () =>
  types.optional(ContactsModel, { contacts: [], firebaseContacts: [] });
