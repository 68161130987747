import React from 'react';
import getFirebase from 'services/firebase/client';
import { useStores } from 'models/root-store/root-store-context';
import { normalizePhoneNumber } from 'utils/common';
import { AuthProps } from 'lib/types';
import firebase from 'firebase';
import EmailForm from '../forms/Email';
import PhoneForm from '../forms/Phone';

const RegisterCredentials: React.FC = () => {
  const { registrationStore, sessionStore } = useStores();

  const verifier = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
      },
    );
  };

  const sendVerificationEmail = async (
    values: AuthProps,
    setValidationError: any,
  ) => {
    const auth = getFirebase().auth();
    try {
      registrationStore.setEmail(values.email!);
      const userCredential = await auth.createUserWithEmailAndPassword(
        values.email!,
        values.password!,
      );
      await userCredential.user!.sendEmailVerification();
      if (userCredential) {
        const token = Object.entries(userCredential.user!)[5][1].b.i;
        sessionStore.setToken(token);
      }
      registrationStore.setStep('RegisterVerification');
    } catch (error: any) {
      setValidationError(error.code || error.message);
    }
  };

  const sendVerificationPhone = async (
    values: AuthProps,
    setValidationError: any,
  ) => {
    const phoneProvider = new (getFirebase().auth.PhoneAuthProvider)();
    const { phoneNumber } = values;
    const normalizedPhoneNumber = normalizePhoneNumber(phoneNumber!);
    verifier();
    const appVerifier = window.recaptchaVerifier;

    try {
      phoneProvider
        .verifyPhoneNumber(normalizedPhoneNumber, appVerifier)
        .then((verificationId) => {
          registrationStore.setPhone(normalizedPhoneNumber);
          registrationStore.setVerificationId(verificationId);
          registrationStore.setStep('RegisterVerification');
          delete window.recaptchaVerifier;
        })
        .catch((error: any) => {
          setValidationError(error.code || error.message);
        });
    } catch (error: any) {
      setValidationError(error.code || error.message);
    }
  };

  return (
    <>
      {registrationStore.registrationMethod === 'phone' ? (
        <>
          <h1 className="title-rightpanel">¿A dónde podemos contactarte?</h1>
          <p className="paragraph-rightpanel">
            Te enviaremos un SMS o WhatsApp para validar el número de tu cuenta
          </p>
          <div className="field-wrapper">
            <PhoneForm onSubmit={sendVerificationPhone} />
          </div>
        </>
      ) : (
        <>
          <h1 className="title-rightpanel">Crea tus credenciales</h1>
          <p className="paragraph-rightpanel">
            Utilizaremos estos datos para validar tus datos o recuperar la
            cuenta
          </p>
          <div className="field-wrapper">
            <EmailForm onSubmit={sendVerificationEmail} />
          </div>
        </>
      )}
    </>
  );
};

export default RegisterCredentials;
