import styled from 'styled-components';
import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';

interface SearchPropsStyle {
  isClear?: boolean;
}

export const StyledSearch = styled.div<SearchPropsStyle>`
  width: ${px2rem(315)};
  height: ${px2rem(48)};
  position: relative;

  input {
    width: 100%;
    height: 100%;
    padding: 0 ${px2rem(76)} 0 ${px2rem(50)};
    border: none;
    border-radius: ${px2rem(12)};
    background-color: ${color.black};
    font-family: 'Lexend';
    font-size: ${px2rem(16)};
    color: ${color.palette.shades.mediumGray};
  }

  .search-icon {
    position: absolute;
    cursor: pointer;
    top: 12px;
    left: 16px;
  }

  .clear-icon {
    display: ${(props) => (props.isClear ? 'none' : 'flex')};
    position: absolute;
    cursor: pointer;
    top: 13px;
    right: 53px;
    background: none;
    border: none;
  }

  .filter-icon {
    position: absolute;
    cursor: pointer;
    top: 13px;
    right: 16px;
    background: none;
    border: none;
  }
`;
