import styled from 'styled-components';
import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';

export const StyledJobDetail = styled.div`
  display: flex;
  width: ${px2rem(545)};
  height: 100%;
  background-color: ${color.black};
  flex-direction: column;
  position: relative;

  .photoBox {
    width: ${px2rem(545)};
    height: ${px2rem(248)};
    object-fit: cover;
  }

  .dataBox {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: ${px2rem(20)};
  }
  .title {
    font-family: 'Lexend Mega';
    color: ${color.white};
    font-size: ${px2rem(24)};
    margin-bottom: ${px2rem(10)};
    letter-spacing: -0.12em;
  }

  .text {
    color: ${color.palette.shades.lightGray};
    font-family: 'Lexend';
    font-size: ${px2rem(14)};
    margin-left: ${px2rem(5)};
  }

  .iconTextJobDetail {
    display: flex;
    align-items: center;
    margin-top: ${px2rem(8)};
  }

  .buttonsBox {
    width: fit-content;
    height: ${px2rem(104)};
    align-self: center;
    display: flex;
    flex-direction: row;
    margin-bottom: ${px2rem(44)};
  }
  .action-jobdetail {
    width: ${px2rem(115)};
    height: ${px2rem(104)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
  }
  .buttonText {
    color: ${color.palette.shades.lightGray};
    font-family: 'Lexend';
    font-size: ${px2rem(12)};
  }
  .iconDiv {
    cursor: pointer;
    position: absolute;
    z-index: 1999;
    top: 2vh;
    right: 1em;
  }
`;
