import React from 'react';
import { SvgProps } from './icon.props';

const BubaLogoSvg: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || 26}
      height={height || 26}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.714715 17.0036C0.714715 18.686 1.45844 20.7908 2.91298 22.4881C4.03603 23.7986 5.62184 24.9084 7.66383 25.4027C7.80153 25.4361 7.93699 25.4534 8.06911 25.4561C8.7364 25.6012 9.43043 25.6778 10.1429 25.6778C15.3502 25.6778 19.5715 21.5868 19.5715 16.5402C19.5715 11.4936 15.3502 7.4025 10.1429 7.4025C9.0437 7.4025 7.9884 7.5848 7.00747 7.91992V5.95098L7.00747 3.13203C7.00747 1.45103 5.60955 0.0883179 3.88513 0.0883179C2.13416 0.0883179 0.714714 1.47201 0.714714 3.17888L0.714715 16.4596C0.714475 16.4864 0.714355 16.5133 0.714355 16.5402C0.714355 16.5671 0.714475 16.5939 0.714715 16.6208L0.714715 17.0036Z"
        fill={color || '#F24545'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9263 16.231C13.4003 16.231 13.7845 15.6557 13.7845 14.9461C13.7845 14.2364 13.4003 13.6611 12.9263 13.6611C12.4524 13.6611 12.0682 14.2364 12.0682 14.9461C12.0682 15.6557 12.4524 16.231 12.9263 16.231ZM7.11694 16.2312C7.59089 16.2312 7.9751 15.656 7.9751 14.9463C7.9751 14.2367 7.59089 13.6614 7.11694 13.6614C6.64299 13.6614 6.25877 14.2367 6.25877 14.9463C6.25877 15.656 6.64299 16.2312 7.11694 16.2312ZM6.81693 18.7585C6.6564 18.5654 6.36544 18.5356 6.16704 18.6918C5.96865 18.848 5.93796 19.1312 6.09849 19.3243C7.02959 20.4442 8.45479 21.1615 10.0518 21.1615C11.6488 21.1615 13.074 20.4442 14.0051 19.3243C14.1657 19.1312 14.135 18.848 13.9366 18.6918C13.7382 18.5356 13.4472 18.5654 13.2867 18.7585C12.5235 19.6765 11.3581 20.2621 10.0518 20.2621C8.74551 20.2621 7.5801 19.6765 6.81693 18.7585Z"
        fill={color || '#0D0D0D'}
      />
    </svg>
  );
};

export default BubaLogoSvg;
