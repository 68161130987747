import styled from 'styled-components';
import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';

export const StyledModal = styled.div`
  position: absolute;
  z-index: 90001;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(31, 31, 31, 0.3);

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: ${color.darkGrey};
    color: white;
    margin-top: ${px2rem(50)};
    padding: ${px2rem(24)} ${px2rem(12)};
    height: 530px;
    max-width: 1066px;
    width: calc(100% - 48px);
    border-radius: ${px2rem(12)};
    box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.5);
  }

  .modal-close {
    position: absolute;
    right: 24px;
    top: 24px;
  }

  .modal-head {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modal-title {
    font-family: 'Lexend Mega';
    font-size: ${px2rem(24)};
    margin-bottom: ${px2rem(8)};
  }

  .modal-subtitle {
    font-family: 'Lexend';
    font-size: ${px2rem(14)};
  }

  .modal-body {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: ${px2rem(12)};
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
