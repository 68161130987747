/* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */

import React, { useEffect, useState } from 'react';
import { LinkBtn } from 'components/common/Button';
import { color } from 'theme/colors';
import { useStores } from 'models/root-store/root-store-context';
import getFirebase from 'services/firebase/client';

const RegisterVerification: React.FC = () => {
  const [timer, setTimer] = useState(60);
  const [emailValidated, setEmailValidated] = useState(false);
  const { registrationStore, sessionStore, loginStore } = useStores();

  useEffect(() => {
    if (emailValidated) {
      const body = {
        email: registrationStore.email,
        userTypeId: 2,
      };
      sessionStore.postUser(body);
      loginStore.setUserLogged(true);
      registrationStore.setStep('RegisterCommerce');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailValidated]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer((lastTimerCount) => {
          lastTimerCount <= 1 && clearInterval(interval);
          return lastTimerCount - 1;
        });
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [timer]); // TODO: Disparar useEffect si se reenvia la verificación (con timer = 120)

  useEffect(() => {
    const interval = setInterval(() => {
      const { currentUser } = getFirebase().auth();
      if (currentUser) {
        currentUser.reload().then(() => {
          setEmailValidated(
            getFirebase().auth().currentUser?.emailVerified === true,
          );
        });
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const resendVerificationEmail = async () => {
    const { currentUser } = getFirebase().auth();
    currentUser?.sendEmailVerification().then(() => {
      setTimer(60);
    });
  };

  return (
    <>
      <h1 className="title-rightpanel">
        Te enviamos un email a tu casilla para validarla
      </h1>
      <div className="wrapper-texts">
        <span>¿No recibiste el mail?</span>
        <LinkBtn
          text={timer ? `Reenviar en ${timer} segundos` : 'Reenviar'}
          textColor={color.red}
          textSize={14}
          textFont="Lexend"
          btnClick={resendVerificationEmail}
          disabled={timer > 0}
        />
      </div>
      <div className="wrapper-texts">
        <span>Revisá que el email {registrationStore.email} sea correcto</span>
        <LinkBtn
          text="Modificar email"
          textColor={color.red}
          textSize={14}
          textFont="Lexend"
          btnClick={() => {
            registrationStore.setStep('RegisterCredentials');
          }}
        />
      </div>
    </>
  );
};

export default RegisterVerification;
