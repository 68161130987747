import React from 'react';
import { SvgProps } from './icon.props';

const ChevronUpSvg: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || 14}
      height={height || 8}
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7071 6.09413C13.3166 6.39994 12.6834 6.39994 12.2929 6.09412L7 1.94932L1.70711 6.09412C1.31658 6.39994 0.683417 6.39994 0.292893 6.09412C-0.0976314 5.78831 -0.0976314 5.29248 0.292894 4.98667L6.29289 0.288142C6.68342 -0.0176737 7.31658 -0.0176736 7.70711 0.288142L13.7071 4.98667C14.0976 5.29249 14.0976 5.78831 13.7071 6.09413Z"
        fill={color || '#C1C1C1'}
      />
    </svg>
  );
};

export default ChevronUpSvg;
