import React from 'react';
import { CustomTextarea } from 'components/common/Input';
import Button from 'components/common/Button';
import Label from 'components/common/Label';
import { Formik } from 'formik';
import { color } from 'theme/colors';
import { useStores } from 'models/root-store/root-store-context';
import { DescriptionSchema } from 'utils/schema-validations';

const PositionDescription: React.FC = () => {
  const { JobPositionStore, renderStore, skillsModel } = useStores();

  const initialValues = {
    description: JobPositionStore.description || '',
  };

  const onSubmit = (values: any) => {
    const { description } = values;
    JobPositionStore.setDescription(description);
    renderStore.setRenderPostOffer(false);

    const { id, ...body } = JobPositionStore;
    if (id) {
      JobPositionStore.putJob(body, id);
    } else {
      JobPositionStore.postJobs(body);
    }

    renderStore.setLoadJobs(true);
    JobPositionStore.resetValues();
    skillsModel.removeSkills();
    JobPositionStore.setId(null);

    renderStore.setNotification(
      'Tu oferta fue publicada exitosamente.',
      6500,
      'postOffer',
      '', // 'VER OFERTA',
    );
    renderStore.setRenderNotification(true);
  };

  return (
    <>
      <h1 className="title-rightpanel">Contanos tu oferta</h1>
      <h2 className="section-rightpanel">
        Describí de qué se trata la búsqueda
      </h2>
      <Formik
        initialValues={initialValues}
        validationSchema={DescriptionSchema}
        validateOnBlur={false}
        validateOnChange
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isValid, values }) => (
          <>
            <div className="field-wrapper">
              <Label text="Descripción" />
              <CustomTextarea
                name="description"
                placeholder="Queremos que trabajes para nosotros porque..."
                maxLength={220}
              />
            </div>
            <div className="btn-panel">
              <Button
                text="Confirmar"
                color={color.red}
                hBtn={48}
                wBtn={328}
                btnClick={handleSubmit}
                disabled={!isValid || values.description.length < 12}
              />
              <Button
                text="Omitir"
                color={color.black}
                hBtn={48}
                wBtn={328}
                btnClick={handleSubmit}
                btnMaring="5px 0"
              />
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

export default PositionDescription;
