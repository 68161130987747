import React, { useState } from 'react';
import { CustomTextInput } from 'components/common/Input';
import Button from 'components/common/Button';
import Label, { Error } from 'components/common/Label';
import { Formik } from 'formik';
import { color } from 'theme/colors';
import { PhoneSchema } from 'utils/schema-validations';
import { phoneErrors } from 'utils/errors';

interface ValuesProps {
  phoneNumber: string;
}

interface PhoneFormProps {
  onSubmit: (arg0: ValuesProps, arg1: any) => void;
}

const initialValues = {
  phoneNumber: '',
};

const PhoneForm: React.FC<PhoneFormProps> = ({ onSubmit }) => {
  const [validation, setValidationError] = useState();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit(values, setValidationError);
      }}
      validationSchema={PhoneSchema}
      validateOnBlur={false}
      validateOnChange
    >
      {({ handleSubmit, values, isValid }) => (
        <>
          <div className="field-wrapper">
            <Label text="Tu número de teléfono" />
            <CustomTextInput
              name="phoneNumber"
              placeholder="Tu número"
              type="text"
            />
          </div>
          <div className="field-wrapper">
            <Button
              text="Confirmar"
              color={color.red}
              hBtn={48}
              wBtn={328}
              btnClick={handleSubmit}
              disabled={!values.phoneNumber || !isValid}
            />
          </div>
          {validation && phoneErrors[validation] && (
            <Error text={phoneErrors[validation]} />
          )}
          {validation && !phoneErrors[validation] && (
            <Error text="Ocurrió un error interno. Intente más tarde" />
          )}
        </>
      )}
    </Formik>
  );
};

export default PhoneForm;
