import React from 'react';
import { SvgProps } from './icon.props';

const BriefCaseSvg: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || '14'}
      height={height || '14'}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16671 3.33335V3.83335H9.66671H11.6667C12.0645 3.83335 12.4461 3.99139 12.7274 4.27269L13.0809 3.91914L12.7274 4.27269C13.0087 4.554 13.1667 4.93553 13.1667 5.33335V11.3334C13.1667 11.7312 13.0087 12.1127 12.7274 12.394C12.4461 12.6753 12.0645 12.8334 11.6667 12.8334H2.33337C1.93555 12.8334 1.55402 12.6753 1.27271 12.394C0.991409 12.1127 0.833374 11.7312 0.833374 11.3334V5.33335C0.833374 4.93553 0.991409 4.554 1.27271 4.27269L0.919161 3.91914L1.27271 4.27269C1.55402 3.99139 1.93555 3.83335 2.33337 3.83335H4.33337H4.83337V3.33335V2.66669C4.83337 2.26886 4.99141 1.88733 5.27271 1.60603C5.55402 1.32472 5.93555 1.16669 6.33337 1.16669H7.66671C8.06453 1.16669 8.44606 1.32472 8.72737 1.60603L9.07525 1.25815L8.72737 1.60603C9.00867 1.88733 9.16671 2.26886 9.16671 2.66669V3.33335ZM8.33337 3.83335H8.83337V3.33335V2.66669C8.83337 2.35727 8.71046 2.06052 8.49167 1.84173C8.27287 1.62294 7.97613 1.50002 7.66671 1.50002H6.33337C6.02395 1.50002 5.72721 1.62294 5.50842 1.84173C5.28962 2.06052 5.16671 2.35727 5.16671 2.66669V3.33335V3.83335H5.66671H8.33337ZM1.85109 7.20192L1.16671 6.93042V7.66669V11.3334C1.16671 11.6428 1.28962 11.9395 1.50842 12.1583C1.72721 12.3771 2.02395 12.5 2.33337 12.5H11.6667C11.9761 12.5 12.2729 12.3771 12.4917 12.1583C12.7105 11.9395 12.8334 11.6428 12.8334 11.3334V7.66669V6.9307L12.1492 7.20186C10.5101 7.85145 8.763 8.18558 6.99987 8.18669C5.23685 8.18618 3.48986 7.85205 1.85109 7.20192ZM12.5345 6.66446L12.8334 6.53315V6.20669V5.33335C12.8334 5.02394 12.7105 4.72719 12.4917 4.5084C12.2729 4.2896 11.9761 4.16669 11.6667 4.16669H2.33337C2.02395 4.16669 1.72721 4.2896 1.50842 4.5084C1.28962 4.72719 1.16671 5.02393 1.16671 5.33335V6.20669V6.53315L1.4656 6.66446C3.21006 7.43082 5.09467 7.82654 7.00004 7.82654C8.90541 7.82654 10.79 7.43082 12.5345 6.66446Z"
        stroke={color || 'black'}
      />
    </svg>
  );
};
export default BriefCaseSvg;
