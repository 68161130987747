import firebase from 'firebase';
import firebaseConfig from './config';

function getFirebase() {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  return firebase;
}

export default getFirebase;

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();

export { googleAuthProvider, facebookAuthProvider }