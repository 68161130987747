import { types, Instance, SnapshotOut } from 'mobx-state-tree';

export const NotificationModel = types.model('Notification').props({
  message: types.optional(types.string, ''),
  time: types.optional(types.number, 10000),
  renderType: types.optional(types.string, ''),
  renderBtnText: types.optional(types.string, ''),
});

export const RenderModel = types
  .model('Render')
  .props({
    renderCardList: types.maybe(types.boolean),
    renderEmployeeDetail: types.maybe(types.boolean),
    renderJobDetail: types.maybe(types.boolean),
    renderPublishedOffers: types.maybe(types.boolean),
    renderUserSettings: types.maybe(types.boolean),
    renderChat: types.maybe(types.boolean),
    renderYourConversations: types.maybe(types.boolean),
    renderCommerceData: types.maybe(types.boolean),
    renderDropdown: types.maybe(types.boolean),
    stepCommerceData: types.optional(types.number, 1),
    renderPostOffer: types.maybe(types.boolean),
    stepPostOffer: types.optional(types.number, 1),
    renderModalSegment: types.maybe(types.boolean),
    renderClearFilter: types.maybe(types.boolean),
    renderFilter: types.maybe(types.boolean),
    renderNotification: types.optional(types.boolean, false),
    renderNotificationProps: types.maybe(NotificationModel),
    renderFlap: types.optional(types.string, 'En contacto'),
    renderSelectedEmployeeId: types.maybe(types.string),
    renderReference: types.optional(types.boolean, false),
    renderLoadJobs: types.optional(types.boolean, false),
    renderList: types.optional(types.boolean, false),
    renderFavourites: types.optional(types.boolean, false),
  })
  .actions((self) => {
    function setRenderList(value: boolean) {
      self.renderList = value;
    }

    function setLoadJobs(value: boolean) {
      self.renderLoadJobs = value;
    }

    function setRenderCardList(open: boolean) {
      self.renderCardList = open;
    }

    function setRenderEmployeeDetail(open: boolean) {
      self.renderEmployeeDetail = open;
    }

    function setRenderJobDetail(open: boolean) {
      self.renderJobDetail = open;
    }

    function setRenderPublishedOffers(open: boolean) {
      self.renderPublishedOffers = open;
    }

    function setRenderUserSettings(open: boolean) {
      self.renderUserSettings = open;
    }
    
    function setRenderChat(open: boolean) {
      self.renderChat = open;
    }

    function setRenderYourConversations(open: boolean) {
      self.renderYourConversations = open;
    }

    function setRenderCommerceData(open: boolean) {
      self.renderCommerceData = open;
      self.stepCommerceData = 1;
    }

    function setRenderDropdown(open: boolean) {
      self.renderDropdown = open;
    }

    function setStepCommcerData(step: number) {
      self.stepCommerceData = step;
    }

    function setRenderPostOffer(open: boolean) {
      self.renderPostOffer = open;
    }

    function setStepPostOffer(step: number) {
      self.stepPostOffer = step;
    }

    function setRenderModalSegment(open: boolean) {
      self.renderModalSegment = open;
    }

    function setRenderClearFilter(open: boolean) {
      self.renderClearFilter = open;
    }

    function setRenderFilter(open: boolean) {
      self.renderFilter = open;
    }

    function setRenderNotification(open: boolean) {
      self.renderNotification = open;
    }

    function setRenderFlap(text: string) {
      self.renderFlap = text;
    }

    function setRenderSelectedEmployeeId(id: string) {
      self.renderSelectedEmployeeId = id;
    }

    function setRenderReference(ref: boolean) {
      self.renderReference = ref;
    }

    function setRenderFavourites(value: boolean) {
      self.renderFavourites = value;
    }

    function setNotification(
      message: string,
      time: number,
      renderType: string,
      renderBtnText: string,
    ) {
      self.renderNotificationProps = {
        message,
        time,
        renderType,
        renderBtnText,
      };
    }

    function closeLeftComponents() {
      setRenderYourConversations(false);
      setRenderFilter(false);
      setRenderChat(false);
      setRenderPublishedOffers(false);
      setRenderJobDetail(false);
      setRenderEmployeeDetail(false);
      setRenderDropdown(false);
      setRenderCardList(false);
      setRenderList(true);
    }

    function closeRightComponents() {
      setRenderPostOffer(false);
      setRenderCommerceData(false);
      setRenderDropdown(false);
      setRenderFilter(false);
      setRenderUserSettings(false);
    }

    return {
      setRenderList,
      setRenderCardList,
      setRenderEmployeeDetail,
      setRenderJobDetail,
      setRenderPublishedOffers,
      setRenderChat,
      setRenderYourConversations,
      setRenderCommerceData,
      setRenderDropdown,
      setStepCommcerData,
      setRenderPostOffer,
      setRenderUserSettings,
      setStepPostOffer,
      setRenderModalSegment,
      setRenderClearFilter,
      setRenderFilter,
      setRenderNotification,
      setNotification,
      setRenderFlap,
      setRenderSelectedEmployeeId,
      setRenderReference,
      setLoadJobs,
      closeLeftComponents,
      closeRightComponents,
      setRenderFavourites,
    };
  });

type RenderModelType = Instance<typeof RenderModel>;
export type Render = RenderModelType;
type RenderSnapshotType = SnapshotOut<typeof RenderModel>;
export type RenderSnapshot = RenderSnapshotType;

export const createRenderDefaultModel = () =>
  types.optional(RenderModel, {
    renderCardList: true,
    renderEmployeeDetail: false,
    renderJobDetail: false,
    renderPublishedOffers: false,
    renderChat: false,
    renderYourConversations: false,
    renderUserSettings: false,
  });
