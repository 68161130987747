import React from 'react';
import RegisterOptions from 'components/common/RegisterOptions';
import RegisterCredentials from 'components/common/RegisterCredentials';
import RegisterVerification from 'components/common/RegisterVerification';
import RegisterCode from 'components/common/RegisterCode';
import RegisterCommerce from 'components/common/StepsCommerce/registerName';
import { IconBtn } from 'components/common/Button';
import Icon from 'components/icons/icon';
import { useStores } from 'models/root-store/root-store-context';
import { observer } from 'mobx-react-lite';
import { color } from 'theme/colors';
import { StyledRightPanel } from 'theme/rigthPanel.styled';
import packageJson from '../../../../package.json';

const Register: React.FC = observer(function Register() {
  const { registrationStore, loginStore } = useStores();
  /**
   * registerStep states:
   *    1°: RegisterOptions
   *    2°: RegisterCredentials
   *    3°: RegisterVerification (email) || RegisterCode (phone)
   *    4°: RegisterCommerce
   */

  const handleClose = () => {
    loginStore.setOpenRegister(false);
    registrationStore.setStep('RegisterOptions');
  };

  return (
    <StyledRightPanel>
      <>
        <div className="header-panel">
          <Icon icon="close" color={color.black} />
          <h2 className="section-rightpanel">Crear perfil Buba</h2>
          <IconBtn icon="close" btnClick={handleClose} />
        </div>
        {registrationStore.step === 'RegisterOptions' && <RegisterOptions />}
        {registrationStore.step === 'RegisterCredentials' && (
          <RegisterCredentials />
        )}
        {registrationStore.registrationMethod === 'phone'
          ? registrationStore.step === 'RegisterVerification' && (
              <RegisterCode />
            )
          : registrationStore.step === 'RegisterVerification' && (
              <RegisterVerification />
            )}
        {registrationStore.step === 'RegisterCommerce' && <RegisterCommerce />}

        <div id="recaptcha-container" />

        <p className="have-account version">Version {packageJson.version}</p>
      </>
    </StyledRightPanel>
  );
});

export default Register;
