import { px2rem } from 'theme/styleUtils';
import { color } from 'theme/colors';

import styled from 'styled-components';

export const StyledControls = styled.div`
  display: flex;
  height: ${px2rem(184)};
  justify-content: space-between;
  flex-direction: column;
  position: absolute;
  right: ${px2rem(30)};
  bottom: ${px2rem(165)};
  z-index: 90000;

  .button {
    display: flex;
    height: ${px2rem(56)};
    width: ${px2rem(56)};
    background-color: ${color.white};
    border-width: 0;
    border-radius: ${px2rem(24)};
    justify-content: center;
    align-items: center;
  }
`;
