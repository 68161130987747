import { ApiResponse } from 'apisauce';
import { Api } from './api';
import { getGeneralApiProblem } from './api-problem';

export class EmployeeApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getEducation(employeeId: string): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/employee/${employeeId}/education`,
      );
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const education = response.data;
      return { kind: 'ok', education };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getExperience(employeeId: string): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/employee/${employeeId}/experience`,
      );
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const experience = response.data;
      return { kind: 'ok', experience };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
