import React from 'react';
import { SvgProps } from './icon.props';

const AlertCircleSvg: React.FC<SvgProps> = ({height, width}) => {
  return (
    <svg
      width={width||"16"}
      height={height||"16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00033 2.00008C4.68662 2.00008 2.00033 4.68637 2.00033 8.00008C2.00033 11.3138 4.68662 14.0001 8.00033 14.0001C11.314 14.0001 14.0003 11.3138 14.0003 8.00008C14.0003 4.68637 11.314 2.00008 8.00033 2.00008ZM0.666992 8.00008C0.666992 3.94999 3.95024 0.666748 8.00033 0.666748C12.0504 0.666748 15.3337 3.94999 15.3337 8.00008C15.3337 12.0502 12.0504 15.3334 8.00033 15.3334C3.95024 15.3334 0.666992 12.0502 0.666992 8.00008Z"
        fill="#DB3500"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99967 4.66675C8.36786 4.66675 8.66634 4.96522 8.66634 5.33341V8.00008C8.66634 8.36827 8.36786 8.66675 7.99967 8.66675C7.63148 8.66675 7.33301 8.36827 7.33301 8.00008V5.33341C7.33301 4.96522 7.63148 4.66675 7.99967 4.66675Z"
        fill="#DB3500"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33301 10.6667C7.33301 10.2985 7.63148 10 7.99967 10H8.00634C8.37453 10 8.67301 10.2985 8.67301 10.6667C8.67301 11.0349 8.37453 11.3333 8.00634 11.3333H7.99967C7.63148 11.3333 7.33301 11.0349 7.33301 10.6667Z"
        fill="#DB3500"
      />
    </svg>
  );
};

export default AlertCircleSvg;
