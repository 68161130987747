import { Filter } from 'models/filter/filterModel';
import { Employee } from 'models/employees/employeeModel';
import { calculateAge } from './calculateAge';

const filterByAgeEmployee = (birthdate: string, filter: Filter) => {
  if (!birthdate) return false;
  if ((birthdate && filter.minimumAge !== 16) || filter.maximumAge !== 85) {
    const age = calculateAge(birthdate);
    if (
      filter.minimumAge &&
      filter.maximumAge &&
      filter.minimumAge <= age &&
      filter.maximumAge >= age
    ) {
      return true;
    }
    return false;
  }
  return true;
};

const filterByFirstJob = (firstJob: boolean, filter: Filter, reference:boolean) => {
  if (filter.firstJob) {
    return filter.firstJob === firstJob && !reference;
  }
  return true;
};

const filterByProfession = (profession: string, filter: Filter) => {
  if (!filter.searchText) return true;
  if (profession && filter.searchText && filter.searchText !== '') {
    return profession.toLowerCase().includes(filter.searchText.toLowerCase());
  }
  return false;
};

const filterByText = (
  skills: string | null,
  fName: string | null,
  sName: string | null,
  filter: Filter,
) => {
  if (skills === '' && fName === '' && sName === '') return false;
  if (!fName || !sName) return false;
  if (
    (skills || fName || sName) &&
    filter.searchText &&
    filter.searchText !== ''
  ) {
    const [skill, first, last] = [skills, fName, sName].map((element) => element?.toLowerCase())
    const fullNameParsed = `${first} ${last}`;
    const invFullNameParsed = `${first} ${last}`;
    const searchTextLower = filter.searchText.toLowerCase();
    return (
      skill?.includes(searchTextLower) ||
      first?.includes(searchTextLower) ||
      last?.includes(searchTextLower) ||
      fullNameParsed.includes(searchTextLower) ||
      invFullNameParsed.includes(searchTextLower)
    );
  }
  return true;
};

const filterByExpYears = (expYears: number, filter: Filter) => {
  if (filter.experience && filter.experience > -1) {
    return filter.experience <= expYears;
  }
  return true;
};

const filterByReferences = (reference: boolean, filter: Filter, firstJob: boolean) => {
  if (filter.reference) {
    return filter.reference === reference && !firstJob;
  }
  return true;
};

export const progressiveFilterEmployee = (
  employee: Employee,
  filter: Filter,
): boolean => {
  const ageFilter = filterByAgeEmployee(employee.birthdate, filter);
  const firstJobFilter = filterByFirstJob(employee.firstJob, filter, employee.hasReferences);
  const textFilter = filterByText(
    employee.skills,
    employee.firstName,
    employee.lastName,
    filter,
  );
  const expYearsFilter = filterByExpYears(employee.experienceYears, filter);
  const referencesFilter = filterByReferences(employee.hasReferences, filter, employee.firstJob);
  const professionFilter = filterByProfession(employee.profession, filter);
  return (
    ageFilter &&
    firstJobFilter &&
    (textFilter || professionFilter) &&
    expYearsFilter &&
    referencesFilter
  );
};
