import React from 'react';
import { CustomTextInput } from 'components/common/Input';
import Button from 'components/common/Button';
import Label from 'components/common/Label';
import { Formik } from 'formik';
import { color } from 'theme/colors';
import { observer } from 'mobx-react-lite';
import { useStores } from 'models/root-store/root-store-context';
import { NameCommerceSchema } from 'utils/schema-validations';

interface CommerceFormProps {
  onSubmit: (name: string) => void;
}

const CommerceNameForm: React.FC<CommerceFormProps> = observer(
  function CommerceNameForm({ onSubmit }) {
    const { dataCommerceStore } = useStores();

    const initialValues = {
      name: dataCommerceStore.name,
    };

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const { name } = values;
          if (name) {
            onSubmit(name);
          }
        }}
        validationSchema={NameCommerceSchema}
        validateOnChange
      >
        {({ handleSubmit, isValid }) => (
          <>
            <div className="field-wrapper">
              <Label text="Nombre del comercio" />
              <CustomTextInput
                name="name"
                type="text"
                handleSubmit={handleSubmit}
              />
              <Label text="Esta información será compartida con los postulantes" />
            </div>
            <div className="field-wrapper">
              <Button
                text="Confirmar nombre"
                color={color.red}
                hBtn={48}
                wBtn={328}
                btnClick={handleSubmit}
                disabled={!isValid}
              />
            </div>
          </>
        )}
      </Formik>
    );
  },
);

export default CommerceNameForm;
