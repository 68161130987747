import styled from 'styled-components';
import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';

export const StyledNotification = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: ${px2rem(22)};
  right: calc(50vw - (780px / 2));
  width: ${px2rem(780)};
  height: ${px2rem(69)};
  padding-left: ${px2rem(40)};
  padding-right: ${px2rem(40)};
  border-radius: ${px2rem(20)};
  background-color: ${color.palette.primary.purple};
  box-shadow: 1px 0px 16px ${color.palette.primary.purple};
  z-index: 920000;

  .text-notification {
    font-family: 'Lexend';
    color: ${color.white};
  }

  .btn-notification {
    font-family: 'Lexend Mega';
    color: ${color.white};
    background-color: transparent;
    cursor: pointer;
  }
`;
