import styled from 'styled-components';
import { color } from 'theme/colors';
import { px2rem } from 'theme/styleUtils';

interface StyledSelectProps {
  wSelect?: number;
}

export const StyledSelect = styled.select<StyledSelectProps>`
  width: ${(props) => px2rem(props.wSelect || 328)};
  height: ${px2rem(48)};
  padding: 0 ${px2rem(16)} 0 ${px2rem(16)}; // TODO: Editar padding de flecha
  border-radius: ${px2rem(12)};
  border: ${px2rem(1)} solid ${color.white};
  background-color: ${color.black};
  color: ${color.white};
  font-family: 'Lexend';
  font-size: ${px2rem(16)};
`;
