import { types, Instance, SnapshotOut } from 'mobx-state-tree';
import { Location } from 'models/locationModel';

export const UserType = types.model('UserType').props({
  modifiedAt: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  description: types.maybeNull(types.string),
});

export const Jobs = types.model('Jobs').props({
  businessId: types.maybeNull(types.string),
  businessSegmentId: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  genderId: types.maybeNull(types.number),
  id: types.maybeNull(types.string),
  maximumAge: types.maybeNull(types.number),
  minimumAge: types.maybeNull(types.number),
  modalityId: types.maybeNull(types.number),
  modifiedAt: types.maybeNull(types.string),
  modifiedBy: types.maybeNull(types.string),
  skills: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
});

export const Business = types.model('Business').props({
  modifiedAt: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  location: types.maybeNull(Location),
  employeesCountId: types.maybeNull(types.number), // Type MasterTable[algo]
  description: types.maybeNull(types.string),
  pictures: types.maybeNull(types.string),
  jobs: types.maybeNull(types.array(Jobs)),
  // TODO: Falta contacts y employeesCount
});

export const UserModel = types.model('User').props({
  modifiedAt: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  authId: types.maybeNull(types.string),
  isAdmin: types.maybeNull(types.boolean),
  phoneNumber: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  userTypeId: types.maybeNull(types.number),
  userType: types.maybeNull(UserType),
  business: types.maybeNull(Business),
  // TODO: Falta employee
});

type UserModelType = Instance<typeof UserModel>;
export type User = UserModelType;
type UserSnapshotType = SnapshotOut<typeof UserModel>;
export type UserSnapshot = UserSnapshotType;
export const createUserDefaultModel = () => types.optional(UserModel, {});
